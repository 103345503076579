import MainPageType from "../Type/MainPageType";

function setMainPage(result){

    let data = {
        ...result.data,
    };

    let urlMap = new Map();

    urlMap.set("front", data.frontUrl);
    urlMap.set("frontBack", data.frontBackUrl);
    urlMap.set("backBack", data.backBackUrl);

    return{
        type: MainPageType.mp,
        payload: Object.fromEntries(urlMap.entries()),
    };
}

export default setMainPage;