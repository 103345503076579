import "./ArticleUpdate.css";

import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateArticleData, resetUpdateArticleData, resetAllArticle } from "../Redux/Action/ArticleAction";
import { updateArticleError, resetUpdateArticleError } from "../Redux/Action/ErrorAction";
import { isBoolean } from "lodash";
import kapi from "../Redux/kalniyojanapi";

function ArticleUpdate(props){

	let update_d_star = 0;

	let pathParam = useParams();

	let updateArticleNameRef = useRef(null);
	let updateArticlePublishYearRef = useRef(null);
	let updateArticlePublishMonthRef = useRef(null);
	let updateArticlePublishDayRef = useRef(null);
	let updateArticleFileRef = useRef({value:null});

	let [updateArticleErrorCls, SetUpdateArticleError] = useState("updateArticleErrorShower");
	let [updatedArticleFile, SetUpdatedArticleFile] = useState(null);

	let [updated_article_name, Set_Updated_Article_Name] = useState(null);
	let [updated_article_year, Set_Updated_Article_Year] = useState(null);
	let [updated_article_month, Set_Updated_Article_Month] = useState(null);
	let [updated_article_day, Set_Updated_Article_Day] = useState(null);

	let update_article_data;

	let update_article_errors = useSelector(state => state.errors.updateArticleError);

	let updateArticleDispather = useDispatch();

	useEffect(() => {

        let kapt_exist = localStorage.getItem("kapt");

        if(kapt_exist === null || kapt_exist === undefined){
            window.location.href = "/login";
        }

		SetUpdateArticleError("updateArticleErrorShower");
		updateArticleDispather(resetUpdateArticleError());
		updateArticleDispather(resetUpdateArticleData());
		updateArticleDispather(resetAllArticle());

		axios({
			url: kapi.adminupdatearticlekeyapi,
			method: kapi.post,
			data: {
				"articleKey":pathParam.key,
			},
			headers:{
				"Authorization": `Bearer ${kapt_exist}`,
			},
			withCredentials: true,
		}).then(result => {
			if(result.status !== 200){
				throw new Error(result);
			}else{
				updateArticleDataTrim(result);
			}
		}).catch(error => {
			SetUpdateArticleError("updateArticleErrorShowerSelected");
			if(error.response !== undefined){
				if(error.response.data === "" && error.response.status !== 406){
					localStorage.removeItem("kapt");
					window.location.href = "/";
				}
				if(error.response.status === 406 && error.response.data === ""){
					updateArticleDispather(updateArticleError("can not find article"));
					setTimeout(() => {
						SetUpdateArticleError("updateArticleErrorShower");
						updateArticleDispather(resetUpdateArticleError());
					}, 5000);
				}
			}else{
				updateArticleDispather(updateArticleError("something is wrong may be server is down, please try later"));
				setTimeout(() => {
					SetUpdateArticleError("updateArticleErrorShower");
					updateArticleDispather(resetUpdateArticleError());
				}, 5000);
			}
		});
	},[update_article_data]);

	function updateArticleDataTrim(original_data){
		let updated_data_map = new Map();
		let date = new Date(original_data.data.date);
		updated_data_map.set("articleName",original_data.data.articleName);
		updated_data_map.set("year",date.getFullYear());
		updated_data_map.set("month",(date.getMonth() + 1));
		updated_data_map.set("day",date.getDate());

		updateArticleDispather(updateArticleData(Object.fromEntries(updated_data_map.entries())));

		for(let key_of_update_data of Array.from(updated_data_map.keys())){

			switch(key_of_update_data){
				case "articleName":
					Set_Updated_Article_Name(updated_data_map.get(key_of_update_data));
					break;
				
				case "year":
					Set_Updated_Article_Year(updated_data_map.get(key_of_update_data));
					break;
				
				case "month":
					Set_Updated_Article_Month(updated_data_map.get(key_of_update_data));
					break;
				
				case "day":
					Set_Updated_Article_Day(updated_data_map.get(key_of_update_data));
					break;
				
				default:
					Set_Updated_Article_Name(null);
					Set_Updated_Article_Year(null);
					Set_Updated_Article_Month(null);
					Set_Updated_Article_Day(null);
			}
		}
	}

	update_article_data = useSelector(state => state.articles.updateArticle);

	function handleUpdateArticleForm(event){
		event.preventDefault();

		updateArticleDispather(resetUpdateArticleError());
		SetUpdateArticleError("updateArticleErrorShower");

		if(updated_article_name === null || !validateUpdateArticleName(updated_article_name)){
			SetUpdateArticleError("updateArticleErrorShowerSelected");
			updateArticleDispather(updateArticleError("name must be greater than 4 char!!!"));
			setTimeout(() => {
				SetUpdateArticleError("updateArticleErrorShower");
				updateArticleDispather(resetUpdateArticleError());
			},5000);
			update_d_star++;
		}

		if(updated_article_year === null || isNaN(updated_article_year) || !validateUpdatedArticleYear(updated_article_year)){
			SetUpdateArticleError("updateArticleErrorShowerSelected");
			updateArticleDispather(updateArticleError("year is not valid!!!"));
			setTimeout(() => {
				SetUpdateArticleError("updateArticleErrorShower");
				updateArticleDispather(resetUpdateArticleError());
			},5000);
			update_d_star++;
		}

		if(updated_article_month === null || isNaN(updated_article_month) || !validateUpdatedArticleMonth(updated_article_month,updated_article_year)){
			SetUpdateArticleError("updateArticleErrorShowerSelected");
			updateArticleDispather(updateArticleError("month is not valid!!!"));
			setTimeout(() => {
				SetUpdateArticleError("updateArticleErrorShower");
				updateArticleDispather(resetUpdateArticleError());
			},5000);
			update_d_star++;
		}

		if(updated_article_day === null || isNaN(updated_article_day) || !validateUpdatedArticleDayOfMonth(updated_article_year, updated_article_month, updated_article_day)){
			SetUpdateArticleError("updateArticleErrorShowerSelected");
			updateArticleDispather(updateArticleError(`day is not valid!!!`));
			setTimeout(() => {
				SetUpdateArticleError("updateArticleErrorShower");
				updateArticleDispather(resetUpdateArticleError());
			}, 5000);
			update_d_star++;
		}

		if(!validateUpdatedArticleDate(updated_article_year,updated_article_month,updated_article_day)){
			SetUpdateArticleError("updateArticleErrorShowerSelected");
			updateArticleDispather(updateArticleError("Please provide valid date!!!"));
			setTimeout(() => {
				SetUpdateArticleError("updateArticleErrorShower");
				updateArticleDispather(resetUpdateArticleError());
			}, 5000);
			update_d_star++;
		}

		if(updatedArticleFile !== null && updatedArticleFile.type !== "application/pdf"){
			SetUpdateArticleError("updateArticleErrorShowerSelected");
			updateArticleDispather(updateArticleError("you have selected file field place check it is in PDF format!!!"));
			setTimeout(() => {
				SetUpdateArticleError("updateArticleErrorShower");
				updateArticleDispather(resetUpdateArticleError());
			}, 5000);
			update_d_star++;
		}

		if(updatedArticleFile !== null && newUpdateArticleFileSize(updatedArticleFile)){
			SetUpdateArticleError("updateArticleErrorShowerSelected");
			updateArticleDispather(updateArticleError("check file size if less than 20MB!!!"));
			setTimeout(() => {
				SetUpdateArticleError("updateArticleErrorShower");
				updateArticleDispather(resetUpdateArticleError());
			}, 5000);
			update_d_star++;
		}

		if(!isSomethingChanged()){
			SetUpdateArticleError("updateArticleErrorShowerSelected");
			updateArticleDispather(updateArticleError("Nothing changed, can not update article!!!"));
			setTimeout(() => {
				SetUpdateArticleError("updateArticleErrorShower");
				updateArticleDispather(resetUpdateArticleError());
			}, 5000);
			update_d_star++;

		}

		if(update_d_star === 0){

			if(updatedArticleFile === null){

				let updated_article_without_file = new Map();
				updated_article_without_file.set("updatedArticleName",updateArticleNameRef.current.value);
				updated_article_without_file.set("updatedArticleYear",updateArticlePublishYearRef.current.value);
				updated_article_without_file.set("updatedArticleMonth",updateArticlePublishMonthRef.current.value);
				updated_article_without_file.set("updatedArticleDay",updateArticlePublishDayRef.current.value);
				updated_article_without_file.set("key",pathParam.key);

				let auth_token = localStorage.getItem("kapt");

				axios({
					method: kapi.put,
					url: kapi.shortupdatearticleapi,
					data: Object.fromEntries(updated_article_without_file.entries()),
					headers:{
						"Content-Type":"application/json",
						"Authorization": `Bearer ${auth_token}`,
					},
					withCredentials: true,
				}).then(result => {
					if(result.status !== 200){
						throw new Error(result);
					}
					window.location.href = '/kal/admin/dashboard';
				}).catch(error => {
					SetUpdateArticleError("updateArticleErrorShowerSelected");
					if(error.response !== undefined){
						if(error.response.data === ""){
							localStorage.removeItem("kapt");
							window.location.href = "/";
						}
						if(error.response.data === false && error.response.status === 406){
							updateArticleDispather(updateArticleError("check article name, maybe its already there"));
							updateArticleDispather(updateArticleError("check provided date, it should not be grater than current"));
							updateArticleDispather(updateArticleError("may be article not exist"));
							setTimeout(() => {
								SetUpdateArticleError("updateArticleErrorShower");
								updateArticleDispather(resetUpdateArticleError());
							}, 5000);
						}
					}else{
						updateArticleDispather(updateArticleError("something is wrong may be server is down, please try later"));
						setTimeout(() => {
							SetUpdateArticleError("updateArticleErrorShower");
							updateArticleDispather(resetUpdateArticleError());
						}, 5000);
					}
				});
			}else{

				let updated_article_with_file = new FormData();

				updated_article_with_file.append("updatedArticleName",updated_article_name);
				updated_article_with_file.append("updatedArticleYear",updated_article_year);
				updated_article_with_file.append("updatedArticleMonth",updated_article_month);
				updated_article_with_file.append("updatedArticleDay",updated_article_day);
				updated_article_with_file.append("key",pathParam.key);

				if(updatedArticleFile !== null){
					updateArticleFileRef.current.value = updatedArticleFile;
					updated_article_with_file.append("updatedArticleFile",updatedArticleFile);
				}

				updateWholeArticle(updated_article_with_file);
			}
		}

	}


	function isSomethingChanged(){

		let result = new Set();

		if(updated_article_name !== update_article_data.articleName){
			result.add(true);
		}else{
			result.add(false);
		}

		if(updated_article_year !== update_article_data.year){
			result.add(true);
		}else{
			result.add(false);
		}

		if(updated_article_month !== update_article_data.month){
			result.add(true);
		}else{
			result.add(false);
		}

		if(updated_article_day !== update_article_data.day){
			result.add(true);
		}else{
			result.add(false);
		}

		if(updatedArticleFile !== null){
			result.add(true);
		}else{
			result.add(false);
		}

		return result.has(true) ? true : false;
	}

	function newUpdateArticleFileSize(file){
        return file.size > 20971520 ? true : false;
	}

	function validateUpdateArticleName(updated_name){

		let updateNameRegex = /[a-zA-Z0-9_\-.]{4,255}/i

		let isUpdatedNameValid = updateNameRegex.test(updated_name);

		return isUpdatedNameValid;
	}

	function validateUpdatedArticleYear(updated_year){
		let date = new Date();
		return updated_year > date.getFullYear() ? false : true;
	}

	function validateUpdatedArticleMonth(updated_month,updated_year){

		let date = new Date();

		if(updated_month > 12 || (updated_year > date.getFullYear() && (updated_month - 1) >= date.getMonth())){
			return false;
		}

		if(updated_year <= date.getFullYear() && (updated_month - 1) <= date.getMonth()){
			return true;
		}

		if(updated_month <= 12 && (updated_year < date.getFullYear() && ((updated_month - 1) > date.getMonth() || (updated_month - 1) < date.getMonth() || (updated_month - 1) === date.getMonth()))){
			return true;
		}

		if(updated_year === date.getFullYear() && (updated_month - 1) > date.getMonth()){
			return false;
		}
	}

	function validateUpdatedArticleDayOfMonth(updated_year,updated_month,updated_day){

		if(updated_month > 12){
			return false;
		}
		
		let days_in_provided_month = getUpdatedDaysInMonth(updated_year,updated_month);

		if(isBoolean(days_in_provided_month)){
			return days_in_provided_month;
		}

		return updated_day > days_in_provided_month ? false : true;
	}

	function getUpdatedDaysInMonth(yer,mnth){

		let is_leap_year = false;
		let months = getUpdatedMonths();

		if(yer % 4 === 0){
			is_leap_year = true;
		}
		let months_map = new Map(Array.from(Object.entries(months)));

        if(is_leap_year){
            let temp_months_keys_array =  Array.from(months_map.keys());

            let alter_feb = temp_months_keys_array.find(item => item === "Feb");

            if(alter_feb !== null || alter_feb !== undefined){
                months[alter_feb] = 29;
            }
        }

		let months_keys_array = Array.from(months_map.keys());

		let user_expected_month = months_keys_array[(mnth - 1)];

		if(typeof user_expected_month === "string"){
			return months[user_expected_month];
		}else{
			return false;
		}
	}

	function validateUpdatedArticleDate(year,month,day){

		if(year > new Date().getFullYear() || month > 12){
			return false;
		}

		let days_in_provided_month = getUpdatedDaysInMonth(year,month);

		if(isBoolean(days_in_provided_month) || day > days_in_provided_month){
			return false;
		}

		let usr_date = [year,month,day].join("-");

		let date_obj_of_usr_date = new Date(usr_date);

		return date_obj_of_usr_date.valueOf() > new Date().valueOf() ? false : true;
	}

	function getUpdatedMonths(){
		return {
		"Jan" : 31,
		"Feb" : 28,
		"Mar" : 31,
		"Apr" : 30,
		"May" : 31,
		"Jun" : 30,
		"Jul" : 31,
		"Aug" : 31,
		"Sep" : 30,
		"Oct" : 31,
		"Nov" : 30,
		"Dec" : 31,
		};
	}

	function handleUpdateArticleFile(event){
		SetUpdatedArticleFile(event.target.files[0]);
	}

	function handleUpdatedArticleName(event){
		Set_Updated_Article_Name(event.target.value);
	}

	function handleUpdatedArticleYear(event){
		Set_Updated_Article_Year(event.target.value);
	}

	function handleUpdatedArticleMonth(event){
		Set_Updated_Article_Month(event.target.value);
	}

	function handleUpdatedArticleDay(event){
		Set_Updated_Article_Day(event.target.value);
	}

	function updateWholeArticle(formdata){

		let auth_token = localStorage.getItem("kapt");

		axios({
			method:kapi.put,
			url: kapi.wholearticleupdateapi,
			data: formdata,
			headers:{
				'Content-Type':'multipart/form-data',
				'Authorization': `Bearer ${auth_token}`,
			},
			withCredentials: true,
		}).then(result => {
			if(result.status !== 200){
				throw new Error(result);
			}
			window.location.href = "/kal/admin/dashboard";
		}).catch(error => {
			SetUpdateArticleError("updateArticleErrorShowerSelected");
			if(error.response !== undefined){
				if(error.response.data === ""){
					localStorage.removeItem("kapt");
					window.location.href = "/";
				}
				if(error.response.data === false && error.response.status === 406){
					updateArticleDispather(updateArticleError("check article name, maybe its already there"));
					updateArticleDispather(updateArticleError("check provided date, it should not be grater than current"));
					updateArticleDispather(updateArticleError("may be article not exist"));
					setTimeout(() => {
						SetUpdateArticleError("updateArticleErrorShower");
						updateArticleDispather(resetUpdateArticleError());
					}, 5000);
				}
			}else{
				updateArticleDispather(updateArticleError("something is wrong may be server is down, please try later"));
				setTimeout(() => {
					SetUpdateArticleError("updateArticleErrorShower");
					updateArticleDispather(resetUpdateArticleError());
				}, 5000);
			}
		});
	}

	return(
		<div className="kalniyojanAdminUpdateArticleContainer">

			<div className="kalniyojanAdminUpdateArticleContainerContent">

				<div className="updateArticleTitle">Update Article</div>
				
				<div className={updateArticleErrorCls}>
					{Array.from(update_article_errors).map((item,index) => 
						<div key={index}>{item}</div>
					)}
				</div>
				<form onSubmit={event => handleUpdateArticleForm(event)} >
					<div className="adminUpdateArticleNameRow">
						<label htmlFor="updatearticlenamelabel"><span>*</span> Article Name:</label>
						<input type="text" name="updateArticleName" value={updated_article_name != null ? updated_article_name : "articleName"} className="adminUpdateArticleName" onChange={event => handleUpdatedArticleName(event)} ref={updateArticleNameRef}/>
					</div>
					<div className="adminUpdateArticlePublishDateRow">
						<div className="adminUpdateArticlePublishYear">
							<label htmlFor="updatearticleyearlabel"><span>*</span> Year:</label>
							<input type="number" name="updateArticlePublishYear" value={updated_article_year !== null ? updated_article_year : 1970} className="adminUpdateArticleYear" onChange={event => handleUpdatedArticleYear(event)} ref={updateArticlePublishYearRef}/>
						</div>
						<div className="adminUpdateArticlePublishMonth">
							<label htmlFor="updatearticlemonthlabel"><span>*</span> Month:</label>
							<input type="number" name="updateArticlePublishMonth" value={updated_article_month !== null ? updated_article_month : 1} className="adminUpdateArticleMonth" onChange={event => handleUpdatedArticleMonth(event)} ref={updateArticlePublishMonthRef}/>
						</div>
						<div className="adminUpdateArticlePublishDay">
							<label htmlFor="updatearticledaylabel"><span>*</span> Day:</label>
							<input type="number" name="updatearticlePublishDay" value={updated_article_day !== null ? updated_article_day : 1} className="adminUpdateArticleDay" onChange={event => handleUpdatedArticleDay(event)} ref={updateArticlePublishDayRef}/>
						</div>
					</div>
					<div className="adminUpdateArticleImage">
						<label htmlFor="updatearticleimagelabel"><span>*</span> ArticleFile:</label>
						<input type="file" name="updatearticlefile" className="adminUpdateArticleImage" onChange={event => handleUpdateArticleFile(event)} />
						<div className="updateArticleFileNote">NOTE: Leave this field blank if you don't want to change file!!!</div>
					</div>
					<div className="kalniyojanAdminUpdateArticleSubmitBtn">
						<button value="submit" type="submit">Submit</button>
					</div>
				</form>
			</div>
		</div>
	)

}

export default ArticleUpdate;