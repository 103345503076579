import axios from "axios";
import { isBoolean } from "lodash";
import kapi from "../Redux/kalniyojanapi";
import kalniyojanBillPaymentLogo from "../1.png";

let daysInMonths = {
	"Jan": 31,
	"Feb": 28,
	"Mar": 31,
	"Apr": 30,
	"May": 31,
	"Jun": 30,
	"Jul": 31,
	"Aug": 31,
	"Sep": 30,
	"Oct": 31,
	"Nov": 30,
	"Dec": 31,
};

let countrys = {
	Afghanistan: "Afghanistan",
	Albania: "Albania",
	Algeria: "Algeria",
	Andorra: "Andorra",
	Angola: "Angola",
	"Antigua and Barbuda":"Antigua and Barbuda",
	Argentina: "Argentina",
	Armenia: "Armenia",
	Australia:"Australia",
	Austria:"Austria",
	Azerbaijan:"Azerbaijan",
	Bahamas:"Bahamas",
	Bahrain:"Bahrain",
	Bangladesh:"Bangladesh",
	Barbados:"Barbados",
	Belarus:"Belarus",
	Belgium:"Belgium",
	Belize:"Belize",
	Benin:"Benin",
	Bhutan:"Bhutan",
	Bolivia:"Bolivia",
	"Bosnia and Herzegovina":"Bosnia and Herzegovina",
	Botswana:"Botswana",
	Brazil:"Brazil",
	Brunei:"Brunei",
	Bulgaria:"Bulgaria",
	"Burkina Faso":"Burkina Faso",
	Burundi:"Burundi",
	"Côte d'Ivoire":"Côte d'Ivoire",
	"Cabo Verde":"Cabo Verde",
	Cambodia:"Cambodia",
	Cameroon:"Cameroon",
	Canada:"Canada",
	"Central African Republic":"Central African Republic",
	Chad:"Chad",
	Chile:"Chile",
	China: "China",
	Colombia:"Colombia",
	Comoros:"Comoros",
	"Congo (Congo-Brazzaville)":"Congo (Congo-Brazzaville)",
	"Costa Rica":"Costa Rica",
	Croatia:"Croatia",
	Cuba:"Cuba",
	Cyprus:"Cyprus",
	"Czechia (Czech Republic)":"Czechia (Czech Republic)",
	"Democratic Republic of the Congo":"Democratic Republic of the Congo",
	Denmark:"Denmark",
	Djibouti:"Djibouti",
	Dominica:"Dominica",
	"Dominican Republic":"Dominican Republic",
	Ecuador:"Ecuador",
	Egypt:"Egypt",
	"El Salvador":"El Salvador",
	"Equatorial Guinea":"Equatorial Guinea",
	Eritrea:"Eritrea",
	Estonia:"Estonia",
	Swaziland:"Swaziland",
	Ethiopia:"Ethiopia",
	Fiji:"Fiji",
	Finland:"Finland",
	France:"France",
	Gabon:"Gabon",
	Gambia:"Gambia",
	Georgia:"Georgia",
	Germany:"Germany",
	Ghana:"Ghana",
	Greece:"Greece",
	Grenada:"Grenada",
	Guatemala:"Guatemala",
	Guinea:"Guinea",
	"Guinea-Bissau":"Guinea-Bissau",
	Guyana:"Guyana",
	Haiti:"Haiti",
	"Vatican City":"Vatican City",
	Honduras:"Honduras",
	Hungary:"Hungary",
	Iceland:"Iceland",
	India:"India",
	Indonesia:"Indonesia",
	Iran:"Iran",
	Iraq:"Iraq",
	Ireland:"Ireland",
	Israel:"Israel",
	Italy:"Italy",
	Jamaica:"Jamaica",
	Japan:"Japan",
	Jordan:"Jordan",
	Kazakhstan:"Kazakhstan",
	Kenya:"Kenya",
	Kiribati:"Kiribati",
	Kuwait:"Kuwait",
	Kyrgyzstan:"Kyrgyzstan",
	Laos:"Laos",
	Latvia:"Latvia",
	Lebanon:"Lebanon",
	Lesotho:"Lesotho",
	Liberia:"Liberia",
	Libya:"Libya",
	Liechtenstein:"Liechtenstein",
	Lithuania:"Lithuania",
	Luxembourg:"Luxembourg",
	Madagascar:"Madagascar",
	Malawi:"Malawi",
	Malaysia:"Malaysia",
	Maldives:"Maldives",
	Mali:"Mali",
	Malta:"Malta",
	"Marshall Islands":"Marshall Islands",
	Mauritania:"Mauritania",
	Mauritius:"Mauritius",
	Mexico:"Mexico",
	Micronesia:"Micronesia",
	Moldova:"Moldova",
	Monaco:"Monaco",
	Mongolia:"Mongolia",
	Montenegro:"Montenegro",
	Morocco:"Morocco",
	Mozambique:"Mozambique",
	Myanmar:"Myanmar",
	Namibia:"Namibia",
	Nauru:"Nauru",
	Nepal:"Nepal",
	Netherlands:"Netherlands",
	"New Zealand":"New Zealand",
	Nicaragua:"Nicaragua",
	Niger:"Niger",
	Nigeria:"Nigeria",
	"North Korea":"North Korea",
	"North Macedonia":"North Macedonia",
	Norway:"Norway",
	Oman:"Oman",
	Pakistan:"Pakistan",
	Palau:"Palau",
	Panama:"Panama",
	"Papua New Guinea":"Papua New Guinea",
	Paraguay:"Paraguay",
	Peru:"Peru",
	Philippines:"Philippines",
	Poland:"Poland",
	Portugal:"Portugal",
	Qatar:"Qatar",
	Romania:"Romania",
	Russia:"Russia",
	Rwanda:"Rwanda",
	"Saint Kitts and Nevis":"Saint Kitts and Nevis",
	"Saint Lucia":"Saint Lucia",
	"Saint Vincent and the Grenadines":"Saint Vincent and the Grenadines",
	Samoa:"Samoa",
	"San Marino":"San Marino",
	"Sao Tome and Principe":"Sao Tome and Principe",
	"Saudi Arabia":"Saudi Arabia",
	Senegal:"Senegal",
	Serbia:"Serbia",
	Seychelles:"Seychelles",
	"Sierra Leone":"Sierra Leone",
	Singapore:"Singapore",
	Slovakia:"Slovakia",
	Slovenia:"Slovenia",
	"Solomon Islands":"Solomon Islands",
	Somalia:"Somalia",
	"South Africa":"South Africa",
	"South Korea":"South Korea",
	"South Sudan":"South Sudan",
	Spain:"Spain",
	"Sri Lanka":"Sri Lanka",
	Sudan:"Sudan",
	Suriname:"Suriname",
	Sweden:"Sweden",
	Switzerland:"Switzerland",
	Syria:"Syria",
	Tajikistan:"Tajikistan",
	Tanzania:"Tanzania",
	Thailand:"Thailand",
	"Timor-Leste":"Timor-Leste",
	Togo:"Togo",
	Tonga:"Tonga",
	"Trinidad and Tobago":"Trinidad and Tobago",
	Tunisia:"Tunisia",
	Turkey:"Turkey",
	Turkmenistan:"Turkmenistan",
	Tuvalu:"Tuvalu",
	Uganda:"Uganda",
	Ukraine:"Ukraine",
	"United Arab Emirates":"United Arab Emirates",
	"United Kingdom":"United Kingdom",
	"United States of America":"United States of America",
	Uruguay:"Uruguay",
	Uzbekistan:"Uzbekistan",
	Vanuatu:"Vanuatu",
	Venezuela:"Venezuela",
	Vietnam:"Vietnam",
	Yemen:"Yemen",
	Zambia:"Zambia",
	Zimbabwe:"Zimbabwe",
};

export let muhurtaTypes = {
    select: "select",
    vastukharedi: "Vastu Kharedi/Land Purchase",
    bhoomi: "Bhoomi Pujan/Land Puja",
    gruhapravesh: "Gruha Pravesh/House warming ceremony",
    vastushanti: "Vastu Shanti",
    saakharpuda: "Saakhar Puda/Engagement ceremony",
    marriage: "Marriage",
    garbhadhan: "Garbha Dhan/Conceiving",
    dohaal: "Dohaal Jevan/Baby shower",
    delivery: "Normal/Cesarean",
    jananshanti: "Janan Shanti",
    baarsa: "Baarsa",
    jawal: "Jaawal",
    naamkaran: "Naamkaran/Naming ceremony",
    kaantochne: "Kaan Tochne/Ear piercing",
    annaprashan: "Anna Prashan/Rice-feeding ceremony/Grain initiation",
    upanayan: "Upanayan for male",
    rutumati: "Rutumati for female",
    gold: "Gold Kharedi/Gold Purchase",
    silver: "Silver Kharedi/Silver Purchase",
    vehical: "Vehical Kharedi/Vehical Purchase",
    machine: "Machine Kharedi/Machine Purchase",
    perni: "Perni/Seed Sowing",
    vyaparstart: "Vyapar Udghatan/Business Inauguration",
    osudghatan: "Office/Shop Inauguration",
    journey: "Pravaas Muhurta/Journey Muhurta",
    kalrahu: "KaalRahu Muhurta",
    tarabal: "Tarabal",
    nakshatrapaya: "Nakshatra Paya",
    borewell: "New Borewell",
    animal: "Animal Purchase/sell",
    tillering: "Tillering",
    cropping: "Cropping",
    door: "Door Muhurta",
};

export function nameValidator(name){

	let nameRegex = /[a-zA-Z]{5,}/i;
	let isNameValid = nameRegex.test(name);
	return isNameValid;

}

export function muhurtaValidator(muhurta){
	let muhurtaExist = Object.values(muhurtaTypes).find(m => m === muhurta);
	return muhurtaExist === undefined ? false : true;
}

export function emailValidator(email){

	if (email === "") {
		return false;
	}
	let emailRegex = /[a-zA-Z0-9\-_.]+@(gmail|hotmail|yahoo|rediffmail)?(?:\.(com))/
	let isEmailValid = emailRegex.test(email);
	return isEmailValid;
}



export function dateValidator(date){

	if(new Date(date).valueOf() > new Date().valueOf()){
		return false;
	}
	let isDateValid = dateValidate(new Date(date));
	if(Object.values(isDateValid).includes(false)){
		return false;
	}
	return true;
}

function dateValidate(date){

	let dateValidateObj = {
		year: true,
		month: true,
		day: true,
	};

	if (date.getFullYear() === "" || date.getFullYear() <= 1900 || date.getFullYear().toString().length < 4) {
		dateValidateObj.year = false;
	}
	if(date.getFullYear() > new Date().getFullYear()){
		dateValidateObj.year = false;
	}

	if (date.getMonth() === "") {
		dateValidateObj.month = false;
	}

	if (date.getMonth() > 12 || (date.getFullYear() > new Date().getFullYear() && (date.getMonth() - 1) >= new Date().getMonth())) {
		dateValidateObj.month = false;
	}

	if (date.getFullYear() <= new Date().getFullYear() && (date.getMonth() - 1) <= new Date().getMonth()) {
		dateValidateObj.month = true;
	}

	if (date.getMonth() <= 12 && (date.getFullYear() < new Date().getFullYear() && ((date.getMonth() - 1) > new Date().getMonth() || (date.getMonth() - 1) < new Date().getMonth() || (date.getMonth() - 1) === new Date().getMonth()))) {
		dateValidateObj.month = true;
	}

	if (date.getFullYear() === new Date().getFullYear() && (date.getMonth() - 1) > new Date().getMonth()) {
		dateValidateObj.month = false;
	}

	if (date.getDate() === "") {
		dateValidateObj.day = false;
	}
	let patrikaDaysInMonth = getDaysInMonth(date.getFullYear(), date.getMonth());
	if (isBoolean(patrikaDaysInMonth)) {
		dateValidateObj.day = false;
	}
	if(date.getDate() > patrikaDaysInMonth){
		dateValidateObj.day = false;
	}        
	return dateValidateObj;
}

let getDaysInMonth = function(year, month){

	let is_leap_year = false;
	let months = daysInMonths;

	if (year % 4 === 0) {
		is_leap_year = true;
	}

	let months_map = new Map(Array.from(Object.entries(months)));

	if (is_leap_year) {
		let temp_months_keys_array = Array.from(months_map.keys());

		let alter_feb = temp_months_keys_array.find(item => item === "Feb");

		if (alter_feb !== null || alter_feb !== undefined) {
			months[alter_feb] = 29;
		}
	}

	let months_keys_array = Array.from(months_map.keys());

	let user_expected_month = months_keys_array[(month)];

	if (typeof user_expected_month === "string") {
		return months[user_expected_month];
	} else {
		return false;
	}
}

export function getStripeAppearance(clientSecret){
	return{
		clientSecret,
		appearance:{
			theme:'flat',
		},
	};
}

export function timeValidator(time){

	let isTimeValid = timeValidate(time);
	if(isBoolean(isTimeValid)){
		return false;
	}
	if(Object.values(isTimeValid).includes(false)){
		return false;
	}
	return true;
}

function timeValidate(time){

	let timeobj = {
		hour: true,
		minute: true,
	};

	if(time === null || time === " " || time === undefined || time === ""){
		return false;
	}

	let trimedtime = time.split(":");
	if(trimedtime.length < 2 || trimedtime.length > 2 || trimedtime.length !== 2){
		return {
			...timeobj,
			hour: false,
			minute: false,
		};
	}
	let minute = parseInt(trimedtime[1]);
	let hour = parseInt(trimedtime[0]);

	if(hour === "" || hour < 0 || hour > 24){
		timeobj.hour = false;
	}

	if(minute === "" || minute < 0 || minute > 60){
		timeobj.minute = false;
	}

	return timeobj;
}

export function placeValidator(place){
	return placeValidate(place);
}

function placeValidate(place){

	if(place === ""){
		return false;
	}
			
	if (place.length < 4) {
		return false;
	}
	let placeRegex = /[a-zA-Z]{4,}/
	let isPlaceValid = placeRegex.test(place);
	if (!isPlaceValid) {
		return false;
	}
	let advancedPlaceRegex = /(?=.*[@!+._\-?{}[\]])[@!+._\-?{}[\]]/
	if (advancedPlaceRegex.test(place)) {
		return false;
	}
	return true;
}

export function countryValidator(country){

	let countryarr = Object.values(countrys);
	let countryExist = countryarr.find(item => item === country);
	if(countryExist){
		return true;
	}else{
		return false;
	}
}

export let uploadClientAdvice = async function(formdata){
	  	let adviceUploadResponse = null;
	 	try{
			adviceUploadResponse = await axios({
				method: kapi.post,
				url: kapi.addadviceapi,
				data: formdata,
				headers:{
					"Content-Type":"application/json",
				},
				withCredentials: true,
			});

			if(adviceUploadResponse.status === 200){
				return {
					response: adviceUploadResponse,
					success: true,
				};
			}

		}catch(error){
			if(error.response !== undefined){
				return {
					response: null,
					success: false,
					message: error.response.data.message,
				};
			}else {
				return {
					response: null,
					success: false,
					message: "failed to upload may be server is down, try later/again",
				};
			}
		}
	}

export async function getClientIp(){

    try {
        let getIpApiResponse = await axios({
            method: kapi.get,
            url: "https://api.ipify.org/?format=json",
        });

        if(getIpApiResponse.status !== 200){
            return "211.224.65.50";
        }else {
            return getIpApiResponse.data.ip;
        }
            
    } catch (error) {
        return "211.224.65.50";
    }
}

export function getBillSdkConfig(...billSdkData){

    let flow_obj = {
        merchantId: billSdkData.at(1),
        bdOrderId: billSdkData.at(0),
        authToken: billSdkData.at(2),
        childWindow: true,
        prefs: {
        "payment_categories": ["card","upi","gpay","qr","nb"],
        },
        netBanking:{
            "showPopularBanks":"N",
        }
    }

    let config = {
        responseHandler: null,
        merchantLogo: kalniyojanBillPaymentLogo,
        flowConfig: flow_obj,
        flowType: "payments",
    }

	return config;
}

export let uploadClientPatrika = async function(patrika_form_data){
	  	let patrikaUploadResponse = null;
	 	try{
			patrikaUploadResponse = await axios({
				method: kapi.post,
				url: kapi.addjanmapatrikaapi,
				data: patrika_form_data,
				headers:{
					"Content-Type":"application/json",
				},
				withCredentials: true,
			});

			if(patrikaUploadResponse.status === 200){
				return {
					response: patrikaUploadResponse,
					success: true,
				};
			}

		}catch(error){
			if(error.response !== undefined){
				return {
					response: null,
					success: false,
					message: error.response.data.message,
				};
			}else {
				return {
					response: null,
					success: false,
					message: "failed to upload may be server is down, try later/again",
				};
			}
		}
}

export let uploadClientMatchMaking = async function(matchMaking_form_data){
	let matchMakingUploadResponse = null;

	try{
		matchMakingUploadResponse = await axios({
			method: kapi.post,
			url: kapi.addmatchmakingapi,
			data:matchMaking_form_data,
			headers: {
				"Content-Type": "application/json",
			},
			withCredentials: true,
		});

		if(matchMakingUploadResponse.status === 200){
			return{
				response: matchMakingUploadResponse,
				success: true,
			};
		}
	}catch(error){

		if(error.response !== undefined){
			return{
				response: null,
				success: false,
				message: error.response.data.message,
			};
		}else{
			return{
				response: null,
				success: false,
				message: "failed to upload matchMaking form may be server is down try again/later",
			};
		}
	}

}

export let uploadClientMuhurta =  async function(muhurta_data){

	let {active,normal,garbha,all,muhurtaTypes} = muhurta_data;

	let muhurta_form_upload_obj = {

		uploadGarbhaDhan: async function(garbhadhan_data){

			let garbhadhanUploadResponse = null;

			try{
				garbhadhanUploadResponse = await axios({
					method: kapi.post,
					url: kapi.garbhadhamuhurtasubmitapi,
					data: garbhadhan_data,
					headers: {
						"Content-Type": "application/json",
					},
					withCredentials: true,
				});

				if(garbhadhanUploadResponse.status === 200){
					return {
						response: garbhadhanUploadResponse,
						success: true,
					};
				}
			}catch(error){
				if(error.response !== undefined){
					return{
						response: null,
						success: false,
						message: error.response.data.message,
					};
				}else{
					return{
						response: null,
						success: false,
						message: "failed to upload muhurta may be server is down try later",
					};
				}
			}
		},

		uploadOneForAllMuhurta: async function(oneforall_data){
			
			let oneforallUploadResponse = null;

			try{
				oneforallUploadResponse = await axios({
					url: kapi.oneforallmuhurtasubmitapi,
					method: kapi.post,
					data: oneforall_data,
					headers: {
						"Content-Type": "application/json",
					},
					withCredentials: true,
				});

				if(oneforallUploadResponse.status === 200){
					return {
						response: oneforallUploadResponse,
						success: true,
					};
				}
			}catch(error){

				if(error.response !== undefined){
					return{
						response: null,
						success: false,
						message: error.response.data.message,
					};
				}else{
					return{
						response: null,
						success: false,
						message: "failed to upload muhurta may be server is down try later",
					};
				}
			}
		},

		uplaodNormalMuhurta: async function(normal_data){
			
			let normalUploadResponse = null;

			try{
				normalUploadResponse = await axios({
					url: kapi.normalmuhurtasubmitapi,
					method: kapi.post,
					data: normal_data,
					headers:{
						"Content-Type": "application/json",
					},
					withCredentials: true,
				});

				if(normalUploadResponse.status === 200){
					return{
						response: normalUploadResponse,
						success: true,
					};
				}
			}catch(err){

				if(err.response !== undefined){
					return{
						response: null,
						success: false,
						message: err.response.data.message,
					};
				}else{
					return{
						response: null,
						success: false,
						message: "failed to upload muhurta may be server is down try later",
					};
				}
			}
		},


	}
	
	switch(active){
		
		case muhurtaTypes.garbhadhan:
			return muhurta_form_upload_obj.uploadGarbhaDhan(garbha);
		
		case muhurtaTypes.vastukharedi:
		case muhurtaTypes.vastushanti:
		case muhurtaTypes.upanayan:
		case muhurtaTypes.bhoomi:
		case muhurtaTypes.rutumati:
		case muhurtaTypes.delivery:
		case muhurtaTypes.vehical:
		case muhurtaTypes.machine:
			return muhurta_form_upload_obj.uploadOneForAllMuhurta(all);
	
		default:
			return muhurta_form_upload_obj.uplaodNormalMuhurta(normal);
	}
}

export let uploadUserFeedback = async function(feedback){

	let uploadFeedbackResponse = null;

	try{
		uploadFeedbackResponse = await axios({
			url: kapi.userfeedback,
			method: kapi.post,
			data: feedback,
			headers: {
				"Content-Type": "application/json",
			},
			withCredentials: true,
		});

		if(uploadFeedbackResponse.status === 200){
			return{
				response: uploadFeedbackResponse,
				success: true,
			};
		}
	}catch(error){
		
		if(error.response !== undefined){
			return{
				response: null,
				success: false,
				message: error.response.data.message,
			};
		}else{
			return{
				response: null,
				success: false,
				message: "failed to upload muhurta may be server is down try later",
			};
		}
	}
}

export async function getVastuOrder(data){
  return new Promise(async function(resolve,reject){

    let vastuOrderResponse = null;
    try{
      vastuOrderResponse = await axios({
        url: kapi.borderidapi,
        method: kapi.post,
        data: data.data,
        headers:{
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if(vastuOrderResponse.status === 200){
				resolve({
					response: vastuOrderResponse,
					success: true,
				});
      }

    }catch(error){
      if(error.response !== undefined){
        reject({
          response: null,
          success: false,
          message: error.response.data.message,
        });
      }else{
        reject({
          response: null,
          success: false,
          message: "something went wrong may be server is down try later",
        });
      }
    }
  });
}

export async function uploadVastu(formData){


	let uploadVastuRes = null;

	return new Promise(async function(resolve,reject){

		try{
			uploadVastuRes = await axios({
				url: kapi.addvastuapi,
				method: kapi.post,
				data: formData,
				headers: {
          "Content-Type":"multipart/form-data",
				},
				withCredentials: true,
			});

			if(uploadVastuRes.status === 200){
				resolve({
					response: uploadVastuRes,
					success: true,
				});
			}
		}catch(error){

			if(error.response !== undefined){
				reject({
					response: null,
					success: false,
					message: error.response.data.message,
				});
			}else{
				reject({
					response: null,
					success: false,
					message: "failed to upload vastu may be server is down try later",
				})
			}
		}
	});

}