import VisitorType from '../Type/VisitorsType';


function getVisitors(result){
    
    let trim = result.data;

    return {
        type: VisitorType.visitors,
        payload: trim,
    };
}

export default getVisitors;