import ServicesType from "../Type/ServicesType";

let ServicesInitialState = {
    advice: [],
    matchMaking: [],
    patrika: [],
    muhurta: [],
    feedback: [],
};

function ServicesReducer (state = ServicesInitialState,action){

    switch(action.type){

        case ServicesType.getAdvice:
            return{
                ...state,
                advice:[
                    ...action.payload,
                ],
            };

        case ServicesType.setMatchMaking:
            return{
                ...state,
                matchMaking:[
                    ...action.payload,
                ],
            };

        case ServicesType.setPatrika:
            return{
                ...state,
                patrika:[
                    ...action.payload,
                ],
            };

        case ServicesType.setMuhurta:
            return{
                ...state,
                muhurta:[
                    ...action.payload,
                ],
            };

        case ServicesType.setFeedback:
            return{
                ...state,
                feedback: [
                    ...action.payload,
                ],
            };

        default:
            return{
                ...state,
            };
    }
}

export default ServicesReducer;