

const FeesType = {
    adviceFee: "advicefee",
    matchMakingFee: "matchMakingFee",
    janmaPatrikaFee: "janmaPatrikaFee",
    muhurtafee: "muhurtaFee",
    vastuFee: "vastuFee",
};

export default FeesType;