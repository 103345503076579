import { useEffect, useState } from "react";

import "./Article.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {getAllArticle} from "../Redux/Action/ArticleAction";

import {addBaseArticleListError, resetArticleListError} from '../Redux/Action/ErrorAction';
import { useNavigate } from "react-router-dom";
import kapi from "../Redux/kalniyojanapi";

function Article(props){

    let dispatch = useDispatch();

    let selector;

    let [baseArticleListError, SetBaseArticleListError] = useState("baseArticleListErrorShower");

    let articleDownloadSuccessfulEventNavigation = useNavigate();

    useEffect(() => {

        SetBaseArticleListError("baseArticleListErrorShower");
        dispatch(resetArticleListError());

        axios({
            method: kapi.get,
            url: kapi.articleapi,
            withCredentials: true,
        }).then(result => {
            if(result.status !== 200){
                throw new Error(result);
            }else{
                dispatch(getAllArticle(result));
            }
        }).catch(error => {
            if(error.response === undefined){
                SetBaseArticleListError("baseArticleListErrorShowerSelected");
                dispatch(addBaseArticleListError("something is wrong try again later"));
            }else{
                SetBaseArticleListError("baseArticleListErrorShowerSelected");
                dispatch(addBaseArticleListError(error.response.data));
            }
        });

    },[selector]);
    
    selector = useSelector(state => state.articles.article);
    let articleErrorSelector = useSelector(state => state.errors.articleListError);

    function handleDownloadEvent(link,name){
        let adminDownloadBtn = document.createElement("a");
        adminDownloadBtn.href = link;
        adminDownloadBtn.setAttribute("download",name);
        document.body.appendChild(adminDownloadBtn);
        adminDownloadBtn.click();
        adminDownloadBtn.remove();

        setTimeout(() => {
            articleDownloadSuccessfulEventNavigation("/servicessupport");
        },1520);
    }

    return(
        <div className="kalniyojanArticleContainer">
            <div className="kalniyojanArticleContainerContent">

                <div className="articleTitle"><strong>Articles</strong></div>

                <div className={baseArticleListError}>
                    {Array.from(articleErrorSelector).map((item,index) => 
                        <div key={index}>{item}</div>
                    )}
                </div>

                {Array.from(selector).length > 0 ? 
                    <table className="kalniyojanArticleTable">
                        <thead className="kalniyojanTableHeader">
                            <tr>
                                <td className="articleFileName">Filename</td>
                                <td className="articlePublishedDate">Publish Date</td>
                                <td className="articleDownload">Download Article</td>
                            </tr>
                        </thead>
                        <tbody className="kalniyojanTableBody">
                            {Array.from(selector).map(item => 
                                <tr key={item.key}>
                                    <td>{item.filename}</td>
                                    <td>{item.date}</td>
                                    <td><button title="Download Article" onClick={() => {handleDownloadEvent(item.download,item.filename)}}>Download</button></td>
                                </tr>
                            )}
                        </tbody>
                    </table> : <div className="baseEmptyArticleList">No Article Found!!!</div>
                }
            </div>
        </div>
    );
}

export default Article;