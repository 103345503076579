import React, { useEffect } from "react";
import './Footer.css';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import getVisitors from "../Redux/Action/VisitorsAction";
import kapi from "../Redux/kalniyojanapi";

function Footer(props){
    
    let visitorSelector;
    let visitorDispatcher = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            axios({
                method: kapi.get,
                url: kapi.footervisitorapi,
                withCredentials: true,
            }).then(result => {
                if(result.status !== 200){
                    throw new Error(result);
                }
                visitorDispatcher(getVisitors(result));
            }).catch(error => {
                if(error.data === undefined || error.status !== 200){
                    visitorDispatcher(getVisitors({data:"no data"}));
                }
            });
        },1000);
    },[visitorSelector]);

    visitorSelector = useSelector(state => state.visitors);


    return(
        <div className="kalniyojanFooterContainer">
            <div className="kalniyojanCopyRight">
                <div className="reservedRights">
                    <span className="copyRight">&copy; CopyRight Of KALNIYOJAN</span>
                    <span className="reserved">All Rights Reserved</span>
                </div>
            </div>

            <div className="kalniyojanHelpTermPolicyFooter">
                <div className="kalniyojanTerms" onClick={() => window.location.href = "/terms"}>
                    Terms Of Use
                </div>
                <div className="kalniyojanPolicy" onClick={() => window.location.href = "/privacypolicy"}>
                    Privacy Policy
                </div>
                <div className="kalniyojanRefundPolicy" onClick={() => window.location.href = "/refundpolicy"}>
                    Refund Policy
                </div>
                <div className="kalniyojanHelp" onClick={() => window.location.href = "/help"}>
                    Help
                </div>
            </div>

            <div className="visitorCounter">
                <div className="visitorLabel">Visitors:</div>
                <div className="visitorNumbers">{visitorSelector.visitor}</div>
            </div>

            <div className="developer">
                <strong>Developer::</strong> adityakolhapure7@gmail.com
            </div>

        </div>
    );
}

export default Footer;