import { useEffect, useState } from "react";

import "../Admin/Admin.css";

import AdminArticleList from "../../Componants/AdminArticleList";
import AdminAddArticle from "../../Componants/AdminAddArticle";
import AdminMainPageImage from "../../Componants/AdminMainPageImage";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logoutError, resetLogout } from "../../Redux/Action/ErrorAction";
import AdminAdvice from "../AdminAdvice";
import AdminMatchMaking from "../../Componants/AdminMatchMaking";
import kapi from "../../Redux/kalniyojanapi";
import AdminPatrika from "../../Componants/AdminPatrika";
import { AdminMuhurta } from "../../Componants/AdminMuhurta";
import Feedback from "../../Componants/AdminFeedback";

function Admin(props){

    let adminArtiListClsName = "kalniyojanAdminListArticle";
    let adminArtiAddClsName = "kalniyojanAdminAddArticle";
    let adminMainPgeImgClsName = "kalniyojanAdminMainpageImages";
    let adminDashboardAdviceCls = "dashboardAdviceTab";
    let adminDashboardMatchMakingCls = "dashboardMatchmakingTab";
    let adminDashboardPatrikaCls = "adminDashboardPatrikaTab";
    let adminDashboardMuhurtaCls = "adminDashboardMuhurtaTab";
    let adminFeedbackCls = "adminDashboardFeedbackTab";

    let [article,setArticle] = useState(true);
    let [newarticle,setNewArticle] = useState(false); 
    let [mainpage,setMainPage] = useState(false);
    let [advice,setAdvice] = useState(false);
    let [matchMaking, SetMatchMaking] = useState(false);
    let [adminPatrika, SetAdminPatrika] = useState(false);
    let [adminMuhurta, SetAdminMuhurta] = useState(false);
    let [adminFeedback, SetAdminFeedback] = useState(false);

    let [logoutCls,setLogoutCls] = useState("LogoutBar")

    let [adminError,SetAdminError] = useState("adminErrorShower");

    let logoutDispatcher = useDispatch();

    let logoutSelector = null;

    useEffect(() => {
        
        let kapt_exist = localStorage.getItem("kapt");

        if(kapt_exist === null || kapt_exist === undefined){
            window.location.href = "/login";
        }else{
            setLogoutCls("LogoutBarSelected");
        }
    });

    let handleNewArticle = () => {
        setArticle(false);
        setMainPage(false);
        setNewArticle(true);
        setAdvice(false);
        SetMatchMaking(false);
        SetAdminPatrika(false);
        SetAdminMuhurta(false);
        SetAdminFeedback(false);
    }

    let handleArticleList = () => {
        setNewArticle(false);
        setMainPage(false);
        setArticle(true);
        setAdvice(false);
        SetMatchMaking(false);
        SetAdminPatrika(false);
        SetAdminFeedback(false);
        SetAdminMuhurta(false);
    }

    let handleMainPageImage = () => {
        setArticle(false);
        setNewArticle(false);
        setMainPage(true);
        setAdvice(false);
        SetMatchMaking(false);
        SetAdminPatrika(false);
        SetAdminMuhurta(false);
        SetAdminFeedback(false);
    }

    let handleAdviceTab = () => {
        setAdvice(true);
        setArticle(false);
        setNewArticle(false);
        setMainPage(false);
        SetMatchMaking(false);
        SetAdminPatrika(false);
        SetAdminMuhurta(false);
        SetAdminFeedback(false);
    }

    let handleMatchMakingTab = () => {
        SetMatchMaking(true);
        setAdvice(false);
        setArticle(false);
        setNewArticle(false);
        setMainPage(false);
        SetAdminPatrika(false);
        SetAdminMuhurta(false);
        SetAdminFeedback(false);
    }

    function handlePatrikaTab(){
        SetAdminPatrika(true);
        SetMatchMaking(false);
        setAdvice(false);
        setArticle(false);
        setNewArticle(false);
        setMainPage(false);
        SetAdminMuhurta(false);
        SetAdminFeedback(false);
    }

    function handleMuhurtaTab(){
        SetAdminMuhurta(true);
        SetAdminPatrika(false);
        SetMatchMaking(false);
        setAdvice(false);
        setArticle(false);
        setNewArticle(false);
        setMainPage(false);
        SetAdminFeedback(false);
    }

    function handleFeedbackTab(){
        SetAdminFeedback(true);
        SetAdminMuhurta(false);
        SetAdminPatrika(false);
        SetMatchMaking(false);
        setAdvice(false);
        setArticle(false);
        setNewArticle(false);
        setMainPage(false);
    }

    adminArtiListClsName = article ? "kalniyojanAdminListArticleSelect" : "kalniyojanAdminListArticle";
    adminArtiAddClsName = newarticle ? "kalniyojanAdminAddArticleSelect" : "kalniyojanAdminAddArticle";
    adminMainPgeImgClsName = mainpage ? "kalniyojanAdminMainpageImagesSelect" : "kalniyojanAdminMainpageImages";
    adminDashboardAdviceCls = advice ? "dashboardAdviceTabSelect" : "dashboardAdviceTab";
    adminDashboardMatchMakingCls = matchMaking ? "dashboardMatchmakingTabSelect" : "dashboardMatchmakingTab";
    adminDashboardPatrikaCls = adminPatrika ? "adminDashboardPatrikaTabSelect" : "adminDashboardPatrikaTab";
    adminDashboardMuhurtaCls = adminMuhurta ? "adminDashboardMuhurtaTabSelect" : "adminDashboardMuhurtaTab";
    adminFeedbackCls = adminFeedback ? "adminDashboardFeedbackTabSelect" : "adminDashboardFeedbackTab";


    function clearCredentials(){
        localStorage.removeItem("kapt");
        window.location.href = "/";
    }

    function handleLogout(){
        let logout_kapt = localStorage.getItem("kapt");
        if(logout_kapt === null){
            window.location.href = "/login";
        }

        axios({
            method: kapi.post,
            url: kapi.logoutapi,
            headers:{
                Authorization: `Bearer ${logout_kapt}`,
            },
            withCredentials: true,
        }).then(result => {
            if(result.status !== 200 || result.data === undefined){
                throw new Error("can not logout something is wrong");
            }
            clearCredentials();
        }).catch(error => {
            if(error.response !== undefined){
                if(error.response.data === "" && error.response.status === 401){
                    localStorage.removeItem("kapt");
                    window.location.href = "/";
                }
            }
            logoutDispatcher(logoutError("can not logout something is wrong, failed to logout"));
            setTimeout(() => {
                SetAdminError("adminErrorShower");
                logoutDispatcher(resetLogout());
            }, 5000);
        });
    }

    logoutSelector = useSelector(state => state.errors.logoutError);

    return(
        <div className="kalniyojanAdminContainer">
            <div className="kalniyojanAdminContainerContent">
                <div className={adminError}>
                    {Array.from(logoutSelector).map((item,index) => 
                        <div key={index}>{item}</div>
                    )}
                </div>
                <div className="kalniyojanArea">
                    <div className={adminArtiListClsName} onClick={handleArticleList}>
                        Articles
                    </div>
                    <div className={adminArtiAddClsName} onClick={handleNewArticle}>
                        New Articles
                    </div>
                    <div className={adminMainPgeImgClsName} onClick={handleMainPageImage}>
                        Main Page Images
                    </div>
                    <div className={adminDashboardAdviceCls} onClick={handleAdviceTab}>
                        Advice
                    </div>
                    <div className={adminDashboardMatchMakingCls} onClick={handleMatchMakingTab}>
                        MatchMaking
                    </div>
                    <div className={adminDashboardPatrikaCls} onClick={handlePatrikaTab}>
                        Patrika
                    </div>
                    <div className={adminDashboardMuhurtaCls} onClick={handleMuhurtaTab}>
                        Muhura
                    </div>
                    <div className={adminFeedbackCls} onClick={handleFeedbackTab}>
                        Feedback
                    </div>
                </div>
                <div className="adminDashboardHeader">
                    <h1>Admin Dashboard</h1>
                </div>
            </div>
            <div className={logoutCls}>
                <p>
                    <button type="submit" onClick={handleLogout}>Logout</button>
                </p>
            </div>
            <div className="kalniyojanAdminScreenSwitcher">
                <AdminArticleList show = {article} />
                <AdminAddArticle show = {newarticle}/>
                <AdminMainPageImage show = {mainpage}/>
                <AdminAdvice show = {advice}/>
                <AdminMatchMaking show = {matchMaking} />
                <AdminPatrika show = {adminPatrika} />
                <AdminMuhurta show = {adminMuhurta} />
                <Feedback show = {adminFeedback} />
            </div>
        </div>
    );
}


export default Admin;