

import "./Refund.css";

function Refundpolicy(){

    return(
        <div className="kalniyojanRefundPolicyContainer">
            <div className="kalniyojanRefundPolicyContainerContent">
                <h2>Kalniyojan Refund Policy</h2>

                <p>
                    Most of the Refund policies are Explained below. kalniyojan could make changes to these policies in future.
                </p>

                <div className="kalniyojanRefundPolicyDescription">
                    <ol>
                        <li>
                            <div>Time period to apply for product return</div>
                            <div>Customers are eligible to return the product within 2 days/48 hours after 
                                receiving/shippment of the product from kalniyojan.
                            </div>
                        </li>
                        <li>
                            <div>How to apply for product return/cancellation</div>
                            <div>To apply for product return/cancellation. go to the help section of the website and click
                                on the return product, then fill the form with require field and submit.
                                after reciving the confirmation email from kalniyojan that product is purchased from kalniyojan.com.
                                you need to send product to kalniyojan address. (please check address on contact page). kalniyojan do not
                                provide pick-up service of the return product. customer need to pay for whichever courier service they use to send product
                                back to Kalniyojan.
                            </div>
                        </li>
                        <li>
                            <div>who is eligible to apply for returning the product</div>
                            <div>customers who purchased product from kalniyojan.com are eligible to apply for return.</div>
                        </li>
                        <li>
                            <div>On which services customers are eligible to apply for return</div>
                            <div>Customers are only eligible to apply for the services which contains tangible product.
                                customers will not be eligible to apply for return if customer provided wrong info/ mistakes 
                                happend from customer side at the time of filling the forms, or
                                if customer receive negative answer from the service they use on this website.
                                
                            </div>
                        </li>
                        <li>
                            <div>When will customer receive refund after returning the product to kalniyojan.</div>
                            <div>with in 2 to 3 days after getting the confirmation mail from kalniyojan.</div>
                        </li>
                        <li>
                            <div>refund Policy for donation</div>
                            <div>refund policies are only applicable for the tangible products. donation can not be refunded.</div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default Refundpolicy;