
let ErrorTypes = {
    newArticleListError: "addArticleListError",
    resetArticleList: "resetArticleListError",
    newAritcleFormError: "newArticleAddError",
    deleteArticleError: "deleteArticle",
    updateArticleError: "updateArticleError",
    resetUpdateArticleError: "resetUpdateArticleError",
    resetDeleteArticle: "resetDeleteArticleError",
    mainPageFormError: "mainPageImgvalidError",
    resetNewArticle: "resetNewArticleError",
    resetMainPage: "resetMainPageError",
    mainPageError: "mainPageError",
    adviceError: "adviceFormError",
    aAdviceError: "AdminAdviceError",
    resetAAdviceError: "resetAdminAdviceError",
    resetAdviceError: "resetAdviceFormError",
    invalidLogin: "loginError",
    logoutError: "logoutError",
    resetLogoutError: "resetLogout",
    resetLogin: "resetLoginError",
    resetAll: "resetAll",
    advicePaymentError: "paymentAdviceError",
    resetAdvicePaymentError: "resetAdvicePaymentError",
    matchMakingError: "matchMakingError",
    resetMatchMakingError: "resetMatchMakingError",
    matchMakingPaymentError: "matchMakingPaymentError",
    resetMatchMakingPaymentError:"resetMatchMakingPaymentError",
    aMatchMakingError: "adminMatchMakingError",
    resetAMatchMakingError: "resetAdminMatchMakingError",
    janmaPatrikaError: "janmaPatrikaError",
    resetJanmaPatrikaError: "resetJanmaPatrikaError",
    setJanmaPatrikaPaymentError: "setJanmaPatrikaPaymentError",
    resetJanmaPatrikaPaymentError: "resetJanmaPatrikaPaymentError",
    setMuhurtaError: "setMuhurtaError",
    resetMuhurtaError: "resetMuhurtaError",
    setMuhurtaPaymentError: "setMuhurtaPaymentError",
    resetMuhurtaPaymentError: "resetMuhurtaPaymentError",
    aPatrikaError: "aPatrikaError",
    resetAPatrikaError: "resetAPatrikaError",
    setAmuhurtaError: "setAmuhurtaError",
    resetAmuhurtaError: "resetAmuhurtaError",
    setFeedbackError: "setFeedbackError",
    resetFeedbackError: "resetFeedbackError",
    setRefundCancellationError: "setRefundCancellationError",
    resetRefundCancellationError: "resetRefundCancellationError",
    setAFeedbackError: "AFeedbackError",
    resetAFeedbackError: "resetAFeedbackError",
    setVastuPaymentError: "vastuPaymentError",
    resetVastuPaymentError: "resetVastuPaymentError",
    setVastuError: "vastuError",
    resetVastuError: "resetVastuError",
};

export default ErrorTypes;
