import { configureStore, combineReducers } from "@reduxjs/toolkit";
import articleReducer from "./Reducer/ArticleReducer";
import errorReducer from "./Reducer/ErrorReducer";
import visitorReducer from "./Reducer/VisitorReducer";
import mainPageReducer from "./Reducer/MainPageReducer";
import ServicesReducer from "./Reducer/ServicesReducer";
import FeeReducer from "./Reducer/FeesReducer";

let reducers = combineReducers({

    articles: articleReducer,
    errors: errorReducer,
    visitors: visitorReducer,
    main: mainPageReducer,
    services: ServicesReducer,
    fees: FeeReducer,
});

let store = configureStore({

    reducer: reducers,
    
});


export default store;