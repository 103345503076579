

import { useRef, useState } from "react";
import "./Refundhelp.css";
import { emailValidator } from "../Helpers/Earth";
import { useDispatch, useSelector } from "react-redux";
import { resetRefundCancellationError, setRefundCancellationError } from "../Redux/Action/ErrorAction";

function Refundhelp(){

  let RefundCancellationEmailRef = useRef(null);
  let RefundCancellationPhoneRef = useRef(null);
  let RefundCancellationProductCodeRef = useRef(null);
  let RefundCancellationRemarkRef = useRef(null);

  let refundTrimmedPhone = useRef({
    phone: null,
  });

  let refundDispatcher = useDispatch();
  let refundErrorSelector = useSelector(state => state.errors.refund);

  let [refundCancellationErrorShower,SetRefundCancellationErrorShower] = useState("kalniyojanRefundCancellationErrorShower");

  let refundCancellationHandler = {

    handleRefundCancellation: async function(e){

      e.preventDefault();

      await this.validateRCFormFields({field:"email",data:RefundCancellationEmailRef.current.value})
      .catch(error => {
        window.scrollTo(0,0);
        SetRefundCancellationErrorShower("kalniyojanRefundCancellationErrorShowerSelected");
        refundDispatcher(setRefundCancellationError("invalid email"));
        setTimeout(() => {
          SetRefundCancellationErrorShower("kalniyojanRefundCancellationErrorShower");
          refundDispatcher(resetRefundCancellationError());
        }, 8000);
      });

      await this.validateRCFormFields({field: "phone",data:RefundCancellationPhoneRef.current.value})
      .then(result =>{
        refundTrimmedPhone.current = {
          ...result,
        };
      }).catch(error => {
        window.scrollTo(0,0);
        SetRefundCancellationErrorShower("kalniyojanRefundCancellationErrorShowerSelected");
        refundDispatcher(setRefundCancellationError("invalid phone number"));
        setTimeout(() => {
          SetRefundCancellationErrorShower("kalniyojanRefundCancellationErrorShower");
          refundDispatcher(resetRefundCancellationError());
        }, 8000);
      });

      await this.validateRCFormFields({field: "productcode",data:RefundCancellationProductCodeRef.current.value})
      .catch(error => {
        window.scrollTo(0,0);
        SetRefundCancellationErrorShower("kalniyojanRefundCancellationErrorShowerSelected");
        refundDispatcher(setRefundCancellationError("invalid product code"));
        setTimeout(() => {
          SetRefundCancellationErrorShower("kalniyojanRefundCancellationErrorShower");
          refundDispatcher(resetRefundCancellationError());
        }, 8000);
      });

      await this.validateRCFormFields({field: "remark", data: RefundCancellationRemarkRef.current.value})
      .catch(error => {
        window.scrollTo(0,0);
        SetRefundCancellationErrorShower("kalniyojanRefundCancellationErrorShowerSelected");
        refundDispatcher(setRefundCancellationError("invalid remark, it should be less than 300 characters"));
        setTimeout(() => {
          SetRefundCancellationErrorShower("kalniyojanRefundCancellationErrorShower");
          refundDispatcher(resetRefundCancellationError());
        }, 8000);
      })
    },

    validateRCFormFields: async function(data_to_validate){
      
      switch(data_to_validate.field){

        case "email":
          return new Promise(function(resolve,reject){
            let is_email_valid = emailValidator(data_to_validate.data);
            if(is_email_valid){
              resolve(is_email_valid);
            }else{
              reject(is_email_valid);
            }
          });

        case "phone":
          return new Promise(function(resolve,reject){
            let number = refundCancellationHandler.getNumber(data_to_validate.data);
            if (!number.success) {
              reject(number);
            }
            if (number.insideIndia && number.clientPhoneNumber.toString().length < 10) {
              reject(number);
            }
            if (!number.insideIndia && number.clientPhoneNumber.toString().length < 10) {
              reject(number);
            }
            resolve(number);
          });

        case "productcode":
          return new Promise(function (resolve,reject){
            if(data_to_validate.data === ""){
              reject(false);
            }
            if(data_to_validate.data.length !== 32){
              reject(false);
            }
            reject(false);
          });

        default:
          return new Promise(function (resolve,reject){
            if(data_to_validate.data.length > 300){
              reject(false);
            }

            if(data_to_validate.data === ""){
              reject(false);
            }
          });
      }

    },

    getNumber: number => {

      let number_obj = {
        insideIndia: null,
        countryCode: null,
        clientPhoneNumber: null,
        success: false,
      };

      if (number === "") {
          return number_obj;
      }
      let positionOfDash = number.indexOf("-");
      let lastPositionOfDash = number.lastIndexOf("-");
      if (positionOfDash === -1) {
          return number_obj;
      }
      if (positionOfDash !== lastPositionOfDash) {
          return number_obj;
      }
      let CC = number.substring(0, positionOfDash);
      let parsedCC = parseInt(CC);
      if (isNaN(parsedCC) || CC.length > 4 || CC.length < 1 || parsedCC === 0) {
          return number_obj;
      }
      let actualNumber = number.substring(positionOfDash + 1, number.length);
      let parsedActualNumber = parseInt(actualNumber);
      if (isNaN(parsedActualNumber) || actualNumber.length > 10) {
          return number_obj;
      }

      number_obj.insideIndia = parsedCC !== 91 ? false : true;
      number_obj.countryCode = parsedCC;
      number_obj.clientPhoneNumber = parsedActualNumber;
      number_obj.success = true;
      return number_obj;
    }
  }

  return(
    <div className="kalniyojanRefundHelpContainer">
      <div className="kalniyojanRefundHelpContainerContent">

        <h2>Refund & Cancellation</h2>

        <div className={refundCancellationErrorShower}>
          {Array.from(refundErrorSelector).map((item,index) => 
            <div key={index}>{item}</div>
          )}
        </div>

        <div className="kalniyojanRefundCancellationNote">
          This form will help you to apply for refund/cancellation of product like books and panchang.
        </div>

        <form onSubmit={event => refundCancellationHandler.handleRefundCancellation(event)}>
          <div className="RCFEmailContainer">
            <label><span>*</span>Email</label>
            <input type='email' ref={RefundCancellationEmailRef} />
          </div>
          <div className="RCFPhoneContainer">
            <label><span>*</span>Phone</label>
            <input type='text' ref={RefundCancellationPhoneRef} />
          </div>
          <div className="RCFProductCodeContainer">
            <label><span>*</span>Product Code</label>
            <input type='text' ref={RefundCancellationProductCodeRef} />
          </div>
          <div className="RCFRemarkContainer">
            <label><span>*</span>Remark</label>
            <input type='text' ref={RefundCancellationRemarkRef} />
          </div>
          <button type='submit'>Submit</button>
        </form>
      </div>
    </div>
  );
}


export default Refundhelp;