import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import './App.css';

import Navbar from './Componants/Navbar';
import Footer from './Componants/Footer';


import Services from './Screens/Services';
import About from './Screens/About';
import Home from './Screens/Home';
import Contact from './Screens/Contact';
import Fade from "./Screens/Fade";
import Sidebar from './Screens/Sidebar';
import PageNotFound from './Screens/PageNotFound';
import Article from './Screens/Article';
import Admin from './Screens/Admin/Admin';
import ArticleUpdate from './Screens/ArticleUpdate';
import Loginpage from './Screens/Loginpage';
import ServicesSupport from './Screens/ArticleDownloadSupport';
import Advice from './Screens/Advice';
import ServicesSuccessfulCreated from './Screens/ServiceSuccessfulCreated';
import MatchMaking from './Screens/MatchMaking';
import Janmapatrika from './Screens/Janmapatrika';
import Janmapatrikatypes from './Screens/Janmapatrikatypes';
import Muhurta from './Screens/Muhurta';
import { Privacy } from './Screens/Privacy';
import { Help } from './Screens/Help';
import Terms from './Screens/Terms';
import Refundpolicy from './Screens/Refund';
import { FeedBack } from './Screens/Feedback';
import Refundhelp from './Screens/Refundhelp';
import Vastu from './Screens/Vastu';
import Donate from './Screens/Donate';

function App() {

  let [fade,setFade] = useState(false);

  return(
    <BrowserRouter>
      <Navbar click={() => setFade(true)}/>
      <Sidebar show={fade} click={() => setFade(false)}/>
      {/* <Fade show={fade} click={() => setFade(false)}/> */}
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/article' element={<Article/>}></Route>
        <Route path='/services' element={<Services />}></Route>
        <Route path='/services/advice' element={<Advice />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/kal/admin/dashboard' element={<Admin/>}></Route>
        <Route path='/kal/admin/article/update/:key' element={<ArticleUpdate/>}></Route>
        <Route path='/login' element={<Loginpage/>}></Route>
        <Route path='/servicessupport' element={<ServicesSupport/>}></Route>
        <Route path='/servicessuccess' element={<ServicesSuccessfulCreated/>}></Route>
        <Route path='/services/matchmaking' element={<MatchMaking />}></Route>
        <Route path='/services/janmapatrika' element={<Janmapatrika />}></Route>
        <Route path='/janmapatrika/types' element={<Janmapatrikatypes />}></Route>
        <Route path='/services/muhurta' element={<Muhurta />}></Route>
        <Route path='/services/vastu' element={<Vastu />}></Route>
        <Route path='/privacypolicy' element={<Privacy />}></Route>
        <Route path='/refundpolicy' element={<Refundpolicy />}></Route>
        <Route path='/terms' element={<Terms />}></Route>
        <Route path='/help' element={<Help />}></Route>
        <Route path='/help/feedback' element={<FeedBack />}></Route>
        <Route path='/help/refundcancellation' element={<Refundhelp />}></Route>
        <Route path='/donate' element={<Donate />}></Route>
        <Route path='*' element={<PageNotFound/>}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App;
