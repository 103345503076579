
import { useEffect, useState } from "react";
import "./Adminfeedback.css";
import axios from "axios";
import kapi from "../Redux/kalniyojanapi";
import { useDispatch, useSelector } from "react-redux";
import ServicesAction from "../Redux/Action/ServicesAction";
import { resetAFeedbackError, setAFeedbackError } from "../Redux/Action/ErrorAction";

function AdminFeedback(props){

  let [adminFeedbackErrorShower, SetAdminFeedbackErrorShower] = useState("adminFeedbackErrorShower");

  let adminFeedbackSelector = null;

  let adminFeedbackDispatcher = useDispatch();

  let adminFeedbackErrorSelector = useSelector(state => state.errors.aFeedback);

  let adminFeedbackTabCls = "adminDashboardFeedbackTabShowerSelected";

  if(!props.show){
    adminFeedbackTabCls = "adminDashboardFeedbackTabShower";
  }


  function clearAdminFeedbackCredentials(){
    localStorage.removeItem("kapt");
    window.location.href = "/login";
  }

  async function getFeedbacks(){

    let isLoggedIn = localStorage.getItem("kapt");

    if(isLoggedIn === null){
      window.location.href = "/login";
    }

    let feedbacks = null; 
    
    try{
      feedbacks = await axios({
        url: kapi.adminfeedbacks,
        method: kapi.get,
        headers: {
          Authorization: `Bearer ${isLoggedIn}r`
        },
        withCredentials: true,
      });

      if(feedbacks.status !== 200){
        throw new Error({message: "failed to get feedbacks"});
      }else{
        adminFeedbackDispatcher(ServicesAction.setFeedback(feedbacks.data));
      }

    }catch(error){
      if(error.response !== undefined){
        if(error.response.status === 401){
          clearAdminFeedbackCredentials();
        }else if(error.response.status === 404){
          SetAdminFeedbackErrorShower("adminFeedbackErrorShowerSelected");
          adminFeedbackDispatcher(setAFeedbackError(error.response.data.message));
          setTimeout(() => {
            SetAdminFeedbackErrorShower("adminFeedbackErrorShower");
            adminFeedbackDispatcher(resetAFeedbackError());
          }, 8000);
        }
      }else if(error instanceof Object){
        SetAdminFeedbackErrorShower("adminFeedbackErrorShowerSelected");
        adminFeedbackDispatcher(setAFeedbackError(error.message));
        setTimeout(() => {
          SetAdminFeedbackErrorShower("adminFeedbackErrorShower");
          adminFeedbackDispatcher(resetAFeedbackError());
        }, 8000);
      }else{
        SetAdminFeedbackErrorShower("adminFeedbackErrorShowerSelected");
        adminFeedbackDispatcher(setAFeedbackError("something is wrong may be server is down try later"));
        setTimeout(() => {
          SetAdminFeedbackErrorShower("adminFeedbackErrorShower");
          adminFeedbackDispatcher(resetAFeedbackError());
        }, 8000);
      }
    }
  }

  useEffect(() => {
    getFeedbacks();
  },[adminFeedbackSelector]);

  adminFeedbackSelector = useSelector(state => state.services.feedback);

  return(
    <div className={adminFeedbackTabCls}>
      <div className="adminDashboardFeedBackContent">
        <div className={adminFeedbackErrorShower}>
          {Array.from(adminFeedbackErrorSelector).map((item,index) => 
            <div key={index}>{item}</div>
          )}
        </div>
        {Array.from(adminFeedbackSelector).length > 0 ? 
          <table>
            <thead>
              <tr>
                <td>Email</td>
                <td>Feedback</td>
              </tr>
            </thead>
            <tbody>
              {Array.from(adminFeedbackSelector).map(item => 
                <tr key={item.key}>
                  <td>{item.email}</td>
                  <td>{item.message}</td>
                </tr>
              )}
            </tbody>
          </table> : <div className="adminFeedbackEmptyList">
            No feedback available
          </div>
        }
      </div>
    </div>
  );

}

export default AdminFeedback;