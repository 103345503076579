
import "./Privacy.css";

export function Privacy(){

    return(
        <div className="kalniyojanPrivacyContainer">
            <div className="kalniyojanPrivacyContainerContent">
                <h2>Kalniyojan Policy's</h2>
                <p>
                    Privacy of our customers is very important to us, and this is our Privacy Policy. This Privacy Policy may be updated from time to time. The Detailed Privacy Policy is Explained Below. 
                </p>

                <div className="kalniyojanPolicyExplaination">
                    <ol>
                        <li className="kalniyojanPolicyAcceptable">
                            <div>ACCEPTABLE USE:</div>
                            <div>
                                Your use is subject to our rules regarding acceptable conduct and practices.
                                You may not use the online services to threaten, abuse, harass, or invade the privacy of any third party. You will not use kalniyojan for chain letters, junk mail, spamming, or any use of distribution lists to any users of kalniyojan without prior permission in writing

                                Content Posted by You Must be Yours. You shall not upload, post, transmit or otherwise distribute or facilitate the distribution of content that is fraudulent or infringes the rights of kalniyojan, including without limitation, patent, trademark, copyright, right of publicity or other proprietary rights.
                                Your Materials Must be Lawful and Appropriate. You shall not upload, post or otherwise distribute or facilitate the distribution of any unlawful, threatening, abusive, libellous, defamatory, vulgar, profane, indecent or otherwise inappropriate information of any kind, including without limitation, any images or other material of obscene nature.
                                Dont Damage Our Site, Online Services or Servers. You shall not upload, post or otherwise distribute or facilitate the distribution of a software virus or any other computer code that is designed or intended to disrupt, damage, or limit the functioning of the Site, any other online services, or to obtain unauthorized access to kalniyojan
                                You shall not use kalniyojan, its Content or any information contained therein for any unlawful purpose, and you may not encourage conduct that would constitute a criminal offence or give rise to civil liability. You shall not use any services in connection with any site or other use that contains or is associated with information or content prohibited by kalniyojan
                                No Unauthorized Access. You shall not attempt to gain unauthorized access to other computer systems or networks connected to the online services or use the online services, Content or any information contained therein for any unlawful purpose. kalniyojan, in its sole and absolute discretion, shall determine whether any content you transmit or receive or your use of the kalniyojan violates this provision.
                                Your use of the Services is subject to all applicable local, state, national and international laws and regulations. To comply with all Indian laws regarding the transmission of technical data exported from India through our Service
                                To comply with all applicable regulations, policies and procedures of networks connected to the kalniyojan website and To comply with all applicable domestic and international laws, statutes, ordinances and regulations regarding your use of the Services.
                                kalniyojan makes use of the Internet to send and receive certain messages. Therefore, your conduct is subject to Internet regulations, policies, and procedures. Attempts to gain unauthorized access to kalniyojan systems/ servers are strictly prohibited. You shall not interfere.
                            </div>
                        </li>
                        <li className="kalniyojanPolicyUserContent">
                            <div>USER CONTENT:</div>
                            <div>
                                User Content provided by you will not be returned and cost paid will not be refunded. You acknowledge and agree that your User Content is being sent voluntarily by you and that no confidential relationship is intended or created between kalniyojan, and/or any other person or entity, on the one hand, and you, on the other hand, by your submission of the User Content. You also agree that kalniyojan does not intend and will not pay you for any User Content.
                            </div>
                        </li>
                        <li className="kalniyojanPolicyWarranty">
                            <div>WARRANTY</div>
                            <div>
                                You understand and agree that all the Services by kalniyojan are provided on the Users demand. Besides, kalniyojan assumes no responsibility for lack thereof, deletions of any Services. Application and understanding of all the predictions and explanations are on the User’s discretion, and the User shall be solely responsible for any eventuality if any arises.
                                kalniyojan, on behalf of itself ,branches disclaims all warranties of any kind, express or implied, including without limitation any warranty of merchantability, fitness for a particular purpose, title, or non-infringement and it makes no warranty or representation regarding the results that may be obtained from the use of content or services, the accuracy or reliability of any content obtained through the Services, any goods or services purchased or obtained through kalniyojan, or any transactions entered into through kalniyojan’s site, and makes no warranty that the services will, at all times, meet your exact requirements, be 100% uninterrupted, 100% timely, secure or error-free. No advice or information, whether oral or written, obtained by you from kalniyojan or through any other services provided by kalniyojan shall create any such warranty.
                            </div>
                        </li>
                        <li className="kalniyojanPolicyLiability">
                            <div>LIABILITY:</div>
                            <div>
                                In no event will kalniyojan will not be liable for any indirect, incidental, special, consequential or punitive damages of any kind, including but not limited to damages for loss of profits and the cost of procurement of substitute goods and services (collectively, indirect damages) arising out of or in connection with our website, the services, our Privacy Policy, or these Terms & Conditions (however arising, including negligence), even if kalniyojan had been advised of the possibility of such damages without limiting the foregoing, kalniyojan will not be liable for indirect damages arising out of or in connection with: THE USE OF OR THE INABILITY TO USE THE SERVICES INCLUDING ANY SERVICES PURCHASED OR OBTAINED THROUGH kalniyojan.com SITE, OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH kalniyojan.com SERVICES; OR LOSS OF, UNAUTHORIZED ACCESS TO, OR ALTERATION OF USERS TRANSMISSIONS OR DATA
                            </div>
                        </li>
                        <li className="kalniyojanPolicyThirdParty">
                            <div>THIRD-PARTY CONTENT:</div>
                            <div>
                                For your convenience, the online services may provide links to websites of other persons or entities (“Third-Party Websites”). However, third-party websites are not controlled by kalniyojan.com. Accordingly, kalniyojan.com makes no warranties or conditions regarding such third-party websites and has no responsibility for the contents of such third-party websites, and will not be liable for any loss or damage caused by your use of or reliance on such third-party websites. Your use of third-party websites is at your own risk. The inclusion on the site or another service of a link to a third-party website does not imply an endorsement by kalniyojan.com. When you access any of these third-party sites, please understand that if you visit third-party websites then your rights and obligations while accessing and using those sites will be governed by the agreements and policies relating to the use of those sites.
                            </div>
                        </li>
                        <li className="kalniyojanPolicyIndemnification">
                            <div>INDEMNIFICATION:</div>
                            <div>
                                You agree to indemnify, defend and hold harmless kalniyojan.com and its parent, subsidiaries, affiliates, officers, directors, employees, suppliers, consultants and agents from any and all third party claims, liability, damages and/or costs (including, but not limited to, legal fees and costs) arising out of or accruing from Your use of the Services. Your violation of the Privacy Policy or these Terms & Conditions, or Your violation of any third party’s rights, including without limitation, infringement by You or any other user or any intellectual property or other right of any person or entity, defamation of any person or violation of their rights of publicity or privacy these Terms & Conditions will be to the benefit of kalniyojan.com’s successors, assigns, and licensees.
                            </div>
                        </li>
                        <li className="kalniyojanPolicyReservationOfRights">
                            <div>RESERVATION OF RIGHTS:</div>
                            <div>
                                kalniyojan.com reserves the right to modify or discontinue, temporarily or permanently, all or any part of this Site and/or any software, facilities and services on this Site, with or without notice, and/or to establish general guidelines and limitations on their use.
                            </div>
                        </li>
                        <li className="kalniyojanPolicyArbitration">
                            <div>ARBITRATION; INJUNCTIVE RELIEF:</div>
                            <div>
                                Any dispute, controversy, or claim arising out of, relating to, or in connection with this Agreement shall be settled by binding arbitration in accordance with the commercial arbitration rules in the Indian Law. The arbitration shall be conducted by a single arbitrator, selected in accordance with the rules in the Indian Law, and judgment on the arbitration award may be entered in any court having jurisdiction thereof. Notwithstanding the foregoing, either party has the right to seek any interim or preliminary relief from a court of competent jurisdiction in Solapur only in order to protect the rights of such party pending the completion of any arbitration hereunder, and both parties agree to submit to the exclusive jurisdiction of the courts of in Solapur, Maharashtra only for any such proceeding.
                            </div>
                        </li>
                        <li className="kalniyojanPolicyGeneralProvision">
                            <div>GENERAL PROVISIONS:</div>
                            <div>
                                These Terms & Conditions will be governed by and construed in accordance with the laws of India without giving effect to any choice of law principles that would require the application of the laws of a different State. If for any reason a court of competent jurisdiction finds any provision or portion of these Terms & Conditions or Privacy Policy & Disclaimer to be unenforceable or invalid, such provision shall be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law, and the remainder of the Terms & Conditions or Privacy Policy & Disclaimer, as applicable, will continue in full force and effect. Headings are for reference purposes only and in no way define, limit, construe, or describe the scope or extent of such section. Any waiver of any provision of the Terms & Conditions will be effective as it is considered to be in writing by kalniyojan.com. These Terms & Conditions constitute the Entire Agreement between the parties with respect to the subject matter hereof and supersede and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matters.
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}