import './Home.css';
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import setMainPage from "../Redux/Action/MainPageAction";
import { mainPageError } from '../Redux/Action/ErrorAction';
import defaultImage from "../images/image not found.jpg";
import kapi from '../Redux/kalniyojanapi';
import front from "../images/front.jpg";
import frontBack from "../images/frontBack.jpg";
import backBack from "../images/backBack.jpg";
import back from '../images/back.jpg';

function Home(props){

	const mpDispatcher = useDispatch();
	let mpSelector;
	let mpErrorSelector;

	useEffect(() => {
		axios({
			method: kapi.get,
			url: kapi.homepageimgapi,
			withCredentials: true,
		}).then(result => {
			if(result.status !== 200){
				throw new Error("can not load image, server error try later");
			}
			mpDispatcher(setMainPage(result));
		}).catch(error => {
			mpDispatcher(mainPageError("can not load image, server error try later"));
		});
	},[mpSelector,mpErrorSelector]);

	mpSelector = useSelector(state => state.main);
	mpErrorSelector = useSelector(state => state.errors.mainPageError);

	return(
		<div className="homeContainer">
			<div className='homeContainerContent'>
				<div className="homeImage">
					{mpSelector.front ? 
					<img src={mpSelector.front} className="frontImage" alt="panchangFrontPic" /> : <div><img src={front} className='homePageDefaultImage' alt='defaultImage'></img></div>}
				</div>
				<div className="homeImage">
					{mpSelector.frontBack ?
					<img src={mpSelector.frontBack} className="frontBackImage" alt="panchangFrontBackPic" /> : <div><img src={frontBack} className='homePageDefaultImage' alt='defaultImage'></img></div> }
				</div>
				<div className="homeImage">
					{mpSelector.backBack ? 
					<img src={mpSelector.backBack} className="backBackImage" alt="panchangBackBackPic" /> : <div><img src={backBack} className='homePageDefaultImage' alt='defaultImage'></img></div>}
				</div>
				<div className="homeImage">
					{mpSelector.back ? 
						<img src={mpSelector.back} className='backImage' alt="panchangBackPic" /> : <div><img src={back} className='homePageDefaultImage' alt='defaultImage' /></div>}
				</div>
			</div>
		</div>
	);
}

export default Home;