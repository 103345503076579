import ErrorTypes from "../Type/ErrorTypes";

let errorInitialState = {
    articleListError:[],
    deleteArticleError:[],
    newArticleError:[],
    mainPageImgError:[],
    updateArticleError:[],
    advice:[],
    matchMaking:[],
    aMatchMaking:[],
    payment:[],
    aAdvice:[],
    aPatrika:[],
    loginError:[],
    logoutError:[],
    mainPageError:0,
    janmaPatrika:[],
    muhurta:[],
    aMuhurta:[],
    feedback: [],
    refund: [],
    aFeedback: [],
    vastu: [],
};


function errorReducer(state = errorInitialState, action){


    switch(action.type){
        case "addArticleListError":
            if(!state.articleListError.includes(action.payload)){
                return{
                    ...state,
                    articleListError:[
                        ...state.articleListError,
                        action.payload,
                    ]
                }
            }
            return{
                ...state,
                articleListError:[
                    ...state.articleListError,
                ]
            }

        case "newArticleAddError":
            if(!state.newArticleError.includes(action.payload)){
                return{
                    ...state,
                    newArticleError:[
                        ...state.newArticleError,
                        action.payload,
                    ],
                }
            }
            return{
                ...state,
                newArticleError: [
                    ...state.newArticleError,
                ],
            };

        case "deleteArticle":
            if(!state.deleteArticleError.includes(action.payload)){
                return{
                    ...state,
                    deleteArticleError:[
                        ...state.deleteArticleError,
                        action.payload,
                    ],
                }
            }
            return{
                ...state,
                deleteArticleError:[
                    ...state.deleteArticleError,
                ],
            };

        case "mainPageImgvalidError":
            return {
                ...state,
                mainPageImgError:[
                    ...state.mainPageImgError,
                    action.payload,
                ],
            }

        case "resetArticleListError":
            return{
                ...state,
                articleListError: action.payload,
            };
        
        case "resetNewArticleError":
            return{
                ...state,
                newArticleError: action.payload,
            };

        case "resetDeleteArticleError":
                return{
                    ...state,
                    deleteArticleError:action.payload,
                };

        case "updateArticleError":
            if(!state.updateArticleError.includes(action.payload)){
                return{
                    ...state,
                    updateArticleError:[
                        ...state.updateArticleError,
                        action.payload,
                    ],
                }
            }
            return {
                ...state,
                updateArticleError:[
                    ...state.updateArticleError,
                ],
            };

        case ErrorTypes.adviceError:
            if(!state.advice.includes(action.payload)){
                return{
                    ...state,
                    advice:[
                        ...state.advice,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                advice:[
                    ...state.advice,
                ],
            };

        case ErrorTypes.resetAdviceError:
            return{
                ...state,
                advice: action.payload,
            };

        case ErrorTypes.aAdviceError:
            if(!state.aAdvice.includes(action.payload)){
                return{
                    ...state,
                    aAdvice:[
                        ...state.aAdvice,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                aAdvice:[
                    ...state.aAdvice,
                ],
            };

        case ErrorTypes.resetAAdviceError:
            return{
                ...state,
                aAdvice: action.payload,
            };

        case "resetUpdateArticleError":
            return{
                ...state,
                updateArticleError: action.payload,
            };

        case "resetMainPageError":
            return{
                ...state,
                mainPageImgError: action.payload,
            };

        case "loginError":
            if(!state.loginError.includes(action.payload)){
                return{
                    ...state,
                    loginError:[
                        ...state.loginError,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                loginError:[
                    ...state.loginError,
                ],
            };

        case ErrorTypes.logoutError:
            if(!state.logoutError.includes(action.payload)){
                return{
                    ...state,
                    logoutError:[
                        ...state.logoutError,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                logoutError:[
                    ...state.loginError,
                ],
            };

        case ErrorTypes.resetLogoutError:
            return{
                ...state,
                logoutError: action.payload,
            };

        case "resetLoginError":
            return{
                ...state,
                loginError: action.payload,
            };

        case "resetAll":
            return{
                ...state,
                newArticleError: action.payload,
                mainPageImgError: action.payload,
            };

        case ErrorTypes.mainPageError:
            return{
                ...state,
                mainPageError: action.payload,
            };

        case ErrorTypes.advicePaymentError:
            if(!state.payment.includes(action.payload)){
                return{
                    ...state,
                    payment:[
                        ...state.payment,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                payment:[
                    ...state.payment,
                ],
            };

        case ErrorTypes.resetAdvicePaymentError:
            return{
                ...state,
                payment:action.payload,
            };

        case ErrorTypes.matchMakingError:
            if(!state.matchMaking.includes(action.payload)){
                return{
                    ...state,
                    matchMaking:[
                        ...state.matchMaking,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                matchMaking:[
                    ...state.matchMaking,
                ],
            };
        
        case ErrorTypes.resetMatchMakingError:
            return{
                ...state,
                matchMaking:action.payload,
            };

        case ErrorTypes.matchMakingPaymentError:
            if(!state.payment.includes(action.payload)){
                return{
                    ...state,
                    payment:[
                        ...state.payment,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                payment:[
                    ...state.payment,
                ],
            };

        case ErrorTypes.resetMatchMakingPaymentError:
            return{
                ...state,
                payment:action.payload,
            };

        case ErrorTypes.aMatchMakingError:
            if(!state.aMatchMaking.includes(action.payload)){
                return{
                    ...state,
                    aMatchMaking:[
                        ...state.aMatchMaking,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                aMatchMaking:[
                    ...state.aMatchMaking,
                ],
            };

        case ErrorTypes.resetAMatchMakingError:
            return{
                ...state,
                aMatchMaking:action.payload,
            };

        case ErrorTypes.janmaPatrikaError:
            if(!state.janmaPatrika.includes(action.payload)){
                return{
                    ...state,
                    janmaPatrika:[
                        ...state.janmaPatrika,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                janmaPatrika:[
                    ...state.janmaPatrika,
                ],
            };

        case ErrorTypes.resetJanmaPatrikaError:
            return{
                ...state,
                janmaPatrika: action.payload,
            };

        case ErrorTypes.setJanmaPatrikaPaymentError:
            if(!state.payment.includes(action.payload)){
                return{
                    ...state,
                    payment: [
                        ...state.payment,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                payment: [
                    ...state.payment,
                ],
            };

        case ErrorTypes.resetJanmaPatrikaPaymentError:
            return{
                ...state,
                payment: action.payload,
            };

        case ErrorTypes.setMuhurtaError:
            if(!state.muhurta.includes(action.payload)){
                return{
                    ...state,
                    muhurta:[
                        ...state.muhurta,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                muhurta:[
                    ...state.muhurta,
                ],
            };

        case ErrorTypes.resetMuhurtaError:
            return{
                ...state,
                muhurta:action.payload,
            };

        case ErrorTypes.setMuhurtaPaymentError:
            if(!state.payment.includes(action.payload)){
                return{
                    ...state,
                    payment:[
                        ...state.payment,
                        action.payload,
                    ],
                };
            }
            return {
                ...state,
                payment:[
                    ...state.payment,
                ],
            };

        case ErrorTypes.resetMuhurtaPaymentError:
            return{
                ...state,
                payment:action.payload,
            };

        case ErrorTypes.aPatrikaError:
            if(!state.aPatrika.includes(action.payload)){
                return{
                    ...state,
                    aPatrika:[
                        ...state.aPatrika,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                aPatrika:[
                    ...state.aPatrika,
                ],
            };

        case ErrorTypes.resetAPatrikaError:
            return{
                ...state,
                aPatrika:action.payload,
            };

        case ErrorTypes.setAmuhurtaError:
            if(!state.aMuhurta.includes(action.payload)){
                return{
                    ...state,
                    aMuhurta:[
                        ...state.aMuhurta,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                aMuhurta:[
                    ...state.aMuhurta,
                ],
            };

        case ErrorTypes.resetAmuhurtaError:
            return{
                ...state,
                aMuhurta: action.payload,
            };

        case ErrorTypes.setFeedbackError:
            if(!state.feedback.includes(action.payload)){
                return{
                    ...state,
                    feedback: [
                        ...state.feedback,
                        action.payload,
                    ],
                };
            }
            return {
                ...state,
                feedback: [
                    ...state.feedback,
                ],
            };

        case ErrorTypes.resetFeedbackError:
            return{
                ...state,
                feedback: action.payload,
            };

        case ErrorTypes.setRefundCancellationError:
            if(!state.refund.includes(action.payload)){
                return{
                    ...state,
                    refund: [
                        ...state.refund,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                refund: [
                    ...state.refund,
                ],
            };

        case ErrorTypes.resetRefundCancellationError:
            return{
                ...state,
                refund: action.payload,
            };

        case ErrorTypes.setAFeedbackError:
            if(!state.aFeedback.includes(action.payload)){
                return{
                    ...state,
                    aFeedback: [
                        ...state.aFeedback,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                aFeedback: [
                    ...state.aFeedback,
                ],
            };

        case ErrorTypes.resetAFeedbackError:
            return{
                ...state,
                aFeedback: action.payload,
            };

        case ErrorTypes.setVastuPaymentError:
            if(!state.payment.includes(action.payload)){
                return{
                    ...state,
                    payment:[
                        ...state.payment,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                payment:[
                    ...state.payment,
                ],
            };

        case ErrorTypes.resetVastuPaymentError:
            return{
                ...state,
                payment: action.payload,
            }

        case ErrorTypes.setVastuError:
            if(!state.vastu.includes(action.payload)){
                return{
                    ...state,
                    vastu:[
                        ...state.vastu,
                        action.payload,
                    ],
                };
            }
            return{
                ...state,
                vastu:[
                    ...state.vastu,
                ],
            };

        case ErrorTypes.resetVastuError:
            return{
                ...state,
                vastu: action.payload,
            }

        default:
            return state;
    }
}

export default errorReducer;