
import { useEffect, useState } from "react";
import "./AdminMuhurta.css";
import axios from "axios";
import kapi from "../Redux/kalniyojanapi";
import { useDispatch, useSelector } from "react-redux";
import { resetAmuhurtaError, setAmuhurtaError } from "../Redux/Action/ErrorAction";
import ServicesAction from "../Redux/Action/ServicesAction";
import adminMuhurtaSolvedImg from "../images/successtick.svg";


export function AdminMuhurta(props){

    let [adminMuhurtaError,SetAdminMuhurtaError] = useState("adminMuhurtaErrorShower");

    let adminMuhurtaClientsSelector = null;

    let adminMuhurtaDispatcher = useDispatch();
    let adminMuhurtaErrorSelector = useSelector(state => state.errors.aMuhurta);

    let adminMuhurtaTabCls = "adminDashboardMuhurtaTabShowerSelected";

    if(!props.show){
        adminMuhurtaTabCls = "adminDashboardMuhurtaTabShower";
    }


    function clearAdminMuhurtaUserCredentials(){
        let adminmuhurtakapt = localStorage.getItem("kapt");
        if(adminmuhurtakapt !== null){
            localStorage.removeItem("kapt");
            window.location.href("/login");
        }
    }

    async function handleAdminMuhurtaActionBtn(key){

        let adminMuhurtaActionResponse = null;

        let adminMuhurtaKapt = localStorage.getItem("kapt");
        if(adminMuhurtaKapt === null){
            window.location.href = "/login";
        }

        try{
            adminMuhurtaActionResponse = await axios({
                method: kapi.post,
                url: kapi.adminmuhurtacompleteapi,
                data: {
                    "key":key,
                },
                headers:{
                    "Content-Type":"application/json",
                    "Authorization": `Bearer ${adminMuhurtaKapt}`,
                },
                withCredentials: true,
            });

            if(adminMuhurtaActionResponse.status !== 200){
                throw new Error("failed to perform this action!!!");
            }else{
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        }catch(error){
            if(error.response !== undefined){
                let muhurtaErrorResponse = error.response;
                if(muhurtaErrorResponse.status === 401){
                    clearAdminMuhurtaUserCredentials();
                }else if(muhurtaErrorResponse.status === 406){
                    SetAdminMuhurtaError("adminMuhurtaErrorShowerSelected");
                    adminMuhurtaDispatcher(setAmuhurtaError(muhurtaErrorResponse.data.message));
                    setTimeout(() => {
                        SetAdminMuhurtaError("adminMuhurtaErrorShower");
                        adminMuhurtaDispatcher(resetAmuhurtaError());
                    }, 8000);
                }
            }else{
                SetAdminMuhurtaError("adminMuhurtaErrorShowerSelected");
                adminMuhurtaDispatcher(setAmuhurtaError("something is wrong may be server is down"));
                setTimeout(() => {
                    SetAdminMuhurtaError("adminMuhurtaErrorShower");
                    adminMuhurtaDispatcher(resetAmuhurtaError());
                }, 8000);
            }
        }
    }

    async function getAdminMuhurtaClients(){

        let adminMuhurtaClientKapt = localStorage.getItem("kapt");

        if(adminMuhurtaClientKapt === null){
            window.location.href = "/login";
        }

        try{
            let adminMuhurtaClientsResponse = await axios({
                method: kapi.get,
                url: kapi.adminmuhurtaclients,
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${adminMuhurtaClientKapt}`,
                },
                withCredentials: true,
            });
            if(adminMuhurtaClientsResponse.status !== 200){
                throw new Error("error fetching muhurta clients");
            }else{
                adminMuhurtaDispatcher(ServicesAction.setMuhurta(adminMuhurtaClientsResponse.data));
            }
        }catch(error){
            if(error.response !== undefined){
                let muhurtaErrorResponse = error.response;
                if(muhurtaErrorResponse.status === 401){
                    clearAdminMuhurtaUserCredentials();
                }else if(muhurtaErrorResponse.status === 404){
                    SetAdminMuhurtaError("adminMuhurtaErrorShowerSelected");
                    adminMuhurtaDispatcher(setAmuhurtaError(muhurtaErrorResponse.data.message));
                    setTimeout(() => {
                        SetAdminMuhurtaError("adminMuhurtaErrorShower");
                        adminMuhurtaDispatcher(resetAmuhurtaError());
                    }, 8000);
                }
            }else{
                SetAdminMuhurtaError("adminMuhurtaErrorShowerSelected");
                adminMuhurtaDispatcher(setAmuhurtaError("something is wrong may be server is down"));
                setTimeout(() => {
                    SetAdminMuhurtaError("adminMuhurtaErrorShower");
                    adminMuhurtaDispatcher(resetAmuhurtaError());
                }, 8000);
            }
        }
    }

    useEffect(() => {
        getAdminMuhurtaClients();
    },[adminMuhurtaClientsSelector]);

    adminMuhurtaClientsSelector = useSelector(state => state.services.muhurta);

    return(
        <div className={adminMuhurtaTabCls}>
            <div className="adminDashboardMuhurtaTabContainerContent">

                <div className={adminMuhurtaError}>
                    {Array.from(adminMuhurtaErrorSelector).map((item,index) => 
                        <div key={index}>{item}</div>
                    )}
                </div>

                {Array.from(adminMuhurtaClientsSelector).length > 0 ?

                    <table className="adminMuhurtaTable">
                        <thead>
                            <tr>
                                <td>User Detail</td>
                                <td>User Data</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        <tbody id="adminMuhurtaTableBody">
                            {Array.from(adminMuhurtaClientsSelector).map((item) => 
                                <tr key={item.muhurtaKey}>
                                    <td>
                                        <div>client name</div>
                                        <div>client email</div>
                                        <div>client phone</div>
                                        <div>client phonecode</div>
                                        <div>muhurta type</div>
                                        <div>name</div>
                                        <div>date</div>
                                        <div>time</div>
                                        <div>place</div>
                                        <div>country</div>
                                        <div>female name</div>
                                        <div>male name</div>
                                        <div>female date</div>
                                        <div>male date</div>
                                        <div>female time</div>
                                        <div>male time</div>
                                        <div>female place</div>
                                        <div>male place</div>
                                        <div>female country</div>
                                        <div>male country</div>
                                    </td>
                                    <td>
                                        <div>{item.clientName}</div>
                                        <div>{item.clientEmail}</div>
                                        <div>{item.clientPhone}</div>
                                        <div>{item.clientPhoneCode}</div>
                                        <div>{item.muhurtaFor}</div>
                                        {item.name === undefined ? <div>---</div> : <div>{item.name}</div>}
                                        {item.date === undefined ? <div>---</div> : <div>{item.date}</div>}
                                        {item.time === undefined ? <div>---</div> : <div>{item.time}</div>}
                                        {item.place === undefined ? <div>---</div> : <div>{item.place}</div>}
                                        {item.country === undefined ? <div>---</div> : <div>{item.country}</div>}
                                        {item.femaleName === undefined ? <div>---</div> : <div>{item.femaleName}</div>}
                                        {item.maleName === undefined ? <div>---</div> : <div>{item.maleName}</div>}
                                        {item.femaleDate === undefined ? <div>---</div> : <div>{item.femaleDate}</div>}
                                        {item.maleDate === undefined ? <div>---</div> : <div>{item.maleDate}</div>}
                                        {item.femaleTime === undefined ? <div>---</div> : <div>{item.femaleTime}</div>}
                                        {item.maleTime === undefined ? <div>---</div> : <div>{item.maleTime}</div>}
                                        {item.femalePlace === undefined ? <div>---</div> : <div>{item.femalePlace}</div>}
                                        {item.malePlace === undefined ? <div>---</div> : <div>{item.malePlace}</div>}
                                        {item.femaleCountry === undefined ? <div>---</div> : <div>{item.femaleCountry}</div>}
                                        {item.maleCountry === undefined ? <div>---</div> : <div>{item.maleCountry}</div>}
                                    </td>
                                    <td>
                                        <button title="muhurta delivered" onClick={() => handleAdminMuhurtaActionBtn(item.muhurtaKey)}>
                                            <img src={adminMuhurtaSolvedImg} className="adminClientPatrikaDelivered" alt="patrikaDeliveredBtn" />
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table> :
                    <div className="adminEmptyMuhurtaList">
                        No Muhura Client Available!!!
                    </div>
                }
            </div>
        </div>
    );
}