
const kapi = {
	get:"GET",
	post:"POST",
	delete:"DELETE",
	put: "PUT",
	adviceServiceName: "advice",
	matchMakingServiceName: "matchmaking",
	janmaPatrikaServiceName: "janmapatrika",
	muhurtaServiceName: "muhurta",
	vastuServiceName: "vastu",

	admindeletearticleapi:`https://api.kalniyojan.com/api/v1/admin/delete/article`,
	adminupdatearticlekeyapi:'https://api.kalniyojan.com/api/v1/admin/article/key',
	adminmatchmakinglistapi:"https://api.kalniyojan.com/api/v1/admin/services/matchmakingclients",
	adminmatchmakingcompleteapi:"https://api.kalniyojan.com/api/v1/admin/services/matchmaking/complete",
	adminadvicecompleteapi:"https://api.kalniyojan.com/api/v1/admin/services/advice/complete",
	adminaddarticleapi:'https://api.kalniyojan.com/api/v1/admin/add/article',
	adminadvicelistapi:"https://api.kalniyojan.com/api/v1/admin/services/adviceclients",
	adminjanmapatrikalist: "https://api.kalniyojan.com/api/v1/admin/services/patrikaclients",
	adminpatrikacompleteapi:"https://api.kalniyojan.com/api/v1/admin/services/janmapatrika/complete",
	adminmuhurtaclients: "https://api.kalniyojan.com/api/v1/admin/services/muhurtaclients",
	adminmuhurtacompleteapi: "https://api.kalniyojan.com/api/v1/admin/services/muhurta/complete",
	adminfeedbacks: "https://api.kalniyojan.com/api/v1/admin/services/feedbacks",

	orderidapi:"https://api.kalniyojan.com/api/v1/payment/order",
	borderidapi:"https://api.kalniyojan.com/api/v1/payment/border",
	orderconfig:"https://api.kalniyojan.com/api/v1/payment/payconfig",

	addadviceapi:"https://api.kalniyojan.com/services/advice",
	advicefeesapi:"https://api.kalniyojan.com/services/advice/fees",

	matchmakingfeesapi:"https://api.kalniyojan.com/services/matchmaking/fees",
	addmatchmakingapi:"https://api.kalniyojan.com/services/matchmaking",

	addjanmapatrikaapi: "https://api.kalniyojan.com/services/janmapatrika",
	janmapatrikafeeapi: "https://api.kalniyojan.com/services/janmapatrika/fees",

	muhurtafeeapi: "https://api.kalniyojan.com/services/muhurta/fees",
	oneforallmuhurtasubmitapi: "https://api.kalniyojan.com/services/muhurta/oneforall",
	garbhadhamuhurtasubmitapi: "https://api.kalniyojan.com/services/muhurta/garbhadhan",
	normalmuhurtasubmitapi: "https://api.kalniyojan.com/services/muhurta/normal",

	vastufeesapi: "https://api.kalniyojan.com/services/vastu/fees",
	addvastuapi:"https://api.kalniyojan.com/services/vastu",

	loginapi:"https://api.kalniyojan.com/api/v1/login",
	logoutapi:"https://api.kalniyojan.com/api/v1/logout",
	homepageimgapi:"https://api.kalniyojan.com/article/mainPageImgs",
	footervisitorapi:"https://api.kalniyojan.com/article/visitors",
	articleapi:"https://api.kalniyojan.com/article/articles",
	shortupdatearticleapi:'https://api.kalniyojan.com/api/v1/admin/shortUpdate/article',
	wholearticleupdateapi:'https://api.kalniyojan.com/api/v1/admin/update/article',
	adminaddmainpageapi:'https://api.kalniyojan.com/api/v1/admin/add/mainpage',

	userfeedback:"https://api.kalniyojan.com/services/feedback",
}

export default kapi;