import articleType from "../Type/ArticleTypes";

function getAllArticle(articleData){

    let trimedData = [
        ...articleData.data,
    ]

    return {
        type: articleType.loadAllArticle,
        payload: trimedData,
    };
}

function resetAllArticle(){
    return{
        type:articleType.resetAllArticle,
        payload:[],
    };
}

function updateArticleData(articleData){

    return{
        type: articleType.updateArticle,
        payload: articleData,
    };
    
}

function resetUpdateArticleData(){
    return{
        type: articleType.resetUpdateArticle,
        payload:[],
    };
}

export {getAllArticle,resetAllArticle, updateArticleData, resetUpdateArticleData};