import React from "react";

import './Contact.css'
import ContactImage from '../DBKContactPage.png';
import contactQR from "../paymentQR.png";

class Contact extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }


    render(){
        return(
            <div className="contactContainer">
                <div className="ContactContent">

                    <img src={ContactImage} alt="contactUsImg" />

                    <h1> <strong>Contact </strong></h1>

                    <div className="panchangKarte">
                        <span>Panchang Creator : Shree Laxmikant Motisa Kolhapure</span><br/>
                        <strong><span>Address</span> : 543, East Mangalwar Peth, Kshatriya Galli, Solapur-413002 Maharashtra.</strong><br/>
                        <strong><span>Office</span> : 52, Sakhar Peth, 102 Shreeram Sankul, First Floor, Solapur-413002.</strong><br/>
                        <strong><span>Email</span> : kalniyojanpanchang@rediffmail.com.</strong><br/>
                        <strong><span>Phone</span> : 0217-2621188, <span>Mobile </span>: 0942-2621188</strong><br/>
                    </div>

                    <img src={contactQR} alt="contactPaymentQrCode" className="paymentQrCode"/>
                </div>
            </div>
        );
    }
}

export default Contact;