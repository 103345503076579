import './Donate.css';
import paymentQRimg from '../paymentQR.png';


function Donate(){

  return(
    <div className='kalniyojanDonationContainer'>
      <h2 className='kalniyojanDonationHeader'>
        Donation
      </h2>
      <div className='kalniyojanDonationDes'>
        <div className='descOne'>
          if you like/satisfied with the services of kalniyojan and if you want
          to support us, you can donate us through the provided QR code
          or bank transfer.
        </div>
        <div className='kalniyojanDonationOptions'>
          <div className='donationOptionBank'>
            <p className='optionBankNumber'><strong>Bank Number:</strong>11087797116</p>
            <p className='optionBankName'><strong>Name:</strong>Laxmikant Motisa Kolhapure</p>
            <p className='optionBankIFSCode'><strong>IFSC code:</strong>SBIN 0000483</p>
          </div>
          <img src={paymentQRimg} alt='kalniyojanDonationQr' />
        </div>
        <span className='kalniyojanDonationTermCondition'>All terms and conditions applied</span>
      </div>
    </div>
  );
}

export default Donate;