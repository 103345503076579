import { useEffect, useRef, useState } from "react";
import "./Advice.css"
import { useDispatch, useSelector } from "react-redux";
import { adviceError, advicePaymentError, resetAdviceError, resetAdvicePaymentError } from "../Redux/Action/ErrorAction";
import { isBoolean, isNaN, parseInt, toString } from "lodash";
import axios from "axios";
import kapi from "../Redux/kalniyojanapi";
import { adviceFee } from "../Redux/Action/FeesAction";
import { getStripeAppearance, uploadClientAdvice } from "../Helpers/Earth";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import KalniyojanCheckout from "./Stripescreens/KalniyojanCheckout";
import kalniyojanBillLogo from "../1.png";

function Advice(props) {

    let c_star = 0;
    let adviceFeeSelector;

    let [adviceFormErrorShower, SetAdviceFormErrorShower] = useState("adviceFormErrorShower");
    let [advicePaymentErrorShower, SetAdvicePaymentErrorShower] = useState("advicePaymentErrorShower");
    let [advicePaymentWindowCls, SetAdvicePaymentWindowCls] = useState("advicePaymentWindow")
    let [advicePaymentOptionsWindowCls, SetAdvicePaymentOptionsWindowCls] = useState("advicePaymentOptionsWindow");

    let [loadStripePromise, SetLoadStripePromise] = useState(null);

    let adviceErrorSelector = useSelector(state => state.errors.advice);
    let advicePaymentErrorSelector = useSelector(state => state.errors.payment);
    let adviceDispatcher = useDispatch();

    let adviceNameRef = useRef(null);
    let clientBirthYearRef = useRef(null);
    let clientBirthMonthRef = useRef(null);
    let clientBirthDayRef = useRef(null);
    let clientQuestionRef = useRef(null);
    let clientBirthHourRef = useRef(null);
    let clientBirthMinuteRef = useRef(null);
    let clientBirthPlaceRef = useRef(null);
    let adviceEmailRef = useRef(null);
    let advicePhoneRef = useRef(null);

    let stripePubKey = useRef({
        key:null,
    });
    let korderid = useRef({
        orderid:0
    });
    let useradviceformdata = useRef({
        formvalue:0,
    });

    useEffect(() => {

        axios({
            url: kapi.advicefeesapi,
            method: kapi.post,
            data:{
                servicename: kapi.adviceServiceName,
            },
            headers:{
                "Content-Type":"application/json",
            },
            withCredentials:true,
        }).then(result => {
            if(result.status !== 200){
                throw new Error();
            }
            let {fees} = result.data;
            adviceDispatcher(adviceFee(fees));
        }).catch(error =>{
            SetAdvicePaymentErrorShower("advicePaymentErrorShowerSelected");
            adviceDispatcher(advicePaymentError("failed to fetch service data"));
            setTimeout(() => {
                SetAdvicePaymentErrorShower("advicePaymentErrorShower");
                adviceDispatcher(resetAdvicePaymentError());
            }, 10000);
        });
    },[adviceFeeSelector])

    adviceFeeSelector = useSelector(state => state.fees.advice);

    let handlers = {

        insideIndia: true,
        currentDate: new Date(),
        countryCode: null,
        clientPhoneNumber: null,
        countrys: {
            Afghanistan: "Afghanistan",
            Albania: "Albania",
            Algeria: "Algeria",
            Andorra: "Andorra",
            Angola: "Angola",
            "Antigua and Barbuda":"Antigua and Barbuda",
            Argentina: "Argentina",
            Armenia: "Armenia",
            Australia:"Australia",
            Austria:"Austria",
            Azerbaijan:"Azerbaijan",
            Bahamas:"Bahamas",
            Bahrain:"Bahrain",
            Bangladesh:"Bangladesh",
            Barbados:"Barbados",
            Belarus:"Belarus",
            Belgium:"Belgium",
            Belize:"Belize",
            Benin:"Benin",
            Bhutan:"Bhutan",
            Bolivia:"Bolivia",
            "Bosnia and Herzegovina":"Bosnia and Herzegovina",
            Botswana:"Botswana",
            Brazil:"Brazil",
            Brunei:"Brunei",
            Bulgaria:"Bulgaria",
            "Burkina Faso":"Burkina Faso",
            Burundi:"Burundi",
            "Côte d'Ivoire":"Côte d'Ivoire",
            "Cabo Verde":"Cabo Verde",
            Cambodia:"Cambodia",
            Cameroon:"Cameroon",
            Canada:"Canada",
            "Central African Republic":"Central African Republic",
            Chad:"Chad",
            Chile:"Chile",
            China: "China",
            Colombia:"Colombia",
            Comoros:"Comoros",
            "Congo (Congo-Brazzaville)":"Congo (Congo-Brazzaville)",
            "Costa Rica":"Costa Rica",
            Croatia:"Croatia",
            Cuba:"Cuba",
            Cyprus:"Cyprus",
            "Czechia (Czech Republic)":"Czechia (Czech Republic)",
            "Democratic Republic of the Congo":"Democratic Republic of the Congo",
            Denmark:"Denmark",
            Djibouti:"Djibouti",
            Dominica:"Dominica",
            "Dominican Republic":"Dominican Republic",
            Ecuador:"Ecuador",
            Egypt:"Egypt",
            "El Salvador":"El Salvador",
            "Equatorial Guinea":"Equatorial Guinea",
            Eritrea:"Eritrea",
            Estonia:"Estonia",
            Swaziland:"Swaziland",
            Ethiopia:"Ethiopia",
            Fiji:"Fiji",
            Finland:"Finland",
            France:"France",
            Gabon:"Gabon",
            Gambia:"Gambia",
            Georgia:"Georgia",
            Germany:"Germany",
            Ghana:"Ghana",
            Greece:"Greece",
            Grenada:"Grenada",
            Guatemala:"Guatemala",
            Guinea:"Guinea",
            "Guinea-Bissau":"Guinea-Bissau",
            Guyana:"Guyana",
            Haiti:"Haiti",
            "Vatican City":"Vatican City",
            Honduras:"Honduras",
            Hungary:"Hungary",
            Iceland:"Iceland",
            India:"India",
            Indonesia:"Indonesia",
            Iran:"Iran",
            Iraq:"Iraq",
            Ireland:"Ireland",
            Israel:"Israel",
            Italy:"Italy",
            Jamaica:"Jamaica",
            Japan:"Japan",
            Jordan:"Jordan",
            Kazakhstan:"Kazakhstan",
            Kenya:"Kenya",
            Kiribati:"Kiribati",
            Kuwait:"Kuwait",
            Kyrgyzstan:"Kyrgyzstan",
            Laos:"Laos",
            Latvia:"Latvia",
            Lebanon:"Lebanon",
            Lesotho:"Lesotho",
            Liberia:"Liberia",
            Libya:"Libya",
            Liechtenstein:"Liechtenstein",
            Lithuania:"Lithuania",
            Luxembourg:"Luxembourg",
            Madagascar:"Madagascar",
            Malawi:"Malawi",
            Malaysia:"Malaysia",
            Maldives:"Maldives",
            Mali:"Mali",
            Malta:"Malta",
            "Marshall Islands":"Marshall Islands",
            Mauritania:"Mauritania",
            Mauritius:"Mauritius",
            Mexico:"Mexico",
            Micronesia:"Micronesia",
            Moldova:"Moldova",
            Monaco:"Monaco",
            Mongolia:"Mongolia",
            Montenegro:"Montenegro",
            Morocco:"Morocco",
            Mozambique:"Mozambique",
            Myanmar:"Myanmar",
            Namibia:"Namibia",
            Nauru:"Nauru",
            Nepal:"Nepal",
            Netherlands:"Netherlands",
            "New Zealand":"New Zealand",
            Nicaragua:"Nicaragua",
            Niger:"Niger",
            Nigeria:"Nigeria",
            "North Korea":"North Korea",
            "North Macedonia":"North Macedonia",
            Norway:"Norway",
            Oman:"Oman",
            Pakistan:"Pakistan",
            Palau:"Palau",
            Panama:"Panama",
            "Papua New Guinea":"Papua New Guinea",
            Paraguay:"Paraguay",
            Peru:"Peru",
            Philippines:"Philippines",
            Poland:"Poland",
            Portugal:"Portugal",
            Qatar:"Qatar",
            Romania:"Romania",
            Russia:"Russia",
            Rwanda:"Rwanda",
            "Saint Kitts and Nevis":"Saint Kitts and Nevis",
            "Saint Lucia":"Saint Lucia",
            "Saint Vincent and the Grenadines":"Saint Vincent and the Grenadines",
            Samoa:"Samoa",
            "San Marino":"San Marino",
            "Sao Tome and Principe":"Sao Tome and Principe",
            "Saudi Arabia":"Saudi Arabia",
            Senegal:"Senegal",
            Serbia:"Serbia",
            Seychelles:"Seychelles",
            "Sierra Leone":"Sierra Leone",
            Singapore:"Singapore",
            Slovakia:"Slovakia",
            Slovenia:"Slovenia",
            "Solomon Islands":"Solomon Islands",
            Somalia:"Somalia",
            "South Africa":"South Africa",
            "South Korea":"South Korea",
            "South Sudan":"South Sudan",
            Spain:"Spain",
            "Sri Lanka":"Sri Lanka",
            Sudan:"Sudan",
            Suriname:"Suriname",
            Sweden:"Sweden",
            Switzerland:"Switzerland",
            Syria:"Syria",
            Tajikistan:"Tajikistan",
            Tanzania:"Tanzania",
            Thailand:"Thailand",
            "Timor-Leste":"Timor-Leste",
            Togo:"Togo",
            Tonga:"Tonga",
            "Trinidad and Tobago":"Trinidad and Tobago",
            Tunisia:"Tunisia",
            Turkey:"Turkey",
            Turkmenistan:"Turkmenistan",
            Tuvalu:"Tuvalu",
            Uganda:"Uganda",
            Ukraine:"Ukraine",
            "United Arab Emirates":"United Arab Emirates",
            "United Kingdom":"United Kingdom",
            "United States of America":"United States of America",
            Uruguay:"Uruguay",
            Uzbekistan:"Uzbekistan",
            Vanuatu:"Vanuatu",
            Venezuela:"Venezuela",
            Vietnam:"Vietnam",
            Yemen:"Yemen",
            Zambia:"Zambia",
            Zimbabwe:"Zimbabwe",
        },

        countrysValues: function(){
            let countryMap = new Map(Object.entries(this.countrys))
            return countryMap.values();
        },

        daysInMonths: {
            "Jan": 31,
            "Feb": 28,
            "Mar": 31,
            "Apr": 30,
            "May": 31,
            "Jun": 30,
            "Jul": 31,
            "Aug": 31,
            "Sep": 30,
            "Oct": 31,
            "Nov": 30,
            "Dec": 31,
        },

        validatorTypes: {
            adviceName: "nameValidate",
            adviceEmail: "emailValidate",
            advicePhone: "phoneNumberValidate",
            adviceBirthYear: "birthYearValidate",
            adviceBirthMonth: "birthMonthValidate",
            adviceBirthDay: "birthDayValidate",
            adviceBirthHour: "birthHourValidate",
            adviceBirthMinute: "birthMinuteValidate",
            adviceDate: "wholeDate",
            adviceBirthCity: "birthCityValidate",
            adviceBirthCountry: "birthCountryValidate",
            adviceQuestion: "questionValidate",
        },

        resetHandlerCountryCodeAndPhoneNumberProperty: function(){
            this.clientPhoneNumber = null;
            this.countryCode = null;
        },

        handleAdvice: function(event) {
            event.preventDefault();

            if (adviceNameRef.current.value === null || !handlers.validator({
                name: adviceNameRef.current.value,
            }, this.validatorTypes.adviceName)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("client name must be grater than 5 char"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (adviceEmailRef.current.value === null || !handlers.validator({ email: adviceEmailRef.current.value }, this.validatorTypes.adviceEmail)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("email is not valid, only (gmail,hotmail,yahoo,rediffmail) supported"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (advicePhoneRef.current.value === null || !handlers.validator({ phone: advicePhoneRef.current.value }, this.validatorTypes.advicePhone)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("phoneNumber is not valid"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }


            if (clientBirthYearRef.current.value === null || isNaN(clientBirthYearRef.current.value) || !handlers.validator({
                year: clientBirthYearRef.current.value,
            }, this.validatorTypes.adviceBirthYear)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("birth year is not valid"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (clientBirthMonthRef.current.value === null || isNaN(clientBirthMonthRef.current.value) || !handlers.validator({
                month: clientBirthMonthRef.current.value,
                year: clientBirthYearRef.current.value,
            }, this.validatorTypes.adviceBirthMonth)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("birth month is not valid"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (clientBirthDayRef.current.value === null || isNaN(clientBirthDayRef.current.value) || !handlers.validator({
                year: clientBirthYearRef.current.value,
                month: clientBirthMonthRef.current.value,
                day: clientBirthDayRef.current.value,
            }, this.validatorTypes.adviceBirthDay)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("day is not valid, check filled date"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (!handlers.validator({
                date: {
                    year: clientBirthYearRef.current.value,
                    month: clientBirthMonthRef.current.value,
                    day: clientBirthDayRef.current.value,
                },
            }, this.validatorTypes.adviceDate)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("date is not valid, check filled date"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (clientBirthHourRef.current.value === null || isNaN(clientBirthHourRef.current.value) || !handlers.validator({ hour: clientBirthHourRef.current.value }, this.validatorTypes.adviceBirthHour)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("hour is not valid"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (clientBirthMinuteRef.current.value === null || isNaN(clientBirthMinuteRef.current.value) || !handlers.validator({ minute: clientBirthHourRef.current.value }, this.validatorTypes.adviceBirthMinute)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("minute is not valid"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (clientBirthPlaceRef.current.value === null || !handlers.validator({ place: clientBirthPlaceRef.current.value }, this.validatorTypes.adviceBirthCity)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("birth place is not valid, place should be atleast 4 char long"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (defaultCountry === null || !handlers.validator({ country: defaultCountry }, this.validatorTypes.adviceBirthCountry)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("birth country is not valid"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (clientQuestionRef.current.value === null || !handlers.validator({ question: clientQuestionRef.current.value }, this.validatorTypes.adviceQuestion)) {
                window.scrollTo(0,0);
                SetAdviceFormErrorShower("adviceFormErrorShowerSelected");
                adviceDispatcher(adviceError("not a valid question please put '?' at the end of question, and check question length is < 255 char"));
                setTimeout(() => {
                    SetAdviceFormErrorShower("adviceFormErrorShower");
                    adviceDispatcher(resetAdviceError());
                }, 30000);
                c_star++;
            }

            if (c_star === 0) {
                let adviceForm = new FormData();
                adviceForm.append("clientName", adviceNameRef.current.value);
                adviceForm.append("clientEmail", adviceEmailRef.current.value);
                adviceForm.append("clientPhone", this.clientPhoneNumber);
                adviceForm.append("clientPhoneCountryCode", this.countryCode);
                adviceForm.append("clientBirthYear", clientBirthYearRef.current.value);
                adviceForm.append("clientBirthMonth", clientBirthMonthRef.current.value);
                adviceForm.append("clientBirthDay", clientBirthDayRef.current.value);
                adviceForm.append("clientBirthHour", clientBirthHourRef.current.value);
                adviceForm.append("clientBirthMinute", clientBirthMinuteRef.current.value);
                adviceForm.append("clientBirthPlace", clientBirthPlaceRef.current.value);
                adviceForm.append("clientQuestion", clientQuestionRef.current.value);

                handlers.resetHandlerCountryCodeAndPhoneNumberProperty();
                setTimeout(() => {
                    submitQuestion(adviceForm);
                }, 1000);
            }

        },

        validator: function(data, type) {

            let getNumber = number => {
                if (number === "") {
                    return false;
                }
                let positionOfDash = number.indexOf("-");
                let lastPositionOfDash = number.lastIndexOf("-");
                if (positionOfDash === -1) {
                    return false;
                }
                if (positionOfDash !== lastPositionOfDash) {
                    return false;
                }
                let CC = number.substring(0, positionOfDash);
                let parsedCC = parseInt(CC);
                if (isNaN(parsedCC) || CC.length > 4 || CC.length < 1 || parsedCC === 0) {
                    return false;
                }
                let actualNumber = number.substring(positionOfDash + 1, number.length);
                let parsedActualNumber = parseInt(actualNumber);
                if (isNaN(parsedActualNumber) || actualNumber.length > 10) {
                    return false;
                }
                this.insideIndia = parsedCC !== 91 ? false : true;
                this.countryCode = parsedCC;
                this.clientPhoneNumber = parsedActualNumber;
                return parsedActualNumber;
            };

            let getDaysInAdviceMonth = (year, month) => {

                let is_leap_year = false;
                let adviceMonths = this.daysInMonths;

                if (year % 4 === 0) {
                    is_leap_year = true;
                }

                let months_map = new Map(Array.from(Object.entries(adviceMonths)));

                if (is_leap_year) {
                    let temp_months_keys_array = Array.from(months_map.keys());

                    let alter_feb = temp_months_keys_array.find(item => item === "Feb");

                    if (alter_feb !== null || alter_feb !== undefined) {
                        adviceMonths[alter_feb] = 29;
                    }
                }

                let months_keys_array = Array.from(months_map.keys());

                let user_expected_month = months_keys_array[(month - 1)];

                if (typeof user_expected_month === "string") {
                    return adviceMonths[user_expected_month];
                } else {
                    return false;
                }
            }

            switch (type) {

                case this.validatorTypes.adviceName:
                    if (data.name === "") {
                        return false;
                    }

                    let clientNameRegex = /[a-zA-Z]{5,}/i;
                    let isNameValid = clientNameRegex.test(data.name);
                    return isNameValid;

                case this.validatorTypes.adviceEmail:
                    if (data.email === "") {
                        return false;
                    }

                    let emailRegex = /[a-zA-Z0-9\-_.]+@(gmail|hotmail|yahoo|rediffmail)+(?:\.(com))/
                    let isEmailValid = emailRegex.test(data.email);
                    return isEmailValid;

                case this.validatorTypes.advicePhone:
                    let numberWithOutCode = getNumber(data.phone);

                    if (typeof numberWithOutCode === Boolean) {
                        return false;
                    }
                    if (this.insideIndia && numberWithOutCode.toString().length < 10) {
                        return false;
                    }
                    if (!this.insideIndia && numberWithOutCode.toString().length < 10) {
                        return false;
                    }
                    return true;

                case this.validatorTypes.adviceBirthYear:
                    if (data.year === "" || data.year <= 1900 || data.year.toString().length < 4) {
                        return false;
                    }

                    return data.year > this.currentDate.getFullYear() ? false : true;

                case this.validatorTypes.adviceBirthMonth:
                    if (data.month === "") {
                        return false;
                    }

                    if (data.month > 12 || (data.year > this.currentDate.getFullYear() && (data.month - 1) >= this.currentDate.getMonth())) {
                        return false;
                    }

                    if (data.year <= this.currentDate.getFullYear() && (data.month - 1) <= this.currentDate.getMonth()) {
                        return true;
                    }

                    if (data.month <= 12 && (data.year < this.currentDate.getFullYear() && ((data.month - 1) > this.currentDate.getMonth() || (data.month - 1) < this.currentDate.getMonth() || (data.month - 1) === this.currentDate.getMonth()))) {
                        return true;
                    }

                    if (data.year === this.currentDate.getFullYear() && (data.month - 1) > this.currentDate.getMonth()) {
                        return false;
                    }
                    break;

                case this.validatorTypes.adviceBirthDay:
                    if (data.day === "") {
                        return false;
                    }
                    let adviceDaysInMonth = getDaysInAdviceMonth(data.year, data.month);
                    if (isBoolean(adviceDaysInMonth)) {
                        return adviceDaysInMonth;
                    }
                    return data.day > adviceDaysInMonth ? false : true;

                case this.validatorTypes.adviceDate:

                    if (data.date.year > new Date().getFullYear() || data.date.month > 12) {
                        return false;
                    }

                    let days_in_provided_month = getDaysInAdviceMonth(data.date.year, data.date.month);


                    if (isBoolean(days_in_provided_month) || data.date.day > days_in_provided_month) {
                        return false;
                    }

                    let usr_date = [data.date.year, data.date.month, data.date.day].join("-");

                    let date_obj_of_usr_date = new Date(usr_date);

                    return date_obj_of_usr_date.valueOf() > new Date().valueOf() ? false : true;

                case this.validatorTypes.adviceBirthHour:
                    if (data.hour === "" || data.hour < 0 || data.hour > 24) {
                        return false;
                    } else {
                        return true;
                    }

                case this.validatorTypes.adviceBirthMinute:
                    if (data.minute === "" || data.minute < 0 || data.minute > 60) {
                        return false;
                    } else {
                        return true;
                    }

                case this.validatorTypes.adviceBirthCity:
                    if (data.place.length < 4) {
                        return false;
                    }
                    let placeRegex = /[a-zA-Z]{4,}/
                    let isPlaceValid = placeRegex.test(data.place);
                    if (!isPlaceValid) {
                        return false;
                    }
                    let advancedPlaceRegex = /(?=.*[@!+._\-?{}[\]])[@!+._\-?{}[\]]/
                    if (advancedPlaceRegex.test(data.place)) {
                        return false;
                    }
                    return true;

                case this.validatorTypes.adviceBirthCountry:
                    let countrysArr = Array.from(Object.entries(this.countrys));
                    let countryArrMap = new Map(countrysArr);
                    let foundCountry = Array.from(countryArrMap.values()).find(word => word === toString(data.country));
                    if(foundCountry){
                        return true;
                    }else{
                        return false;
                    }

                case this.validatorTypes.adviceQuestion:
                    if (data.question.charAt(data.question.length - 1) !== '?' || data.question.length > 225) {
                        return false;
                    } else {
                        return true;
                    }

                default:
                    return false;
            }
        },


    };

    async function getClientIp(){

        try {
            let getIpApiResponse = await axios({
                method: kapi.get,
                url: "https://api.ipify.org/?format=json",
            });

            if(getIpApiResponse.status !== 200){
                return "211.224.65.50";
            }else {
                return getIpApiResponse.data.ip;
            }
            
        } catch (error) {
            return "211.224.65.50";
        }
    }

    let paymentOptionHandlerObj = {

        handlePaymentOptionOne: function(){
            setTimeout(() => {
			    getOrderId();
            }, 1000);
            SetAdvicePaymentOptionsWindowCls("advicePaymentOptionsWindow");
            setTimeout(() => {
                SetAdvicePaymentWindowCls("advicePaymentWindowSelected");
            }, 200);
        },

        handlePaymentOptionTwo: function(){
            setTimeout(() => {
                getBOrderId();
            }, 1000);
            SetAdvicePaymentOptionsWindowCls("advicePaymentOptionsWindow");
        },
    };

    async function getPayConfig(){
 
        let payConfig = null;
	
        try{
	        payConfig = await axios({
		        method:kapi.get,
		        url:kapi.orderconfig,
		        withCredentials:true,
	        });
        }catch(error){
            SetAdvicePaymentErrorShower("advicePaymentErrorShowerSelected");
            adviceDispatcher(advicePaymentError("error creating order try later"));
            setTimeout(() => {
                SetAdvicePaymentErrorShower("advicePaymentErrorShower");
                adviceDispatcher(resetAdvicePaymentError());
            }, 10000);
        }

        return payConfig;
	}
   

    let [defaultCountry, setDefaultCountry] = useState(handlers.countrys.India);

    async function handleBillPaymentComplete(billRes){
        
        let uploadAdviceRes = null;
        
        if(billRes.status === 200){
            uploadAdviceRes = await uploadClientAdvice(useradviceformdata.current.formvalue);
        }

        if(uploadAdviceRes.success){
            setTimeout(() => {
                window.location.href = "/servicessuccess";
            }, 1000);
        }else {
            SetAdvicePaymentErrorShower("advicePaymentErrorShowerSelected");
            adviceDispatcher(advicePaymentError(uploadAdviceRes.message));
            setTimeout(() => {
                SetAdvicePaymentErrorShower("advicePaymentErrorShower");
                adviceDispatcher(resetAdvicePaymentError());
            }, 5000);
            window.location.reload();
        }
    }

    function prepareBillSdkObj(order_obj){

        let {bdorderid,token,authorization} = order_obj;

        let flow_obj = {
            merchantId: token,
            bdOrderId: bdorderid,
            authToken: authorization,
            childWindow: true,
            prefs: {
            "payment_categories": ["card","upi","gpay","qr","nb"],
            },
            netBanking:{
                "showPopularBanks":"N",
            }
        }

        let config = {
            responseHandler: handleBillPaymentComplete,
            merchantLogo: kalniyojanBillLogo,
            flowConfig: flow_obj,
            flowType: "payments",
        }

        window.loadBillDeskSdk(config);
    }

    let getOrderId = function(){
        let orderForm = new FormData();
        orderForm.append("amount",adviceFeeSelector);
        orderForm.append("currency","inr");

        axios({
            method: kapi.post,
            url: kapi.orderidapi,
            data: orderForm,
            headers:{
                "Content-Type": "application/json",
            },
            withCredentials: true,
        }).then(async result => {
            if(result.status !== 200){
                throw new Error();
            }
            korderid.current.orderid = result.data.data;
            let config = await getPayConfig().then(result => {
                if(result.status === 200){
                    stripePubKey.current.key = result.data.data;
                    return result.data.data;
                }
            }).catch(error => {
                SetAdvicePaymentErrorShower("advicePaymentErrorShowerSelected");
                adviceDispatcher(advicePaymentError("failed to create order try later"));
                setTimeout(() => {
                    SetAdvicePaymentErrorShower("advicePaymentErrorShower");
                    adviceDispatcher(resetAdvicePaymentError());
                }, 10000);
            });
            if(config === null){
                throw new Error({
                    message:"failed to create order",
                });
            }
            SetLoadStripePromise(loadStripe(config));
        }).catch(error => {
            if(error.response !== undefined){
                SetAdvicePaymentErrorShower("advicePaymentErrorShowerSelected");
                adviceDispatcher(advicePaymentError(error.response.data.message));
                setTimeout(() => {
                    SetAdvicePaymentErrorShower("advicePaymentErrorShower");
                    adviceDispatcher(resetAdvicePaymentError());
                }, 10000);
            }else if(error instanceof Object){
                SetAdvicePaymentErrorShower("advicePaymentErrorShowerSelected");
                adviceDispatcher(advicePaymentError(error.message));
                setTimeout(() => {
                    SetAdvicePaymentErrorShower("advicePaymentErrorShower");
                    adviceDispatcher(resetAdvicePaymentError());
                }, 10000);
            }else{
                SetAdvicePaymentErrorShower("advicePaymentErrorShowerSelected");
                adviceDispatcher(advicePaymentError("failed to fetch order data"));
                setTimeout(() => {
                    SetAdvicePaymentErrorShower("advicePaymentErrorShower");
                    adviceDispatcher(resetAdvicePaymentError());
                }, 10000);
            }
        })
    }

    let getBOrderId = async function(){
        

        let bOrderForm = new FormData();
        bOrderForm.append("amount",adviceFeeSelector);
        bOrderForm.append("currency","inr");
        bOrderForm.append("clientip",await getClientIp());
        bOrderForm.append("useragent",window.navigator.userAgent);

        axios({
            url: kapi.borderidapi,
            method: kapi.post,
            data: bOrderForm,
            headers: {
                "Content-Type":"application/json",
            },
            withCredentials: true,
        }).then(result => {
            if(result.status !== 200){
                throw new Error({message:"order creation failed"});
            }else{
                SetAdvicePaymentOptionsWindowCls("advicePaymentOptionsWindow");
                prepareBillSdkObj(result.data.data);
            }
        }).catch(error => {
            SetAdvicePaymentOptionsWindowCls("advicePaymentOptionsWindow");
            if(error.response !== undefined){
                SetAdvicePaymentErrorShower("advicePaymentErrorShowerSelected");
                adviceDispatcher(advicePaymentError(error.response.data.message));
                setTimeout(() => {
                    SetAdvicePaymentErrorShower("advicePaymentErrorShower");
                    adviceDispatcher(resetAdvicePaymentError());
                }, 6000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
            }else if(error instanceof Object){
                SetAdvicePaymentErrorShower("advicePaymentErrorShowerSelected");
                adviceDispatcher(advicePaymentError(error.message));
                setTimeout(() => {
                    SetAdvicePaymentErrorShower("advicePaymentErrorShower");
                    adviceDispatcher(resetAdvicePaymentError());
                }, 6000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
            }else {
                SetAdvicePaymentErrorShower("advicePaymentErrorShowerSelected");
                adviceDispatcher(advicePaymentError("something is wrong may be server is down, try later/again"));
                setTimeout(() => {
                    SetAdvicePaymentErrorShower("advicePaymentErrorShower");
                    adviceDispatcher(resetAdvicePaymentError());
                }, 6000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
            }
        });
    }

	function handleAdviceOutsideIndia(value){
		setDefaultCountry(value);
		let outsideIndiaFees = adviceFeeSelector * 5;
		if(value !== handlers.countrys.India && adviceFeeSelector === 100){
			adviceDispatcher(adviceFee(outsideIndiaFees));
		}else if(value === handlers.countrys.India && adviceFeeSelector === 500){
			adviceDispatcher(adviceFee(adviceFeeSelector/5));
		}
	}

    function submitQuestion(questionFormData) {
        questionFormData.append("clientBirthCountry",defaultCountry);
        useradviceformdata.current.formvalue = questionFormData;
        SetAdvicePaymentOptionsWindowCls("advicePaymentOptionsWindowSelected");
    }

    return (
        <div className="adviceContainer">
            <div className="adviceContainerContent">
                <h2 className="advicePageHeader">Question Form</h2>
                <div className={adviceFormErrorShower}>
                    {adviceErrorSelector.map((error, index) =>
                        <div key={index}>{error}</div>
                    )}
                </div>
                <div className={advicePaymentErrorShower}>
                    {advicePaymentErrorSelector.map((error, index) =>
                        <div key={index}>{error}</div>
                    )}
                </div>
                <div className="kalniyojanAdviceFeesIndicator">
                    <div>Fees</div>
                    <div id="adviceFees">{adviceFeeSelector} INR</div>
                </div>
                <form onSubmit={event => handlers.handleAdvice(event)} >
                    <div className="adviceNameRow">
                        <label htmlFor="advicenamelabel"><span>*</span> Client Name:</label>
                        <input type="text" name="adviceName" className="adviceName" ref={adviceNameRef} />
                    </div>
                    <div className="adviceEmailRow">
                        <label htmlFor="adviceemaillabel"><span>*</span> Client Email:</label>
                        <input type="text" name="adviceEmail" className="adviceEmail" ref={adviceEmailRef} />
                    </div>
                    <div className="advicePhoneRow">
                        <label htmlFor="advicephonelabel"><span>*</span> Client PhoneNumber:</label>
                        <input type='text' name="advicePhone" className="advicePhone" ref={advicePhoneRef} />
                        <div className="advicePhoneFormatNote">
                            NOTE: use this format (country code - phonenumber)(eg: 91-0123456789)
                        </div>
                    </div>
                    <div className="adviceBirthDateHeader">Birth Date</div>
                    <div className="adviceClientBirthDateRow">
                        <div className="clientBirthYearRow">
                            <label htmlFor="clientyearlabel"><span>*</span> BirthYear:</label>
                            <input type="number" name="clientBirthYear" className="clientBirthYear" ref={clientBirthYearRef} />
                        </div>
                        <div className="clientBirthMonthRow">
                            <label htmlFor="clientmonthlabel"><span>*</span> BirthMonth:</label>
                            <input type="number" name="clientBirthMonth" className="clientBirthMonth" ref={clientBirthMonthRef} />
                        </div>
                        <div className="clientBirthDayRow">
                            <label htmlFor="clientdaylabel"><span>*</span> BirthDay:</label>
                            <input type="number" name="clientBirthDay" className="clientBirthDay" ref={clientBirthDayRef} />
                        </div>
                    </div>
                    <div className="adviceBirthTimeHeader">Birth Time</div>
                    <div className="adviceClientBirthTimeRow">
                        <div className="birthTimeHourMinuteRow">
                            <div className="clientBirthTimeHourRow">
                                <label htmlFor="clientbirthhourlabel"><span>*</span> BirthHour:</label>
                                <input type="number" name="clientBirthHour" className="clientBirthHour" ref={clientBirthHourRef} />
                            </div>
                            <div className="clientBirthTimeMinuteRow">
                                <label htmlFor="clientbirthminutelabel"><span>*</span> BirthMinutes:</label>
                                <input type="number" name="clientBirthMinute" className="clientBirthMinute" ref={clientBirthMinuteRef} />
                            </div>
                        </div>
                        <div className="adviceBirthTimeFormat">
                            NOTE: Please use 24 hour time format in hour field.
                        </div>
                    </div>
                    <div className="adviceBirthPlaceHeader">
                        Birth city/country
                    </div>
                    <div className="adviceBirthPlaceAndCountry">
                        <div className="adviceClientBirthPlaceRow">
                            <label htmlFor="birthplacelabel"><span>*</span> BirthCityName:</label>
                            <input type="text" name="clientBirthPlace" className="clientBirthPlace" ref={clientBirthPlaceRef} />
                        </div>
                        <div className="adviceClientBirthCountryRow">
                            <label htmlFor="birthcountrylabel"><span>*</span> BirthCountryName:</label>
                            <select value={defaultCountry} onChange={e => handleAdviceOutsideIndia(e.target.value)}>
                                {Array.from(handlers.countrysValues()).map((item,index) => 
                                    <option key={index}>{item}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="clientQuestionRow">
                        <label htmlFor="questionlabel"><span>*</span> Question:</label>
                        <input type="text" name="clientQuestion" className="clientQuestion" ref={clientQuestionRef} />
                    </div>
                    <div className="adviceSubmitBtn">
                        <button value="submit" type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div className={advicePaymentOptionsWindowCls}>
                <div className="kalniyojanPaymentOptionOne">
                    <p>select this payment option if you want to pay through (master,visa) credit/debit card</p>
                    <button onClick={paymentOptionHandlerObj.handlePaymentOptionOne}>Select</button>
                </div>
                <div className="kalniyojanPaymentOptionTwo">
                    <p>select this payment option if you want to pay through (netbanking,upi,QR) or (Rupay) credit/debit card</p>
                    <button onClick={paymentOptionHandlerObj.handlePaymentOptionTwo}>Select</button>
                </div>
            </div>
            { stripePubKey.current.key && <div className={advicePaymentWindowCls}>
                <Elements stripe={loadStripePromise} options={getStripeAppearance(korderid.current.orderid)}>
                    <KalniyojanCheckout sendData={{serviceName:"advice", formD:useradviceformdata.current.formvalue}} payAmount={adviceFeeSelector} paymentWindow={() => {SetAdvicePaymentWindowCls("advicePaymentWindow"); window.location.reload(); }} orderId={korderid.current.orderid} stripePub={stripePubKey.current.key}/>
                </Elements>
            </div>}
        </div>
    );

}

export default Advice;
