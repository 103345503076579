import { useEffect, useState } from "react";
import patrikaSubmittedImg from "../images/successtick.svg";

import "./AdminPatrika.css";
import axios from "axios";
import kapi from "../Redux/kalniyojanapi";
import { useDispatch, useSelector } from "react-redux";
import { resetAPatrikaError, setAdminPatrikaError } from "../Redux/Action/ErrorAction";
import ServicesAction from "../Redux/Action/ServicesAction";

export default function AdminPatrika(props){

    let adminPatrikaTabCls = "adminDashboardPatrikaTabContainerSelected";

    let adminPatrikaClientsSelector = null;
    if(!props.show){
        adminPatrikaTabCls = "adminDashboardPatrikaTabContainer";
    }

    let [adminPatrikaError,SetAdminPatrikaError] = useState("adminPatrikaErrorShower");

    let adminPatrikaDispatcher = useDispatch();

    let apatrikaerrorselector = useSelector(state => state.errors.aPatrika);

    function clearAdminPatrikaUserCredentials(){
        let adminpatrikakapt = localStorage.getItem("kapt");
        if(adminpatrikakapt !== null){
            localStorage.removeItem("kapt");
            window.location.href = "/login";
        }
    }

    async function handleAdminPatrikaActionBtn(key){
        let adminPatrikaActionResponse = null;

        let adminPatrikaActionKapt = localStorage.getItem("kapt");
        if(adminPatrikaActionKapt === null){
            window.location.href = "/login";
        }

        try{

            adminPatrikaActionResponse = await axios({
                method: kapi.post,
                url: kapi.adminpatrikacompleteapi,
                data: {
                    "key":key,
                },
                headers:{
                    "Content-Type":"application/json",
                    "Authorization": `Bearer ${adminPatrikaActionKapt}`,
                },
                withCredentials: true,
            });

            if(adminPatrikaActionResponse.status !== 200){
                throw new Error("failed to perform this action!!!");
            }else{
                if(adminPatrikaActionResponse.data.data){
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                }
            }
        }catch(error){
            if(error.response !== undefined){

                let adminpatrikaActionResponse = error.response;

                SetAdminPatrikaError("adminPatrikaErrorShowerSelected");
                adminPatrikaDispatcher(setAdminPatrikaError(adminpatrikaActionResponse.data.message));
                setTimeout(() => {
                    SetAdminPatrikaError("adminPatrikaErrorShower");
                    adminPatrikaDispatcher(resetAPatrikaError());
                }, 8000);
            }else{
                SetAdminPatrikaError("adminPatrikaErrorShowerSelected");
                adminPatrikaDispatcher(setAdminPatrikaError("can not perform this action may be server is down try later!!!"));
                setTimeout(() => {
                    SetAdminPatrikaError("adminPatrikaErrorShower");
                    adminPatrikaDispatcher(resetAPatrikaError());
                }, 8000);

            }
        }
    }

    async function getPatrikaClients(){

        let adminpatrikakapt = localStorage.getItem("kapt");

        if(adminpatrikakapt === null){
            window.location.href = "/login";
        }
        
        let adminPatrikaClients = null;

        try{

            adminPatrikaClients = await axios({
                method: kapi.get,
                url: kapi.adminjanmapatrikalist,
                headers: {
                    Authorization: `Bearer ${adminpatrikakapt}`,
                },
                withCredentials: true,
            });

            if(adminPatrikaClients.status !== 200){
                throw new Error("handle error");
            }else{
                adminPatrikaDispatcher(ServicesAction.setPatrika(adminPatrikaClients.data));
            }
        }catch(error){
            if(error.response !== undefined){
                let response = error.response;
                if(response.status === 401){
                    clearAdminPatrikaUserCredentials();
                }else if(response.status === 404){
                    SetAdminPatrikaError("adminPatrikaErrorShowerSelected");
                    adminPatrikaDispatcher(setAdminPatrikaError(response.data.message));
                    setTimeout(() => {
                        SetAdminPatrikaError("adminPatrikaErrorShower");
                        adminPatrikaDispatcher(resetAPatrikaError());
                    }, 8000);
                }
            }else{
                SetAdminPatrikaError("adminPatrikaErrorShowerSelected");
                adminPatrikaDispatcher(setAdminPatrikaError("something is wrong may be server is down try later"));
                setTimeout(() => {
                    SetAdminPatrikaError("adminPatrikaErrorShower");
                    adminPatrikaDispatcher(resetAPatrikaError());
                }, 8000);
            }
        }
    }

    useEffect(() => {
        getPatrikaClients();
    },[adminPatrikaClientsSelector]);

    adminPatrikaClientsSelector = useSelector(state => state.services.patrika);

    return(
        <div className={adminPatrikaTabCls}>
            <div className="adminDashboardPatrikaTabContainerContent">

                <div className={adminPatrikaError}>
                    {Array.from(apatrikaerrorselector).map((item,index) => 
                        <div key={index}>{item}</div>
                    )}
                </div>

                {Array.from(adminPatrikaClientsSelector).length > 0 ?                           

                    <table className="adminDashboardPatrikaTable">
                        <thead>
                            <tr className="adminpatrikatableheaderrow">
                                <td>Client Details</td>
                                <td>Client Data</td>
                                <td className="adminPatrikaAction">
                                    action
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from(adminPatrikaClientsSelector).map((item) => 
                                <tr key={item.patrikakey}>
                                    <td>
                                        <div>name</div>
                                        <div>birth date</div>
                                        <div>birth time</div>
                                        <div>birth place</div>
                                        <div>birth country</div>
                                        <div>tier</div>
                                        <div>sex</div>
                                        <div>language</div>
                                        <div>phone</div>
                                        <div>phone code</div>
                                        <div>email</div>
                                    </td>
                                    <td>
                                        <div>{item.name}</div>
                                        <div>{item.date}</div>
                                        <div>{item.time}</div>
                                        <div>{item.place}</div>
                                        <div>{item.country}</div>
                                        <div>{item.tier}</div>
                                        <div>{item.sex}</div>
                                        <div>{item.language}</div>
                                        <div>{item.phone}</div>
                                        <div>{item.phonecode}</div>
                                        <div>{item.email}</div>
                                    </td>
                                    <td>
                                        <button className="patrikaSubmitted" title="patrika delivered" onClick={() => handleAdminPatrikaActionBtn(item.patrikakey)}>
                                            <img src={patrikaSubmittedImg} className="adminClientPatrikaDelivered" alt="patrikaDeliveredBtn" />
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table> : 
                    <div className="adminPatrikaEmptyList">
                        no patrika client available!!!
                    </div>
                }
            </div>
        </div>
    );

}