import "./AdminMainPageImage.css";
import { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { addMainPageImgFormError, resetMainPageError } from "../Redux/Action/ErrorAction";
import axios from "axios";
import kapi from "../Redux/kalniyojanapi";

function AdminMainPageImage(props){

    let main_page_error_counter = 0;

    let adminMainPageImageContainerClassName = "kalniyojanAdminMainPageImageContainerSelected";

    if(!props.show){
        adminMainPageImageContainerClassName = "kalniyojanAdminMainPageImageContainer";
    }

    useEffect(() => {

        let isAuth = localStorage.getItem("kapt");

        if(isAuth === null || isAuth === undefined){
            window.location.href = "/login";
        }
    });

    let [frontImage, SetFrontImage] = useState(null);
    let [frontBackImage, SetFrontBackImage] = useState(null);
    let [backBackImage, SetBackBackImage] = useState(null);

    let [mainPageErrorClsName, SetMainPageClsName] = useState("mainPageErrorShower");

    let MainPageErrorDispatcher = useDispatch();

    function handleFrontImgOnChange(e){
        SetFrontImage(e.target.files[0]);
    }

    function handleFrontBackImgOnChange(e){
        SetFrontBackImage(e.target.files[0]);
    }

    function handleBackImgOnChange(e){
        SetBackBackImage(e.target.files[0]);
    }

    function handleMainPageImageForm(e){
        e.preventDefault();

        MainPageErrorDispatcher(resetMainPageError());
        SetMainPageClsName("mainPageErrorShower");

        validateImages();
    
        if(main_page_error_counter === 0){

            let main_page_formData = new FormData();
            main_page_formData.append("front",frontImage);
            main_page_formData.append("frontBack",frontBackImage);
            main_page_formData.append("backBack",backBackImage);
            
            uploadMainPageImgs(main_page_formData);
        }
    }

    function validateImages(){
        
        if(frontImage === null || frontBackImage === null || backBackImage === null){
            SetMainPageClsName("mainPageErrorShowerSelected");
            MainPageErrorDispatcher(addMainPageImgFormError("please provide files, files can not be empty!!!"));
            setTimeout(() => {
                SetMainPageClsName("mainPageErrorShower");
                MainPageErrorDispatcher(resetMainPageError());
            }, 5000);
            main_page_error_counter++;
        }
        
        if(frontImage.size > 10485760 || frontBackImage.size > 10485760 || backBackImage.size > 10485760){
            SetMainPageClsName("mainPageErrorShowerSelected");
            MainPageErrorDispatcher(addMainPageImgFormError("file size can not be greater than 10mb!!!"));
            setTimeout(() => {
                SetMainPageClsName("mainPageErrorShower");
                MainPageErrorDispatcher(resetMainPageError());
            }, 5000);
            main_page_error_counter++;
        }

        if((frontImage.type !== "image/jpeg" && frontImage.type !== "image/svg+xml") || (frontBackImage.type !== "image/jpeg" && frontBackImage.type !== "image/svg+xml") || (backBackImage.type !== "image/jpeg" && frontBackImage.type !== "image/svg+xml")){
            SetMainPageClsName("mainPageErrorShowerSelected");
            MainPageErrorDispatcher(addMainPageImgFormError(`only jpg/svg files are expected!!!`));
            setTimeout(() => {
                SetMainPageClsName("mainPageErrorShower");
                MainPageErrorDispatcher(resetMainPageError());
            }, 5000);
            main_page_error_counter++;
        }
    }

    let main_page_selector = useSelector(state => state.errors.mainPageImgError);

    function uploadMainPageImgs(formData){

        let kapt_token = localStorage.getItem("kapt");

        axios({
            method: kapi.post,
            url: kapi.adminaddmainpageapi,
            data: formData,
            headers:{
                "Content-Type":"multipart/form-data",
                "Authorization": `Bearer ${kapt_token}`,
            },
            withCredentials: true,
        }).then(result => {
            if(result.status !== 200){
                throw new Error(result);
            }else{
                window.location.reload();
            }
        }).catch(error => {
            SetMainPageClsName("mainPageErrorShowerSelected");
            if(error.response !== undefined){
                if(error.response.data === ""){
					localStorage.removeItem("kapt");
					window.location.href = "/";
                }
                if(error.response.data === false && error.response.status === 406){
                    MainPageErrorDispatcher(addMainPageImgFormError("name of file already exist change and try again"));
        			setTimeout(() => {
        				SetMainPageClsName("updateArticleErrorShower");
                        MainPageErrorDispatcher(resetMainPageError());
        			}, 5000);
                }
            }else{
                MainPageErrorDispatcher(addMainPageImgFormError("something is wrong may be server is down, please try later"));
    			setTimeout(() => {
    				SetMainPageClsName("updateArticleErrorShower");
                    MainPageErrorDispatcher(resetMainPageError());
    			}, 5000);
            }
        });
    }

    return(
         <div className={adminMainPageImageContainerClassName}>
            <div className={mainPageErrorClsName}>
                {Array.from(main_page_selector).map((item,index) => 
                    <div key={index}>{item}</div>
                )}
            </div>
            <form onSubmit={event => handleMainPageImageForm(event)}>
                <div className="adminMainPageFrontImageRow">
                    <label>Front: </label>
                    <input type="file" name="MainPageFrontImage" onChange={event => handleFrontImgOnChange(event)} />
                </div>

                <div className="adminMainPageFrontBackImageRow">
                    <label>FrontBack: </label>
                    <input type="file" name="MainPageFrontBackImage" onChange={event => handleFrontBackImgOnChange(event)} />
                </div>

                <div className="adminMainPageBackBackImageRow">
                    <label>BackBack: </label>
                    <input type="file" name="MainPageBackBackImage" onChange={ event => handleBackImgOnChange(event)} />
                </div>

                <div className="kalniyojanAdminMainPageSubmitBtn">
                    <button type="submit" value="submit">Submit</button>
                </div>
            </form>
        </div>
    );
}

export default AdminMainPageImage;