
import { useEffect, useRef, useState } from "react";
import "./Muhurta.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import kapi from "../Redux/kalniyojanapi";
import { muhurtaFee } from "../Redux/Action/FeesAction";
import { resetMuhurtaError, resetMuhurtaPaymentError, setMuhurtaError, setMuhurtaPaymentError } from "../Redux/Action/ErrorAction";
import { countryValidator, dateValidator, emailValidator, getBillSdkConfig, getClientIp, getStripeAppearance, muhurtaValidator, nameValidator, placeValidator, timeValidator, uploadClientMuhurta } from "../Helpers/Earth.js";
import { isBoolean } from "lodash";
import { Elements } from "@stripe/react-stripe-js";
import KalniyojanCheckout from "./Stripescreens/KalniyojanCheckout";
import { loadStripe } from "@stripe/stripe-js";


function Muhurta(){

	let mu_star = 0;

	let requesterInsideIndia = true;

	let muhurtaOrderId = useRef({
		orderid:null,
	});
	let muhurtaStripePub = useRef({
		key: null,
	});

	let garbhaDhanMuhurtaFormData = useRef({
		data:0,
	});
	let normalMuhurtaFormData = useRef({
		data:0,
	});
	let oneForAllFormData = useRef({
		data:0,
	});

	let [muhurtaPaymentWindowCls, SetMuhurtaPaymentWindowCls] = useState("muhurtaPaymentWindow");
	let [muhurtaStripePromise, SetStripeMuhurtaPromise] = useState(null);

	let [outsideIndia,SetOutsideIndia] = useState(false);
	let [garbhaOutsideIndia, SetGarbhaOutsideIndia] = useState(false);
	let [maleGarbhaOutsideIndia, SetMaleGarbhaOutsideIndia] = useState(false);

	let [muhurtaPaymentOptionsWindowCls, SetMuhurtaPaymentOptionsWindowCls] = useState("muhurtaPaymentOptionsWindow");

    let muhurtaClientNameRef = useRef(null);
    let muhurtaClientEmailRef = useRef(null);
    let muhurtaClientPhoneRef = useRef(null);

    let garbhadhanFemaleNameRef = useRef(null);
    let garbhadhanMaleNameRef = useRef(null);
    let garbhadhanFemaleBirthDateRef = useRef(null);
    let garbhadhanMaleBirthDateRef = useRef(null);
    let garbhadhanFemaleBirthTimeRef = useRef(null);
    let garbhadhanMaleBirthTimeRef = useRef(null);
    let garbhadhanFemaleBirthPlaceRef = useRef(null);
    let garbhadhanMaleBirthPlaceRef = useRef(null);

	let oneForAllNameRef = useRef(null);
	let oneForAllBirthDateRef = useRef(null);
	let oneForAllBirthTimeRef = useRef(null);
	let oneForAllBirthPlaceRef = useRef(null);

    let [garbhadhanFormFieldClsName, SetGarbhadhanFormFieldClsName] = useState("kalniyojanMuhurtaGarbhaDhanField");
	let [oneForAllMuhurtaFormFieldsClsName, SetOneForAllMuhurtaFormFieldsClsName] = useState("oneForAllMuhurtaFormField");

	let [muhurtaErrorShower,SetMuhurtaErrorShower] = useState("muhurtaErrorShower");
	let [muhurtaPaymentErrorShower, SetMuhurtaPaymentErrorShower] = useState("muhurtaPaymentErrorShower");


	let muhurtaErrorSelector = useSelector(state => state.errors.muhurta);
	let muhurtaPaymentErrorSelector = useSelector(state => state.errors.payment);
	let muhurtaFeeSelector = null;
	let muhurtaDispatcher = useDispatch();

	let [oneForAllNameLabel, SetOneForAllNameLabel] = useState("name");
	let [oneForAllDateLabel, SetOneForAllDateLabel] = useState("birth date");
	let [oneForAllTimeLabel, SetOneForAllTimeLabel] = useState("birth time");
	let [oneForAllPlaceLabel, SetOneForAllPlaceLabel] = useState("birth place");
	let [oneForAllCountryLabel, SetOneForAllCountryLabel] = useState("birth country");
	let [oneForAllHeader, SetOneForAllHeader] = useState(null);

	useEffect(() => {

		axios({
			method:kapi.post,
			url: kapi.muhurtafeeapi,
			data:{
				servicename: kapi.muhurtaServiceName,
			},
			headers:{
				"Content-Type":"application/json",
			},
			withCredentials: true,
		}).then(result => {
			if(result.status !== 200){
				throw new Error();
			}
			let {fees} = result.data;
			muhurtaDispatcher(muhurtaFee(fees));
		}).catch(error => {
			SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaPaymentError("failed to fetch service data"));
			setTimeout(() => {
				SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShower");
				muhurtaDispatcher(resetMuhurtaPaymentError());
			}, 8000);
		});
	},[muhurtaFeeSelector]);

	muhurtaFeeSelector = useSelector(state => state.fees.muhurta);

    let MuhurtaHelperHandler = {

		countrys: {
			Afghanistan: "Afghanistan",
			Albania: "Albania",
			Algeria: "Algeria",
			Andorra: "Andorra",
			Angola: "Angola",
			"Antigua and Barbuda":"Antigua and Barbuda",
			Argentina: "Argentina",
			Armenia: "Armenia",
			Australia:"Australia",
			Austria:"Austria",
			Azerbaijan:"Azerbaijan",
			Bahamas:"Bahamas",
			Bahrain:"Bahrain",
			Bangladesh:"Bangladesh",
			Barbados:"Barbados",
			Belarus:"Belarus",
			Belgium:"Belgium",
			Belize:"Belize",
			Benin:"Benin",
			Bhutan:"Bhutan",
			Bolivia:"Bolivia",
			"Bosnia and Herzegovina":"Bosnia and Herzegovina",
			Botswana:"Botswana",
			Brazil:"Brazil",
			Brunei:"Brunei",
			Bulgaria:"Bulgaria",
			"Burkina Faso":"Burkina Faso",
			Burundi:"Burundi",
			"Côte d'Ivoire":"Côte d'Ivoire",
			"Cabo Verde":"Cabo Verde",
			Cambodia:"Cambodia",
			Cameroon:"Cameroon",
			Canada:"Canada",
			"Central African Republic":"Central African Republic",
			Chad:"Chad",
			Chile:"Chile",
			China: "China",
			Colombia:"Colombia",
			Comoros:"Comoros",
			"Congo (Congo-Brazzaville)":"Congo (Congo-Brazzaville)",
			"Costa Rica":"Costa Rica",
			Croatia:"Croatia",
			Cuba:"Cuba",
			Cyprus:"Cyprus",
			"Czechia (Czech Republic)":"Czechia (Czech Republic)",
			"Democratic Republic of the Congo":"Democratic Republic of the Congo",
			Denmark:"Denmark",
			Djibouti:"Djibouti",
			Dominica:"Dominica",
			"Dominican Republic":"Dominican Republic",
			Ecuador:"Ecuador",
			Egypt:"Egypt",
			"El Salvador":"El Salvador",
			"Equatorial Guinea":"Equatorial Guinea",
			Eritrea:"Eritrea",
			Estonia:"Estonia",
			Swaziland:"Swaziland",
			Ethiopia:"Ethiopia",
			Fiji:"Fiji",
			Finland:"Finland",
			France:"France",
			Gabon:"Gabon",
			Gambia:"Gambia",
			Georgia:"Georgia",
			Germany:"Germany",
			Ghana:"Ghana",
			Greece:"Greece",
			Grenada:"Grenada",
			Guatemala:"Guatemala",
			Guinea:"Guinea",
			"Guinea-Bissau":"Guinea-Bissau",
			Guyana:"Guyana",
			Haiti:"Haiti",
			"Vatican City":"Vatican City",
			Honduras:"Honduras",
			Hungary:"Hungary",
			Iceland:"Iceland",
			India:"India",
			Indonesia:"Indonesia",
			Iran:"Iran",
			Iraq:"Iraq",
			Ireland:"Ireland",
			Israel:"Israel",
			Italy:"Italy",
			Jamaica:"Jamaica",
			Japan:"Japan",
			Jordan:"Jordan",
			Kazakhstan:"Kazakhstan",
			Kenya:"Kenya",
			Kiribati:"Kiribati",
			Kuwait:"Kuwait",
			Kyrgyzstan:"Kyrgyzstan",
			Laos:"Laos",
			Latvia:"Latvia",
			Lebanon:"Lebanon",
			Lesotho:"Lesotho",
			Liberia:"Liberia",
			Libya:"Libya",
			Liechtenstein:"Liechtenstein",
			Lithuania:"Lithuania",
			Luxembourg:"Luxembourg",
			Madagascar:"Madagascar",
			Malawi:"Malawi",
			Malaysia:"Malaysia",
			Maldives:"Maldives",
			Mali:"Mali",
			Malta:"Malta",
			"Marshall Islands":"Marshall Islands",
			Mauritania:"Mauritania",
			Mauritius:"Mauritius",
			Mexico:"Mexico",
			Micronesia:"Micronesia",
			Moldova:"Moldova",
			Monaco:"Monaco",
			Mongolia:"Mongolia",
			Montenegro:"Montenegro",
			Morocco:"Morocco",
			Mozambique:"Mozambique",
			Myanmar:"Myanmar",
			Namibia:"Namibia",
			Nauru:"Nauru",
			Nepal:"Nepal",
			Netherlands:"Netherlands",
			"New Zealand":"New Zealand",
			Nicaragua:"Nicaragua",
			Niger:"Niger",
			Nigeria:"Nigeria",
			"North Korea":"North Korea",
			"North Macedonia":"North Macedonia",
			Norway:"Norway",
			Oman:"Oman",
			Pakistan:"Pakistan",
			Palau:"Palau",
			Panama:"Panama",
			"Papua New Guinea":"Papua New Guinea",
			Paraguay:"Paraguay",
			Peru:"Peru",
			Philippines:"Philippines",
			Poland:"Poland",
			Portugal:"Portugal",
			Qatar:"Qatar",
			Romania:"Romania",
			Russia:"Russia",
			Rwanda:"Rwanda",
			"Saint Kitts and Nevis":"Saint Kitts and Nevis",
			"Saint Lucia":"Saint Lucia",
			"Saint Vincent and the Grenadines":"Saint Vincent and the Grenadines",
			Samoa:"Samoa",
			"San Marino":"San Marino",
			"Sao Tome and Principe":"Sao Tome and Principe",
			"Saudi Arabia":"Saudi Arabia",
			Senegal:"Senegal",
			Serbia:"Serbia",
			Seychelles:"Seychelles",
			"Sierra Leone":"Sierra Leone",
			Singapore:"Singapore",
			Slovakia:"Slovakia",
			Slovenia:"Slovenia",
			"Solomon Islands":"Solomon Islands",
			Somalia:"Somalia",
			"South Africa":"South Africa",
			"South Korea":"South Korea",
			"South Sudan":"South Sudan",
			Spain:"Spain",
			"Sri Lanka":"Sri Lanka",
			Sudan:"Sudan",
			Suriname:"Suriname",
			Sweden:"Sweden",
			Switzerland:"Switzerland",
			Syria:"Syria",
			Tajikistan:"Tajikistan",
			Tanzania:"Tanzania",
			Thailand:"Thailand",
			"Timor-Leste":"Timor-Leste",
			Togo:"Togo",
			Tonga:"Tonga",
			"Trinidad and Tobago":"Trinidad and Tobago",
			Tunisia:"Tunisia",
			Turkey:"Turkey",
			Turkmenistan:"Turkmenistan",
			Tuvalu:"Tuvalu",
			Uganda:"Uganda",
			Ukraine:"Ukraine",
			"United Arab Emirates":"United Arab Emirates",
			"United Kingdom":"United Kingdom",
			"United States of America":"United States of America",
			Uruguay:"Uruguay",
			Uzbekistan:"Uzbekistan",
			Vanuatu:"Vanuatu",
			Venezuela:"Venezuela",
			Vietnam:"Vietnam",
			Yemen:"Yemen",
			Zambia:"Zambia",
			Zimbabwe:"Zimbabwe",
		},

        muhurtaTypes: {
            select: "select",
            vastukharedi: "Vastu Kharedi/Land Purchase",
            bhoomi: "Bhoomi Pujan/Land Puja",
            gruhapravesh: "Gruha Pravesh/House warming ceremony",
            vastushanti: "Vastu Shanti",
            saakharpuda: "Saakhar Puda/Engagement ceremony",
            marriage: "Marriage",
            garbhadhan: "Garbha Dhan/Conceiving",
            dohaal: "Dohaal Jevan/Baby shower",
            delivery: "Normal/Cesarean",
            jananshanti: "Janan Shanti",
            baarsa: "Baarsa",
            jawal: "Jaawal",
            naamkaran: "Naamkaran/Naming ceremony",
            kaantochne: "Kaan Tochne/Ear piercing",
            annaprashan: "Anna Prashan/Rice-feeding ceremony/Grain initiation",
            upanayan: "Upanayan for male",
            rutumati: "Rutumati for female",
            gold: "Gold Kharedi/Gold Purchase",
            silver: "Silver Kharedi/Silver Purchase",
            vehical: "Vehical Kharedi/Vehical Purchase",
            machine: "Machine Kharedi/Machine Purchase",
            perni: "Perni/Seed Sowing",
            vyaparstart: "Vyapar Udghatan/business Inauguration",
            osudghatan: "Office/Shop Inauguration",
            journey: "Pravaas Muhurta/Journey Muhurta",
            kalrahu: "KaalRahu Muhurta",
            tarabal: "Tarabal",
            nakshatrapaya: "Nakshatra Paya",
            borewell: "New Borewell",
            animal: "Animal Purchase/sell",
            tillering: "Tillering",
            cropping: "Cropping",
            door: "Door Muhurta",
        }
    };

    let [activeMuhurtaType, SetActiveMuhurtaType] = useState(MuhurtaHelperHandler.muhurtaTypes.select);
	   
    let [garbhadhanFemaleDefaultCountry, SetGarbhadhanFemaleDefaultCountry] = useState(MuhurtaHelperHandler.countrys.India);
    let [garbhadhanMaleDefaultCountry, SetGarbhadhanMaleDefaultCountry] = useState(MuhurtaHelperHandler.countrys.India);
	
	let [oneForAllMuhurtaDefaultCountry, SetOneForAllMuhurtaDefaultCountry] = useState(MuhurtaHelperHandler.countrys.India);

    function handleGarbhadhanFemaleCountry(e){
        SetGarbhadhanFemaleDefaultCountry(e);
		let price = muhurtaFeeSelector;

		if(e !== MuhurtaHelperHandler.countrys.India && !garbhaOutsideIndia){
			SetGarbhaOutsideIndia(true);

			if(outsideIndia || maleGarbhaOutsideIndia){
				price /= 10;
				price *= 10;
				
				SetOutsideIndia(false);
				SetOneForAllMuhurtaDefaultCountry(MuhurtaHelperHandler.countrys.India);
				muhurtaDispatcher(muhurtaFee(price += 0));
			}else if(!outsideIndia && !maleGarbhaOutsideIndia){
				muhurtaDispatcher(muhurtaFee(price *= 10));
			}

		}else if(e === MuhurtaHelperHandler.countrys.India && garbhaOutsideIndia){

			SetGarbhaOutsideIndia(false);

			if(!maleGarbhaOutsideIndia){
				muhurtaDispatcher(muhurtaFee(price /= 10));
			}
		}
    }
    function handleGarbhadhanMaleCountry(e){
        SetGarbhadhanMaleDefaultCountry(e);
		let price = muhurtaFeeSelector;

		if(e !== MuhurtaHelperHandler.countrys.India && !maleGarbhaOutsideIndia){
			SetMaleGarbhaOutsideIndia(true);

			if(outsideIndia || garbhaOutsideIndia){
				price /= 10;
				price *= 10;

				SetOutsideIndia(false);
				SetOneForAllMuhurtaDefaultCountry(MuhurtaHelperHandler.countrys.India);
				muhurtaDispatcher(muhurtaFee(price += 0));
			}else if(!outsideIndia && !garbhaOutsideIndia){
				muhurtaDispatcher(muhurtaFee(price *= 10));
			}

		}else if(e === MuhurtaHelperHandler.countrys.India){
			SetMaleGarbhaOutsideIndia(false);
			if(!garbhaOutsideIndia){
				muhurtaDispatcher(muhurtaFee(price /= 10));
			}
		}
    }

	function handleOneForAllMuhurtaDefaultCountry(e){
		SetOneForAllMuhurtaDefaultCountry(e);

		let price = muhurtaFeeSelector;
	
		if(e !== MuhurtaHelperHandler.countrys.India && !outsideIndia){
			SetOutsideIndia(true);
			if(garbhaOutsideIndia || maleGarbhaOutsideIndia){
				price /= 10;
				price *= 10;
				SetGarbhaOutsideIndia(false);
				SetGarbhadhanFemaleDefaultCountry(MuhurtaHelperHandler.countrys.India);
				SetMaleGarbhaOutsideIndia(false);
				SetGarbhadhanMaleDefaultCountry(MuhurtaHelperHandler.countrys.India);
				muhurtaDispatcher(muhurtaFee(price));
			}else if(!garbhaOutsideIndia && !maleGarbhaOutsideIndia){
				muhurtaDispatcher(muhurtaFee(price *= 10));
			}
		}else if(e === MuhurtaHelperHandler.countrys.India && outsideIndia){
			SetOutsideIndia(false);

			if(!garbhaOutsideIndia && !maleGarbhaOutsideIndia){
				muhurtaDispatcher(muhurtaFee(price /= 10));
			}
		}
	}

	function getNumber(number){

		let phonenumber, code;
		if (number === "") {
			return false;
		}
		let positionOfDash = number.indexOf("-");
		let lastPositionOfDash = number.lastIndexOf("-");
		if (positionOfDash === -1) {
			return false;
		}
		if (positionOfDash !== lastPositionOfDash) {
			return false;
		}
		let MM = number.substring(0, positionOfDash);
		let parsedMM = parseInt(MM);
		if (isNaN(parsedMM) || MM.length > 4 || MM.length < 1 || parsedMM === 0) {
			return false;
		}
		let actualNumberOfRequester = number.substring(positionOfDash + 1, number.length);
		let actualNumber = parseInt(actualNumberOfRequester);
		if (isNaN(actualNumber) || actualNumberOfRequester.length > 10) {
			return false;
		}
		let indian = requesterInsideIndia;
		indian = parsedMM !== 91 ? false : true;
		code = parsedMM;
		phonenumber = actualNumber;
		let numberObj = {
			phoneCode: code,
			pnumber: phonenumber,
			phoneCountry: indian,
		};
		return numberObj;
	}

	function phonenumberValidator(phone){

		let matchNumberWithOutCode = getNumber(phone);
		let {pnumber} = matchNumberWithOutCode;

		if (isBoolean(matchNumberWithOutCode)) {
			return {
				...matchNumberWithOutCode,
				status: false,
			};
		}
		if (requesterInsideIndia && pnumber.toString().length < 10) {
			return {
				...matchNumberWithOutCode,
				status: false,
			};
		}
		if (!requesterInsideIndia && pnumber.toString().length < 10) {
			return {
				...matchNumberWithOutCode,
				status: false,
			};
		}
		return {
			...matchNumberWithOutCode,
			status: true,
		};
	}

	function oneForAllLabelHandler(muhurtaType){
		
		switch(muhurtaType){

			case MuhurtaHelperHandler.muhurtaTypes.vastukharedi:
			case MuhurtaHelperHandler.muhurtaTypes.vastushanti:
			case MuhurtaHelperHandler.muhurtaTypes.bhoomi:
				SetOneForAllNameLabel("Owner Name");
				SetOneForAllDateLabel("Land Owner Birth Date");
				SetOneForAllTimeLabel("Land Owner Birth Time");
				SetOneForAllPlaceLabel("Land Owner Birth Place");
				SetOneForAllCountryLabel("Land Owner Birth Country");
				if(muhurtaType === MuhurtaHelperHandler.muhurtaTypes.vastukharedi){
					SetOneForAllHeader(`${MuhurtaHelperHandler.muhurtaTypes.vastukharedi} Muhurta`);
				}else if(muhurtaType === MuhurtaHelperHandler.muhurtaTypes.vastushanti){
					SetOneForAllHeader(`${MuhurtaHelperHandler.muhurtaTypes.vastushanti} Muhurta`);
				}else if(muhurtaType === MuhurtaHelperHandler.muhurtaTypes.bhoomi){
					SetOneForAllHeader(`${MuhurtaHelperHandler.muhurtaTypes.bhoomi} Muhurta`);
				}
				break;

			case MuhurtaHelperHandler.muhurtaTypes.upanayan:
				SetOneForAllNameLabel("Male Name");
				SetOneForAllDateLabel("Male Birth Date");
				SetOneForAllTimeLabel("Male Birth Time");
				SetOneForAllPlaceLabel("Male Birth Place");
				SetOneForAllCountryLabel("Male Birth Country");
				SetOneForAllHeader("Upanayan Muhurta");
				break;

			case MuhurtaHelperHandler.muhurtaTypes.rutumati:
			case MuhurtaHelperHandler.muhurtaTypes.delivery:
				SetOneForAllNameLabel("Female Name");
				SetOneForAllDateLabel("Female Birth Date");
				SetOneForAllTimeLabel("Female Birth Time");
				SetOneForAllPlaceLabel("Female Birth Place");
				SetOneForAllCountryLabel("Female Birth Country");
				if(muhurtaType === MuhurtaHelperHandler.muhurtaTypes.rutumati){
					SetOneForAllHeader("Rutumati Muhurta");
				}else if(muhurtaType === MuhurtaHelperHandler.muhurtaTypes.delivery){
					SetOneForAllHeader("Delivery Muhurta");
				}
				break;

			case MuhurtaHelperHandler.muhurtaTypes.vehical:
			case MuhurtaHelperHandler.muhurtaTypes.machine:
				SetOneForAllNameLabel("Owner Name");
				SetOneForAllDateLabel("Owner Birth Date");
				SetOneForAllTimeLabel("Owner Birth Time");
				SetOneForAllPlaceLabel("Owner Birth Place");
				SetOneForAllCountryLabel("Owner Birth Country");
				if(muhurtaType === MuhurtaHelperHandler.muhurtaTypes.vehical){
					SetOneForAllHeader("Vehical Kharedi Muhurta");
				}else if(muhurtaType === MuhurtaHelperHandler.muhurtaTypes.machine){
					SetOneForAllHeader("Machine Kharedi Muhurta");
				}
				break;


			default:
				SetOneForAllNameLabel("Name");
				SetOneForAllDateLabel("Birth Date");
				SetOneForAllTimeLabel("Birth Time");
				SetOneForAllPlaceLabel("Birth Place");
				SetOneForAllCountryLabel("Birth Country");
				SetOneForAllHeader(null);
		}
	}

	function formFieldVisibilityHandler(muhurtaType){

		switch(muhurtaType){

			case MuhurtaHelperHandler.muhurtaTypes.garbhadhan:
				SetOneForAllMuhurtaFormFieldsClsName("oneForAllMuhurtaFormField");
				break;

			case MuhurtaHelperHandler.muhurtaTypes.vastukharedi:
			case MuhurtaHelperHandler.muhurtaTypes.bhoomi:
			case MuhurtaHelperHandler.muhurtaTypes.vastushanti:
			case MuhurtaHelperHandler.muhurtaTypes.upanayan:
			case MuhurtaHelperHandler.muhurtaTypes.rutumati:
			case MuhurtaHelperHandler.muhurtaTypes.delivery:
			case MuhurtaHelperHandler.muhurtaTypes.vehical:
			case MuhurtaHelperHandler.muhurtaTypes.machine:
				SetGarbhadhanFormFieldClsName("kalniyojanMuhurtaGarbhaDhanField");
				break;

			default:
                SetGarbhadhanFormFieldClsName("kalniyojanMuhurtaGarbhaDhanField");
				SetOneForAllMuhurtaFormFieldsClsName("oneForAllMuhurtaFormField");
				break;

		}
	}

    function handleMuhurtaType(e){
        SetActiveMuhurtaType(e);

        switch(e){

            case MuhurtaHelperHandler.muhurtaTypes.garbhadhan:
                SetGarbhadhanFormFieldClsName("kalniyojanMuhurtaGarbhaDhanFields");
				formFieldVisibilityHandler(e);
                break;

			case MuhurtaHelperHandler.muhurtaTypes.vastukharedi:
			case MuhurtaHelperHandler.muhurtaTypes.vastushanti:
			case MuhurtaHelperHandler.muhurtaTypes.upanayan:
			case MuhurtaHelperHandler.muhurtaTypes.bhoomi:
			case MuhurtaHelperHandler.muhurtaTypes.rutumati:
			case MuhurtaHelperHandler.muhurtaTypes.delivery:
			case MuhurtaHelperHandler.muhurtaTypes.vehical:
			case MuhurtaHelperHandler.muhurtaTypes.machine:
				SetOneForAllMuhurtaFormFieldsClsName("oneForAllMuhurtaFormFields");
				formFieldVisibilityHandler(e);
				oneForAllLabelHandler(e);
				break;

            default:
				formFieldVisibilityHandler(e);
        }
    }

	async function getMuhurtaConfig(){

		let muhurtaPayConfig = null;

		try{
			muhurtaPayConfig = await axios({
				url: kapi.orderconfig,
				method: kapi.get,
				withCredentials: true,
			});
		}catch(error){
			SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaPaymentError("failed to fetch service data try again or later"));
			setTimeout(() => {
				SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShower");
				muhurtaDispatcher(resetMuhurtaPaymentError());
			}, 8000);
		}

		return muhurtaPayConfig;

	}

	async function handleMuhurtaBillPaymentComplete(muhurta_bill_payment_complete_response){

		let upload_muhurta = null;

		if(muhurta_bill_payment_complete_response.status === 200){
			upload_muhurta = await uploadClientMuhurta({
				active: activeMuhurtaType,
				normal: normalMuhurtaFormData.current.data,
				garbha: garbhaDhanMuhurtaFormData.current.data,
				all: oneForAllFormData.current.data,
				muhurtaTypes: MuhurtaHelperHandler.muhurtaTypes,
			});
		}

		if(upload_muhurta.success){
            setTimeout(() => {
                window.location.href = "/servicessuccess";
            }, 1000);
		}else{
			SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaPaymentError(upload_muhurta.message));
			setTimeout(() => {
				SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShower");
				muhurtaDispatcher(resetMuhurtaPaymentError());
			}, 8000);
			window.location.reload();
		}
	}

	function prepareMuhurtaBillSdk(muhurta_bill_orderdata){

        let {bdorderid,token,authorization} = muhurta_bill_orderdata;

		let muhurtaBillSdkConfig = getBillSdkConfig(bdorderid,token,authorization)

		muhurtaBillSdkConfig.responseHandler = handleMuhurtaBillPaymentComplete;

        window.loadBillDeskSdk(muhurtaBillSdkConfig);
	}

	async function getMuhurtaBOrderId(){

		let muhurtaBorderFormData = new FormData();
		muhurtaBorderFormData.append("amount",muhurtaFeeSelector);
		muhurtaBorderFormData.append("currency","inr");
		muhurtaBorderFormData.append("clientip",await getClientIp());
		muhurtaBorderFormData.append("useragent",window.navigator.userAgent);

		axios({
			url: kapi.borderidapi,
			method: kapi.post,
			data: muhurtaBorderFormData,
			headers:{
				"Content-Type": "application/json",
			},
			withCredentials: true,
		}).then(result => {
			if(result.status !== 200){
				throw new Error({message: "order creation failed"});
			}else{
				SetMuhurtaPaymentOptionsWindowCls("muhurtaPaymentOptionsWindow");
				prepareMuhurtaBillSdk(result.data.data);
			}
		}).catch(error => {
			SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShowerSelected");
			if(error.response !== undefined){
				muhurtaDispatcher(setMuhurtaPaymentError(error.response.data.message));
				setTimeout(() => {
					SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShower");
					muhurtaDispatcher(resetMuhurtaPaymentError());
				}, 8000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
			}else if(error instanceof Object){
				muhurtaDispatcher(setMuhurtaPaymentError(error.message));
				setTimeout(() => {
					SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShower");
					muhurtaDispatcher(resetMuhurtaPaymentError());
				}, 8000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
			}else{
				muhurtaDispatcher(setMuhurtaPaymentError("something is wrong may be server is down try later"));
				setTimeout(() => {
					SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShower");
					muhurtaDispatcher(resetMuhurtaPaymentError());
				}, 8000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
			}
		});
	}

	let muhurtaPaymentOptionHandlerObj = {
		handlePaymentOptionOne: function(){
			
			setTimeout(() => {
			 	getMuhurtaOrderId();
			}, 1000);
			SetMuhurtaPaymentOptionsWindowCls("muhurtaPaymentOptionsWindow");
			setTimeout(() => {
				SetMuhurtaPaymentWindowCls("muhurtaPaymentWindowSelected");
			}, 200);

		},

		handlePaymentOptionTwo: function(){
			getMuhurtaBOrderId();
			SetMuhurtaPaymentOptionsWindowCls("muhurtaPaymentOptionsWindow");
		}
	}

	function getMuhurtaOrderId(){
		let muhurtaOrderForm = new FormData();
		muhurtaOrderForm.append("amount",muhurtaFeeSelector);
		muhurtaOrderForm.append("currency","inr");

		axios({
			url: kapi.orderidapi,
			method: kapi.post,
			data: muhurtaOrderForm,
			headers: {
				"Content-Type": "application/json",
			},
			withCredentials: true,
		}).then(async result => {
			if(result.status !== 200){
				throw new Error({
					message: "failed to create order try again/later",
				});
			}
			muhurtaOrderId.current.orderid = result.data.data;
			let muhurtaConfig = null;
			muhurtaConfig = await getMuhurtaConfig().then(result => {
				if(result.status === 200){
					muhurtaStripePub.current.key = result.data.data;
					return result.data.data;
				}
				if(muhurtaConfig === null){
					throw new Error({
						message: "failed to create order try again or later",
					});
				}
			}).catch(error => {
				SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShowerSelected");
				muhurtaDispatcher(setMuhurtaPaymentError(error.message));
				setTimeout(() => {
					SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShower");
					muhurtaDispatcher(resetMuhurtaPaymentError());
				}, 8000);
			}).catch(error => {
				SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShowerSelected");
				muhurtaDispatcher(setMuhurtaPaymentError("failed to create order try again or later"));
				setTimeout(() => {
					SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShower");
					muhurtaDispatcher(resetMuhurtaPaymentError());
				}, 8000);
			});
			SetStripeMuhurtaPromise(loadStripe(muhurtaConfig));
		}).catch(error => {
			if(error.response !== undefined){
				let muhurtaOrderRespone = error.response;
				SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShowerSelected");
				muhurtaDispatcher(setMuhurtaPaymentError(muhurtaOrderRespone.data.message));
				setTimeout(() => {
					SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShower");
					muhurtaDispatcher(resetMuhurtaPaymentError());
				}, 8000);
			}else{
				SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShowerSelected");
				muhurtaDispatcher(setMuhurtaPaymentError("failed to fetch order data try again or later"));
				setTimeout(() => {
					SetMuhurtaPaymentErrorShower("muhurtaPaymentErrorShower");
					muhurtaDispatcher(resetMuhurtaPaymentError());
				}, 8000);
			}
		});
	}

	async function normalMuhurtaSubmitForm(submitData){

		normalMuhurtaFormData.current.data = submitData;
		SetMuhurtaPaymentOptionsWindowCls("muhurtaPaymentOptionsWindowSelected");
	}

	async function submitGarbhadhanMuhurtaForm(submitData){

		garbhaDhanMuhurtaFormData.current.data = submitData;
		SetMuhurtaPaymentOptionsWindowCls("muhurtaPaymentOptionsWindowSelected");
	}

	function submitOneForAllMuhurtaForm(submitData){

		oneForAllFormData.current.data = submitData;
		SetMuhurtaPaymentOptionsWindowCls("muhurtaPaymentOptionsWindowSelected");
	}


	function validateNormalMuhurta(){
		if(muhurtaClientNameRef.current.value === null || !nameValidator(muhurtaClientNameRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid client name atleast 5 character required"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(muhurtaClientEmailRef.current.value === null || !emailValidator(muhurtaClientEmailRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid client email"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(muhurtaClientPhoneRef.current.value === null || !phonenumberValidator(muhurtaClientPhoneRef.current.value).status){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid client phone number"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(!muhurtaValidator(activeMuhurtaType)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid muhurta"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(mu_star === 0){
			
			let phoneDetails = phonenumberValidator(muhurtaClientPhoneRef.current.value);

			let normalMuhurtaForm = new FormData();
			normalMuhurtaForm.append("clientName",muhurtaClientNameRef.current.value);
			normalMuhurtaForm.append("clientEmail",muhurtaClientEmailRef.current.value);
			normalMuhurtaForm.append("clientPhone",phoneDetails.pnumber);
			normalMuhurtaForm.append("clientPhoneCode",phoneDetails.phoneCode);
			normalMuhurtaForm.append("muhurtaFor",activeMuhurtaType);

			setTimeout(() => {
				normalMuhurtaSubmitForm(normalMuhurtaForm);
			}, 1000);
		}

	}

	function validateMuhurtaFormForGarbhadhan(){
		if(muhurtaClientNameRef.current.value === null || !nameValidator(muhurtaClientNameRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid client name atleast 5 character required"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(muhurtaClientEmailRef.current.value === null || !emailValidator(muhurtaClientEmailRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid client email"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(muhurtaClientPhoneRef.current.value === null || !phonenumberValidator(muhurtaClientPhoneRef.current.value).status){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid client phone number"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(!muhurtaValidator(activeMuhurtaType)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid muhurta"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}


		if(garbhadhanFemaleNameRef.current.value === null || !nameValidator(garbhadhanFemaleNameRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid female name atleast 5 character required"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(garbhadhanMaleNameRef.current.value === null || !nameValidator(garbhadhanMaleNameRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid male name atleast 5 character required"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}
		if(garbhadhanFemaleBirthDateRef.current.value === null || !dateValidator(garbhadhanFemaleBirthDateRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid female birth date"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}
		if(garbhadhanMaleBirthDateRef.current.value === null || !dateValidator(garbhadhanMaleBirthDateRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid male birth date"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}
		if(garbhadhanFemaleBirthTimeRef.current.value === null || !timeValidator(garbhadhanFemaleBirthTimeRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid female birth time"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}
		if(garbhadhanMaleBirthTimeRef.current.value === null || !timeValidator(garbhadhanMaleBirthTimeRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid male birth time"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(garbhadhanFemaleBirthPlaceRef.current.value === null || !placeValidator(garbhadhanFemaleBirthPlaceRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid female birth place"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(garbhadhanMaleBirthPlaceRef.current.value === null || !placeValidator(garbhadhanMaleBirthPlaceRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid male birth place"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(garbhadhanFemaleDefaultCountry === null || !countryValidator(garbhadhanFemaleDefaultCountry)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid female birth country"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(garbhadhanMaleDefaultCountry === null || !countryValidator(garbhadhanMaleDefaultCountry)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid male birth country"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(mu_star === 0){
			let phoneDetails = phonenumberValidator(muhurtaClientPhoneRef.current.value);

			let muhurtagarbhadhanformdata = new FormData();
			muhurtagarbhadhanformdata.append("clientName",muhurtaClientNameRef.current.value);
			muhurtagarbhadhanformdata.append("clientEmail",muhurtaClientEmailRef.current.value);
			muhurtagarbhadhanformdata.append("clientPhone",phoneDetails.pnumber);
			muhurtagarbhadhanformdata.append("clientPhoneCode",phoneDetails.phoneCode);
			muhurtagarbhadhanformdata.append("muhurtaFor",activeMuhurtaType);
			muhurtagarbhadhanformdata.append("femaleName",garbhadhanFemaleNameRef.current.value);
			muhurtagarbhadhanformdata.append("maleName",garbhadhanMaleNameRef.current.value);
			muhurtagarbhadhanformdata.append("femaleBirthDate",garbhadhanFemaleBirthDateRef.current.value);
			muhurtagarbhadhanformdata.append("maleBirthDate",garbhadhanMaleBirthDateRef.current.value);
			muhurtagarbhadhanformdata.append("femaleBirthTime",garbhadhanFemaleBirthTimeRef.current.value);
			muhurtagarbhadhanformdata.append("maleBirthTime",garbhadhanMaleBirthTimeRef.current.value);
			muhurtagarbhadhanformdata.append("femaleBirthPlace",garbhadhanFemaleBirthPlaceRef.current.value);
			muhurtagarbhadhanformdata.append("maleBirthPlace",garbhadhanMaleBirthPlaceRef.current.value);
			muhurtagarbhadhanformdata.append("femaleBirthCountry",garbhadhanFemaleDefaultCountry);
			muhurtagarbhadhanformdata.append("maleBirthCountry",garbhadhanMaleDefaultCountry);

			setTimeout(() => {
				submitGarbhadhanMuhurtaForm(muhurtagarbhadhanformdata);
			}, 1000);
		}
	}

	function validateMuhurtaFormForAll(){
		if(oneForAllNameRef.current.value === null || !nameValidator(oneForAllNameRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid name atleast 5 character required"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(muhurtaClientNameRef.current.value === null || !nameValidator(muhurtaClientNameRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid client name atleast 5 character required"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(muhurtaClientEmailRef.current.value === null || !emailValidator(muhurtaClientEmailRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid client email"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(muhurtaClientPhoneRef.current.value === null || !phonenumberValidator(muhurtaClientPhoneRef.current.value).status){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid client phone number"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(!muhurtaValidator(activeMuhurtaType)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid muhurta"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(oneForAllBirthDateRef.current.value === null || !dateValidator(oneForAllBirthDateRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid date"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(oneForAllBirthTimeRef.current.value === null || !timeValidator(oneForAllBirthTimeRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid time"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(oneForAllBirthPlaceRef.current.value === null || !placeValidator(oneForAllBirthPlaceRef.current.value)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid place"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(oneForAllMuhurtaDefaultCountry === null || !countryValidator(oneForAllMuhurtaDefaultCountry)){
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("invalid country"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);
			mu_star++;
		}

		if(mu_star === 0){

			let phoneDetails = phonenumberValidator(muhurtaClientPhoneRef.current.value);

			let oneforallformdata = new FormData();
			oneforallformdata.append("clientName",muhurtaClientNameRef.current.value);
			oneforallformdata.append("clientEmail",muhurtaClientEmailRef.current.value);
			oneforallformdata.append("clientPhone",phoneDetails.pnumber);
			oneforallformdata.append("clientPhoneCode",phoneDetails.phoneCode);
			oneforallformdata.append("muhurtaFor",activeMuhurtaType);
			oneforallformdata.append("name",oneForAllNameRef.current.value);
			oneforallformdata.append("date",oneForAllBirthDateRef.current.value);
			oneforallformdata.append("time",oneForAllBirthTimeRef.current.value);
			oneforallformdata.append("place",oneForAllBirthPlaceRef.current.value);
			oneforallformdata.append("country",oneForAllMuhurtaDefaultCountry);

			setTimeout(() => {
				submitOneForAllMuhurtaForm(oneforallformdata);
			}, 1000);
		}
	}

	function validateAndSubmitMuhurtaForm(muhurtaType){

		switch(muhurtaType){

			case MuhurtaHelperHandler.muhurtaTypes.vastukharedi:
			case MuhurtaHelperHandler.muhurtaTypes.vastushanti:
			case MuhurtaHelperHandler.muhurtaTypes.upanayan:
			case MuhurtaHelperHandler.muhurtaTypes.bhoomi:
			case MuhurtaHelperHandler.muhurtaTypes.rutumati:
			case MuhurtaHelperHandler.muhurtaTypes.delivery:
			case MuhurtaHelperHandler.muhurtaTypes.vehical:
			case MuhurtaHelperHandler.muhurtaTypes.machine:
				validateMuhurtaFormForAll();
				break;

			case MuhurtaHelperHandler.muhurtaTypes.garbhadhan:
				validateMuhurtaFormForGarbhadhan();
				break;

			case MuhurtaHelperHandler.muhurtaTypes.gruhapravesh:
			case MuhurtaHelperHandler.muhurtaTypes.saakharpuda:
			case MuhurtaHelperHandler.muhurtaTypes.marriage:
			case MuhurtaHelperHandler.muhurtaTypes.dohaal:
			case MuhurtaHelperHandler.muhurtaTypes.jananshanti:
			case MuhurtaHelperHandler.muhurtaTypes.baarsa:
			case MuhurtaHelperHandler.muhurtaTypes.jawal:
			case MuhurtaHelperHandler.muhurtaTypes.naamkaran:
			case MuhurtaHelperHandler.muhurtaTypes.kaantochne:
			case MuhurtaHelperHandler.muhurtaTypes.annaprashan:
			case MuhurtaHelperHandler.muhurtaTypes.gold:
			case MuhurtaHelperHandler.muhurtaTypes.silver:
			case MuhurtaHelperHandler.muhurtaTypes.perni:
			case MuhurtaHelperHandler.muhurtaTypes.vyaparstart:
			case MuhurtaHelperHandler.muhurtaTypes.osudghatan:
			case MuhurtaHelperHandler.muhurtaTypes.journey:
			case MuhurtaHelperHandler.muhurtaTypes.kalrahu:
			case MuhurtaHelperHandler.muhurtaTypes.tarabal:
			case MuhurtaHelperHandler.muhurtaTypes.nakshatrapaya:
			case MuhurtaHelperHandler.muhurtaTypes.borewell:
			case MuhurtaHelperHandler.muhurtaTypes.animal:
			case MuhurtaHelperHandler.muhurtaTypes.tillering:
			case MuhurtaHelperHandler.muhurtaTypes.cropping:
			case MuhurtaHelperHandler.muhurtaTypes.door:
				validateNormalMuhurta();
				break;

			default:
			SetMuhurtaErrorShower("muhurtaErrorShowerSelected");
			muhurtaDispatcher(setMuhurtaError("please select muhurta and try again"));
			window.scrollTo(0,0);
			setTimeout(() => {
				SetMuhurtaErrorShower("muhurtaErrorShower");
				muhurtaDispatcher(resetMuhurtaError());
			}, 10000);


		}
	}

	function handleMuhurtaForm(event){
		event.preventDefault();

		validateAndSubmitMuhurtaForm(activeMuhurtaType);
	}

    return(
    
        <div className="kalniyojanMuhurtaContainer">
            <div className="kalniyojanMuhurtaContainerContent">
                <h1>Muhurta form</h1>

				<div className={muhurtaErrorShower}>
					{Array.from(muhurtaErrorSelector).map((item,index) => 
						<div key={index}>{item}</div>
					)}
				</div>

				<div className={muhurtaPaymentErrorShower}>
					{Array.from(muhurtaPaymentErrorSelector).map((item,index) => 
						<div key={index}>{item}</div>
					)}
				</div>

				<div className="muhurtaFeeIndicator">
					<div>Fees</div>
					<div id="muhurtaFee">{muhurtaFeeSelector} INR</div>
				</div>

                <form onSubmit={event => handleMuhurtaForm(event)}>
                    <div className="muhurtaClientDetailsRow">
                        <div className="muhurtaClientNameRow">
                            <label htmlFor="clientName"><span>*</span> Client Name</label>
                            <input type='text' className="muhurtaClientName" title="client name" ref={muhurtaClientNameRef}/>
                        </div>
                        <div className="muhurtaClientEmailRow">
                            <label htmlFor="clientEmail"><span>*</span> Client Email</label>
                            <input type='text' className="muhurtaClientEmail" title="client email" ref={muhurtaClientEmailRef}/>
                        </div>
                        <div className="muhurtaClientPhoneRow">
                            <label htmlFor="clientPhone"><span>*</span> Client Phone</label>
                            <input type='text' className="muhurtaClientPhone" title="NOTE: use this format (country code - phonenumber)(eg: 91-0123456789)" ref={muhurtaClientPhoneRef}/>
							<p className="janmaPatrikaPhoneFormatNote">
								NOTE: use format(country code - phonenumber)(eg: 91-0123456789)
							</p>
                        </div>
                        <div className="muhurtaForRow">
                            <label htmlFor="muhurtaFor"><span>*</span> Muhurta for</label>
                            <select value={activeMuhurtaType} onChange={e => handleMuhurtaType(e.target.value)}>
                                {Array.from(Object.values(MuhurtaHelperHandler.muhurtaTypes)).map((item,index) => 
                                    <option key={index}>{item}</option>
                                )}
                            </select>
                        </div>

                    </div>

					<div className={oneForAllMuhurtaFormFieldsClsName}>
							
						<h3>{oneForAllHeader}</h3>
						<div className="oneForAllMuhurtaRow">
							
                            <div className="oneForAllMuhurtaNameRow">
                                <label htmlFor="oneForAllMuhurtaName"><span>*</span>{oneForAllNameLabel}</label>
                                <input type="text" className="oneForAllName" title="name" ref={oneForAllNameRef} />
                            </div>

                            <div className="oneForAllMuhurtaBirthDateRow">
                                <label htmlFor="oneForAllMuhurtaBirthDate"><span>*</span>{oneForAllDateLabel}</label>
                                <input type='date' className="oneForAllBirthDate" title="birth date" ref={oneForAllBirthDateRef} />
                            </div>

                            <div className="oneForAllMuhurtaBirthTimeRow">
                                <label htmlFor="oneForAllMuhurtaBirthTime"><span>*</span>{oneForAllTimeLabel}</label>
                                <input type='time' className="oneForAllBirthTime" title="birth time" ref={oneForAllBirthTimeRef} />
                            </div>

                            <div className="oneForAllMuhurtaBirthPlaceRow">
                                <label htmlFor="oneForAllMuhurtaBirthPlace"><span>*</span>{oneForAllPlaceLabel}</label>
                                <input type='text' className="oneForAllBirthPlace" title="birth place" ref={oneForAllBirthPlaceRef} />
                            </div>

                            <div className="oneForAllMuhurtaBirthCountryRow">
                                <label htmlFor="oneForAllMuhurtaBirthCountry"><span>*</span>{oneForAllCountryLabel}</label>
                                <select value={oneForAllMuhurtaDefaultCountry} onChange={e => handleOneForAllMuhurtaDefaultCountry(e.target.value)}>
                                    {Array.from(Object.values(MuhurtaHelperHandler.countrys)).map((item,index) => 
                                        <option key={index}>{item}</option>
                                    )}
                                </select>
                            </div>
						</div>
							
					</div>

                    <div className={garbhadhanFormFieldClsName}>
                        <h3>Garbhadhan</h3>
                        <div className="garbhadhanMaleFemaleDetailsRow">

                            <div className="garbhadhanMuhurtaFemaleNameRow">
                                <label htmlFor="garbhadhanMuhurtaFemaleName"><span>*</span>Female Name</label>
                                <input type="text" className="garbhadhanFemaleName" title="female name" ref={garbhadhanFemaleNameRef} />
                            </div>

                            <div className="garbhadhanMuhurtaMaleNameRow">
                                <label htmlFor="garbhadhanMuhurtaMaleName"><span>*</span>Male Name</label>
                                <input type='text' className="garbhadhanMaleName" title="male name" ref={garbhadhanMaleNameRef} />
                            </div>

                            <div className="garbhadhanMuhurtaFemaleBirthDateRow">
                                <label htmlFor="garbhadhanMuhurtaFemaleBirthDate"><span>*</span>Female Birth Date</label>
                                <input type='date' className="garbhadhanFemaleBirthDate" title="female birth date" ref={garbhadhanFemaleBirthDateRef} />
                            </div>

                            <div className="garbhadhanMuhurtaMaleBirthDateRow">
                                <label htmlFor="garbhadhanMuhurtaMaleBirthDate"><span>*</span>Male Birth Date</label>
                                <input type='date' className="garbhadhanMaleBirthDate" title="male birth date" ref={garbhadhanMaleBirthDateRef} />
                            </div>

                            <div className="garbhadhanMuhurtaFemaleBirthTimeRow">
                                <label htmlFor="garbhadhanMuhurtaFemaleBirthTime"><span>*</span>Female Birth Time</label>
                                <input type='time' className="garbhadhanFemaleBirthTime" title="female birth time" ref={garbhadhanFemaleBirthTimeRef} />
                            </div>

                            <div className="garbhadhanMuhurtaMaleBirthTimeRow">
                                <label htmlFor="garbhadhanMuhurtaMaleBirthTime"><span>*</span>Male Birth Time</label>
                                <input type='time' className="garbhadhanMaleBirthTime" title="male birth time" ref={garbhadhanMaleBirthTimeRef} />
                            </div>

                            <div className="garbhadhanMuhurtaFemaleBirthPlaceRow">
                                <label htmlFor="garbhadhanMuhurtaFemaleBirthPlace"><span>*</span>Female Birth Place</label>
                                <input type='text' className="garbhadhanFemaleBirthPlace" title="female birth place" ref={garbhadhanFemaleBirthPlaceRef} />
                            </div>

                            <div className="garbhadhanMuhurtaMaleBirthPlaceRow">
                                <label htmlFor="garbhadhanMuhurtaMaleBirthPlace"><span>*</span>Male Birth Place</label>
                                <input type='text' className="garbhadhanMaleBirthPlace" title="male birth place" ref={garbhadhanMaleBirthPlaceRef} />
                            </div>
                            
                            <div className="garbhadhanMuhurtaFemaleBirthCountryRow">
                                <label htmlFor="garbhadhanMuhurtaFemaleBirthCountry"><span>*</span>Female Birth Country</label>
                                <select value={garbhadhanFemaleDefaultCountry} onChange={e => handleGarbhadhanFemaleCountry(e.target.value)}>
                                    {Array.from(Object.values(MuhurtaHelperHandler.countrys)).map((item,index) => 
                                        <option key={index}>{item}</option>
                                    )}
                                </select>
                            </div>

                            <div className="garbhadhanMuhurtaMaleBirthCountryRow">
                                <label htmlFor="garbhadhanMuhurtaMaleBirthCountry"><span>*</span>Male Birth Country</label>
                                <select value={garbhadhanMaleDefaultCountry} onChange={e => handleGarbhadhanMaleCountry(e.target.value)}>
                                    {Array.from(Object.values(MuhurtaHelperHandler.countrys)).map((item,index) => 
                                        <option key={index}>{item}</option>
                                    )}
                                </select>
                            </div>

                        </div>
                    </div>

					<div className="kalniyojanMuhurtaSubmitBtn">
						<button type='submit' value="submit" title="submit">Submit</button>
					</div>
                </form>
            </div>
            <div className={muhurtaPaymentOptionsWindowCls}>
                <div className="kalniyojanMuhurtaPaymentOptionOne">
                    <p>select this payment option if you want to pay through (master,visa) credit/debit card</p>
                    <button onClick={muhurtaPaymentOptionHandlerObj.handlePaymentOptionOne}>Select</button>
                </div>
                <div className="kalniyojanMuhurtaPaymentOptionTwo">
                    <p>select this payment option if you want to pay through (netbanking,upi,QR) or (Rupay) credit/debit card</p>
                    <button onClick={muhurtaPaymentOptionHandlerObj.handlePaymentOptionTwo}>Select</button>
                </div>
            </div>
			{muhurtaStripePub.current.key && <div className={muhurtaPaymentWindowCls}>
				<Elements stripe={muhurtaStripePromise} options={getStripeAppearance(muhurtaOrderId.current.orderid)}>
					<KalniyojanCheckout sendData={{serviceName: activeMuhurtaType,
					formD: {normal: normalMuhurtaFormData.current.data,
					garbha: garbhaDhanMuhurtaFormData.current.data,
					forall: oneForAllFormData.current.data},
					muhurtaTypes: MuhurtaHelperHandler.muhurtaTypes}} payAmount={muhurtaFeeSelector} paymentWindow={() => {SetMuhurtaPaymentWindowCls("muhurtaPaymentWindow"); window.location.reload(); }} orderId={muhurtaOrderId.current.orderid} stripePub={muhurtaStripePub.current.key}/>
				</Elements>
			</div>}
        </div>
    );
}

export default Muhurta;