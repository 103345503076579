

import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import "./KalniyojanCheckout.css";
import { useState } from "react";
import axios from "axios";
import kapi from "../../Redux/kalniyojanapi";
import { isBoolean } from "lodash";


export default function KalniyojanCheckout(props){

    const stripe = useStripe();
    const elements = useElements();

    let [isLoading, setIsLoading] = useState(false);
    let [stripePaymentError, SetStripePaymentError] = useState("stripePaymentErrorShower");
    let [stripePaymentErrorMessage, SetStripePaymentErrorMessage] = useState(null);

    let successfulFormCreatedUrl = function(){
        window.location.href = "/servicessuccess";
    }

    let uploadClientAdvice = function(){
        axios({
            method: kapi.post,
            url: kapi.addadviceapi,
            data: props.sendData.formD,
            headers:{
                "Content-Type":"application/json",
            },
            withCredentials: true,
        }).then(result => {
            if(result.status !== 200 || !result.data.data){
                throw new Error(result.data.message);
            }
            successfulFormCreatedUrl();
        }).catch(error => {
            SetStripePaymentError("stripePaymentErrorShowerSelected");
            if(error.data !== undefined){
                if(typeof error.data.data === Boolean && error.data.data === false){
                    SetStripePaymentErrorMessage(error.data.message);
                    setTimeout(() => {
                        SetStripePaymentError("stripePaymentErrorShower");
                        SetStripePaymentErrorMessage(null);
                    }, 10000);
                }
            }else if(error.response !== undefined){
                window.scrollTo(0,0);
                SetStripePaymentErrorMessage(error.response.data.message);
                setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
                    SetStripePaymentErrorMessage(null);
                }, 10000);
            }else{
                SetStripePaymentErrorMessage("something is wrong may be server is down, please try later");
		    	setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
                    SetStripePaymentErrorMessage(null);
		    	}, 10000);
		    }
        });
    }

    let uploadJanmaPatrika = function(){

        axios({
            method: kapi.post,
            url: kapi.addjanmapatrikaapi,
            data: props.sendData.formD,
            headers: {
                "Content-Type":"application/json",
            },
            withCredentials: true,
         }).then(result => {
            if(result.status !== 200){
                throw new Error(result.data.message);
            }
            successfulFormCreatedUrl();
         }).catch(err => {
            SetStripePaymentError("stripePaymentErrorShowerSelected");
            if(err.data !== undefined){
                if(typeof err.data.data === Boolean && err.data.data === false){
                    window.scrollTo(0,0);
                    SetStripePaymentErrorMessage(err.data.message);
                    setTimeout(() => {
                        SetStripePaymentError("stripePaymentErrorShower");
                        SetStripePaymentErrorMessage(null);
                    }, 6000);
                }
            }else if(err.response !== undefined){
                window.scrollTo(0,0);
                SetStripePaymentErrorMessage(err.response.data.message);
                setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
                    SetStripePaymentErrorMessage(null);
                }, 6000);
	   
            }else{
                SetStripePaymentErrorMessage("something went wrong may be server is down, try later");
                setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
                    SetStripePaymentErrorMessage(null);
                }, 10000);
            }
         });
    }

    let uploadMatchMakingForm = function(){
        axios({
            method: kapi.post,
            url: kapi.addmatchmakingapi,
            data: props.sendData.formD,
            headers:{
                "Content-Type":"application/json",
            },
            withCredentials: true,
        }).then(result => {
            if(result.status !== 200 || !result.data.data){
                throw new Error(result.data.message);
            }
            successfulFormCreatedUrl();
        }).catch(error => {
            SetStripePaymentError("stripePaymentErrorShowerSelected");
            if(error.data !== undefined){
                if(typeof error.data.data === Boolean && error.data.data === false){
                    SetStripePaymentErrorMessage(error.data.message);
                    setTimeout(() => {
                        SetStripePaymentError("stripePaymentErrorShower");
                        SetStripePaymentErrorMessage(null);
                    }, 10000);
                }
            }else if(error.response !== undefined){
                window.scrollTo(0,0);
                SetStripePaymentErrorMessage(error.response.data.message);
                setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
                    SetStripePaymentErrorMessage(null);
                }, 10000);
			}else{
                SetStripePaymentErrorMessage("something is wrong may be server is down, please try later");
		    	setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
                    SetStripePaymentErrorMessage(null);
		    	}, 10000);
		    }
        });
    }

    let uploadGarbhaDhanMuhurta = function(){

		axios({
			method: kapi.post,
			url: kapi.garbhadhamuhurtasubmitapi,
			data: props.sendData.formD.garbha,
			headers: {
				"Content-Type": "application/json",
			},
			withCredentials: true,
		}).then(result => {
            if(result.status !== 200){
				throw new Error("error submitting muhurta form");
            }
            successfulFormCreatedUrl();
        }).catch(error => {
            SetStripePaymentError("stripePaymentErrorShowerSelected");
            if(error.data !== undefined){
                if(isBoolean(error.data.data) && error.data.data === false){
                    SetStripePaymentErrorMessage(error.data.message);
                    setTimeout(() => {
                        SetStripePaymentError("stripePaymentErrorShower");
                        SetStripePaymentErrorMessage(null);
                    }, 10000);
                }
            }else if(error.response !== undefined){
                window.scrollTo(0,0);
                SetStripePaymentErrorMessage(error.response.data.message);
                setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
                    SetStripePaymentErrorMessage(null);
                }, 10000);
            }else{
                window.scrollTo(0,0);
                SetStripePaymentErrorMessage("failed to submit form may be server is down");
                setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
                    SetStripePaymentErrorMessage(null);
                }, 10000);
            }
        });
    }

    let uploadOneForAllMuhurta = function(){

		axios({
			method: kapi.post,
			url: kapi.oneforallmuhurtasubmitapi,
			data: props.sendData.formD.forall,
			headers: {
				"Content-Type": "application/json",
			},
			withCredentials: true,
		}).then(result => {
			if(result.status !== 200){
				throw new Error("error submitting muhurta form");
			}
            successfulFormCreatedUrl();
		}).catch(error => {
			if(error.response !== undefined){
				let response = error.response;
                SetStripePaymentError("stripePaymentErrorShowerSelected");
				SetStripePaymentErrorMessage(response.data.message);
				window.scrollTo(0,0);
				setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
				    SetStripePaymentErrorMessage(null);
				}, 10000);
			}else{
                SetStripePaymentError("stripePaymentErrorShowerSelected");
				SetStripePaymentErrorMessage("error sending form, may be server is down try again later!!!");
				window.scrollTo(0,0);
				setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
				    SetStripePaymentErrorMessage(null);
				}, 10000);
			}
		});
    }

    let uploadNormalMuhurta = function(){

		axios({
			method: kapi.post,
			url: kapi.normalmuhurtasubmitapi,
			data: props.sendData.formD.normal,
			headers: {
				"Content-Type": "application/json",
			},
			withCredentials: true,
		}).then(result => {
            if(result.status !== 200){
                throw new Error("error submitting muhurta form");
            }
            successfulFormCreatedUrl();
        }).catch(error => {
            SetStripePaymentError("stripePaymentErrorShowerSelected");
			if(error.response !== undefined){
				let response = error.response;
				SetStripePaymentErrorMessage(response.data.message);
				window.scrollTo(0,0);
				setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
				    SetStripePaymentErrorMessage(null);
				}, 10000);
			}else{
				SetStripePaymentErrorMessage("error sending form, may be server is down try again later!!!");
				window.scrollTo(0,0);
				setTimeout(() => {
                    SetStripePaymentError("stripePaymentErrorShower");
				    SetStripePaymentErrorMessage(null);
				}, 10000);
			}
        });

    }

    async function handleStripePayBtn(){

        if(!props.orderId || props.stripePub === null || !elements || !stripe){
            return;
        }

        setIsLoading(true);

        let {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
            },
            redirect: "if_required",
        });

        if(error){
            SetStripePaymentError("stripePaymentErrorShowerSelected");
            SetStripePaymentErrorMessage(error.message);
            setTimeout(() => {
                SetStripePaymentError("stripePaymentErrorShower");
                SetStripePaymentErrorMessage(null);
            }, 4000);
        }else{
            switch(props.sendData.serviceName){
                case "advice":
                    uploadClientAdvice();
                    break;

                case "patrika":
                    uploadJanmaPatrika();
                    break;

                case "matchMaking":
                    uploadMatchMakingForm();
                    break;

                case props.sendData.muhurtaTypes.garbhadhan:
                    uploadGarbhaDhanMuhurta();
                    break;

			    case props.sendData.muhurtaTypes.vastukharedi:
			    case props.sendData.muhurtaTypes.vastushanti:
			    case props.sendData.muhurtaTypes.upanayan:
			    case props.sendData.muhurtaTypes.bhoomi:
			    case props.sendData.muhurtaTypes.rutumati:
			    case props.sendData.muhurtaTypes.delivery:
			    case props.sendData.muhurtaTypes.vehical:
			    case props.sendData.muhurtaTypes.machine:
                    uploadOneForAllMuhurta();
                    break;

                default:
                    uploadNormalMuhurta();
                    break;
            }
        }

        setIsLoading(false);

    }

    return(
        <div className="kalniyojanCheckoutContainer">
            <div className="kalniyojanCheckoutContainerContent">
                <div className={stripePaymentError} >
                    {stripePaymentErrorMessage}
                </div>
                <PaymentElement id="payment-element" />

                <div className="stripePaymentCancelBtns">
                    <button disabled={isLoading || !props.orderId || props.stripePub === null} className="kalniyojanPaymentBtn" onClick={() => handleStripePayBtn()}>
                        <span id="adviceStripePayBtnContent">
                            {isLoading ? "Loading..." : `Pay ${props.payAmount}RS`}
                        </span>
                    </button>
                    <button className="kalniyojanPaymentCancelBtn" onClick={props.paymentWindow}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}