
import { useRef, useState } from "react";
import "./Feedback.css";
import { useDispatch, useSelector } from "react-redux";
import { resetFeedbackError, setFeedbackError } from "../Redux/Action/ErrorAction";
import { emailValidator, uploadUserFeedback } from "../Helpers/Earth";
import { trim } from "lodash";


export function FeedBack(){

  let f_star = 0;

  let kalniyojanFeedbackEmailRef = useRef(null);
  let kalniyojanFeedbackMessageRef= useRef(null);

  let feedbackErrorSelector = useSelector(state => state.errors.feedback);

  let feedbackDispatcher = useDispatch();

  let [kalniyojanFeedbackErrorShower,SetkalniyojanFeedbackErrorShower] = useState("kalniyojanFeedbackErrorShower");

  let feedbackHandler = {

    handleFeedbackForm: function(e){
      e.preventDefault();

      if(kalniyojanFeedbackEmailRef.current.value === "" || kalniyojanFeedbackEmailRef.current.value === null || !emailValidator(kalniyojanFeedbackEmailRef.current.value)){
        window.scrollTo(0,0);
        SetkalniyojanFeedbackErrorShower("kalniyojanFeedbackErrorShowerSelected");
        feedbackDispatcher(setFeedbackError("email is invalid"));
        setTimeout(() => {
          SetkalniyojanFeedbackErrorShower("kalniyojanFeedbackErrorShower");
          feedbackDispatcher(resetFeedbackError());
        }, 10000);
        f_star++;
      }

      if(kalniyojanFeedbackMessageRef.current.value === "" || kalniyojanFeedbackMessageRef.current.value === null || kalniyojanFeedbackMessageRef.current.value.length > 300){
        window.scrollTo(0,0);
        SetkalniyojanFeedbackErrorShower("kalniyojanFeedbackErrorShowerSelected");
        feedbackDispatcher(setFeedbackError("feedback message is empty or too long only 300 characters are allowed"));
        setTimeout(() => {
          SetkalniyojanFeedbackErrorShower("kalniyojanFeedbackErrorShower");
          feedbackDispatcher(resetFeedbackError());
        }, 10000);
        f_star++;
      }

      if(f_star === 0){

        let feedbackFormData = new FormData();
        feedbackFormData.append("email",kalniyojanFeedbackEmailRef.current.value);
        feedbackFormData.append("message",trim(kalniyojanFeedbackMessageRef.current.value));
        this.uploadFeedback(feedbackFormData);
      }
    },

    uploadFeedback: async function(feedbackData){

      let feedbackUploadResponse = await uploadUserFeedback(feedbackData);

      if(feedbackUploadResponse.success){
        SetkalniyojanFeedbackErrorShower("kalniyojanFeedbackSuccessShowerSelected");
        feedbackDispatcher(setFeedbackError("feedback submitted successfully"));
        kalniyojanFeedbackEmailRef.current.value = null;
        kalniyojanFeedbackMessageRef.current.value = null;
        setTimeout(() => {
          SetkalniyojanFeedbackErrorShower("kalniyojanFeedbackErrorShower");
          feedbackDispatcher(resetFeedbackError());
        }, 9000);
        setTimeout(() => {
			    window.location.reload();
        }, 4000);
      }else{
        SetkalniyojanFeedbackErrorShower("kalniyojanFeedbackErrorShowerSelected");
        feedbackDispatcher(setFeedbackError(feedbackUploadResponse.message));
        kalniyojanFeedbackEmailRef.current.value = null;
        kalniyojanFeedbackMessageRef.current.value = null;
        setTimeout(() => {
          SetkalniyojanFeedbackErrorShower("kalniyojanFeedbackErrorShower");
          feedbackDispatcher(resetFeedbackError());
        }, 9000);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }
    }
  }

  return(
    <div className="kalniyojanFeedbackContainer">
      <div className="kalniyojanFeedbackContainerContent">
        <h2 className="kalniyojanFeedbackHeader">
          Feedback
        </h2>
        <div className={kalniyojanFeedbackErrorShower}>
          {Array.from(feedbackErrorSelector).map((error,index) => 
            <div key={index}>{error}</div>
          )}
        </div>
        <div className="kalniyojanFeedbackNote">
          With the help of this form you can share your experience on this website or
          you can make suggestion to improve website experience to the customers.
        </div>
        <form className="kalniyojanFeedbackForm" onSubmit={event => feedbackHandler.handleFeedbackForm(event)}>
          <label className="kalniyojanFeedbackLabel">
            <span>*</span> Email
          </label>
          <input type='email' ref={kalniyojanFeedbackEmailRef} />
          <div className="kalniyojanFeedbackMessage">
            <label><span>*</span>Message</label>
            <input type='text' ref={kalniyojanFeedbackMessageRef} />
          </div>
          <button type="submit">Submit</button>
        </form>

      </div>
    </div>
  );
}