import ServicesType from "../Type/ServicesType";

let ServicesAction = {

    setAdvice: function(data){
        let trimmedAdviceData = [
            ...data.data,
        ];
        return {
            type: ServicesType.getAdvice,
            payload: trimmedAdviceData,
        };
    },

    setMatchMaking: function(data){
        let trimmedMatchMaking = [
            ...data.data,
        ];
        return{
            type: ServicesType.setMatchMaking,
            payload: trimmedMatchMaking,
        };
    },

    setPatrika: function(data){
        return{
            type: ServicesType.setPatrika,
            payload: data,
        };
    },

    setMuhurta: function(data){
        return{
            type: ServicesType.setMuhurta,
            payload: data,
        };
    },

    setFeedback: function(data){
        return{
            type: ServicesType.setFeedback,
            payload: data,
        };
    }
}

export default ServicesAction;