import FeesType from "../Type/FeesType";


let initialFeeState = {
    advice:[],
    matchMaking:[],
    janmaPatrika:[],
    muhurta:[],
    vastu: [],
};

function FeeReducer(state = initialFeeState, action){

    switch(action.type){
        case FeesType.adviceFee:
            return{
                ...state,
                advice: action.payload,
            };

        case FeesType.matchMakingFee:
            return{
                ...state,
                matchMaking: action.payload,
            };

        case FeesType.janmaPatrikaFee:
            return{
                ...state,
                janmaPatrika: action.payload,
            };

        case FeesType.muhurtafee:
            return{
                ...state,
                muhurta: action.payload,
            };

        case FeesType.vastuFee:
            return{
                ...state,
                vastu: action.payload,
            };

        default:
            return state;
    }

}

export default FeeReducer;