import { useNavigate } from "react-router-dom";

import "./Navbar.css";

import menu from "../images/menu.svg";


function Navbar(props){

    let navigation = useNavigate();

    return(
        <nav className="kalniyojanNavbarContainer">
            <div className="kalniyojanNavbarBrandAndRightSideContent">
                <div className="kalniyojanNavbarBrand" onClick={() => navigation("/")}>Kalniyojan</div>
                <div className="kalniyojanNavbarSidebarButton">
                    <img src={menu} alt="NavbarSidebarBtn" onClick={props.click}/>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;