
import "./AdviceSuccessfulCreated.css"
import thumbsUp from "../images/thumbsup.svg";

function ServicesSuccessfulCreated(props){

    let adviceSuccessHandler = {

        handleAdviceSuccessHomeNavigation: function(){
            window.location.href = "/";
        },

        handleAdviceSuccessServicesNavigation: function(){
            window.location.href = "/services";
        }
    }

    return(
        <div className="adviceSuccessfulContainer">
            <div className="adviceSuccessfulContainerContent">
                <div className="adviceSuccessfulMessage">
                    <img src={thumbsUp} className="adviceSuccessfulImg" alt="adviceSuccessful"></img>
                    <div className="adviceSuccessfulInfo">
                        Your form is successfully send.
                        you will get response either on your email/whatsapp,
                        within 24 hours.
                    </div>
                    <div className="serviceUseThanksMessage">
                        Thank you for using <span>KALNIYOJAN</span> service.
                    </div>
                </div>

                <div className="adviceSuccessfulNavigationBtn">
                    <button className="adviceSuccessfulHomeNavigationBtn" onClick={() => adviceSuccessHandler.handleAdviceSuccessHomeNavigation()}>Go Home</button>
                    <button className="adviceSuccessfulServicesNavigationBtn" onClick={() => adviceSuccessHandler.handleAdviceSuccessServicesNavigation()}>Go Services</button>
                </div>
            </div>
        </div>
    );
}

export default ServicesSuccessfulCreated;