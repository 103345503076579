
import './Janmapatrikatypes.css';


function Janmapatrikatypes(){

    return(
        <div id='JanmapatrikatypesContainer'>
            <div id='JanmapatrikatypesContainerContent'>
                <h1>patrika types</h1>

                <div id='patrikaTypesDescription'>
                    <p>we provide 4 types of patrika as mentioned below. each type contains more information than previous type. those information 
                        are described below. 
                    </p>
                    <dl>
                        <li><span>basic::</span>
                            normal janmapatrika with lagna kundali, rashi kundali, navmansha kundali, spastha graha and mahadasha.
                        </li>
                        <li><span>intermediate::</span> 
                            basic + nakshatra information with shubhaashubha vichar.
                        </li>
                        <li><span>advance::</span>
                            intermediate + ashtakvarga and yogini mahadasha.
                        </li>
                        <li><span>with prediction::</span>
                            advance + patrika in krishnamurti method, vishottari mahadasha, antardasha, vidasha, sukshmadasha.
                        </li>
                    </dl>
                </div>
            </div>
        </div>
    );
}

export default Janmapatrikatypes;