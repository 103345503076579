
import "./Janmapatrika.css";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { janmaPatrikaError, resetJanmaPatrikaError, resetJanmaPatrikaPaymentError, setJanmaPatrikaPaymentError } from "../Redux/Action/ErrorAction";
import { Link } from "react-router-dom";
import { isBoolean } from "lodash";
import axios from "axios";
import kapi from "../Redux/kalniyojanapi";
import { janmaPatrikaFee } from "../Redux/Action/FeesAction";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getBillSdkConfig, getClientIp, getStripeAppearance, uploadClientPatrika } from "../Helpers/Earth";
import KalniyojanCheckout from "./Stripescreens/KalniyojanCheckout";


function Janmapatrika(){

	let jp_star = 0;

	let [janmaPatrikaPaymentErrorShower, SetJanmaPatrikaPaymentErrorShower] = useState("janmaPatrikaPaymentErrorShower");
	let [janmaPatrikaErrorShower,SetJanmaPatrikaErrorShower] = useState("janmaPatrikaErrorShower")
	let janmaPatrikaErrorSelector = useSelector(state => state.errors.janmaPatrika);
	let janmaPatrikaPaymentErrorSelector = useSelector(state => state.errors.payment);
	let janmaPatrikaFeeSelector = null;

	let janmaPatrikaDispatcher = useDispatch();

	let [outsider, SetOutSider] = useState(false);

    let [janmaPatrikaPaymentOptionsWindowCls, SetJanmaPatrikaPaymentOptionsWindowCls] = useState("janmaPatrikaPaymentOptionsWindow");

	let [patrikaStripePromise, SetPatrikaStripePromise] = useState(null);
	let [patrikaPaymentWindowCls, SetPatrikaPaymentWindowCls] = useState("patrikaPaymentWindow");

	let janmaPatrikaOrderId = useRef({
		orderId:null,
	});

	let patrikaStripePub = useRef({
		key:null,
	});

	let janmaPatrikaFormData = useRef({
		map:0,
	});

	let patrikaNameRef = useRef(null);
	let patrikaDateRef = useRef(null);
	let patrikaTimeRef = useRef(null);
	let patrikaPlaceRef = useRef(null);
	let patrikaPhoneRef = useRef(null);
	let patrikaEmailRef = useRef(null);

	let handleSex = sex =>{
		SetDefaultGender(sex);
	}

	let handlePatrikaOutsideCountry = value => {
		let activeTier = defaultPatrikaTier;
		SetDefaultPatrikaCountry(value);
		let price = janmaPatrikaFeeSelector;

		if(value !== janmaPatrikaHandlers.countrys.India && !outsider){
			switch(activeTier){

				case janmaPatrikaHandlers.patrikaTiers.intermediate:
					price -= 200;
					price *= 5;
					price += 500;
					janmaPatrikaDispatcher(janmaPatrikaFee(price));
					SetOutSider(true);
					break;

				case janmaPatrikaHandlers.patrikaTiers.advance:
					price -= 300;
					price *= 5;
					price += 1000;
					janmaPatrikaDispatcher(janmaPatrikaFee(price));
					SetOutSider(true);
					break;

				case janmaPatrikaHandlers.patrikaTiers.withPrediction:
					price -= 400;
					price *= 5;
					price += 1500;
					janmaPatrikaDispatcher(janmaPatrikaFee(price));
					SetOutSider(true);
					break;

				default:
					price *= 5;
					janmaPatrikaDispatcher(janmaPatrikaFee(price));
					SetOutSider(true);
					break;
				
			}
		}else if(value === janmaPatrikaHandlers.countrys.India && outsider){
			switch(activeTier){
				case janmaPatrikaHandlers.patrikaTiers.intermediate:
					price -= 500;
					price /= 5;
					price += 200;
					janmaPatrikaDispatcher(janmaPatrikaFee(price));
					SetOutSider(false);
					break;

				case janmaPatrikaHandlers.patrikaTiers.advance:
					price -= 1000;
					price /= 5;
					price += 300;
					janmaPatrikaDispatcher(janmaPatrikaFee(price));
					SetOutSider(false);
					break;

				case janmaPatrikaHandlers.patrikaTiers.withPrediction:
					price -= 1500;
					price /= 5;
					price += 400;
					janmaPatrikaDispatcher(janmaPatrikaFee(price));
					SetOutSider(false);
					break;

				default:
					price /= 5;
					janmaPatrikaDispatcher(janmaPatrikaFee(price));
					SetOutSider(false);
					break;
				
			}
		}
	}

	let handlePatrikaTier = tier => {
		let previousTier = defaultPatrikaTier;
		SetDefaultPatrikaTier(tier);

		let newTier = tier;

		if(previousTier === janmaPatrikaHandlers.patrikaTiers.basic){
			if(newTier === janmaPatrikaHandlers.patrikaTiers.intermediate && outsider){
				let intermediateFees = Number(janmaPatrikaFeeSelector) + 500;
				janmaPatrikaDispatcher(janmaPatrikaFee(intermediateFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.intermediate && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let intermediateFees = Number(janmaPatrikaFeeSelector) + 200;
				janmaPatrikaDispatcher(janmaPatrikaFee(intermediateFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.advance && outsider){
				let advanceFees = Number(janmaPatrikaFeeSelector) + 1000;
				janmaPatrikaDispatcher(janmaPatrikaFee(advanceFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.advance && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let advanceFees = Number(janmaPatrikaFeeSelector) + 300;
				janmaPatrikaDispatcher(janmaPatrikaFee(advanceFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.withPrediction && outsider){
				let withPredictionFees = Number(janmaPatrikaFeeSelector) + 1500;
				janmaPatrikaDispatcher(janmaPatrikaFee(withPredictionFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.withPrediction && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let withPredictionFees = Number(janmaPatrikaFeeSelector) + 400;
				janmaPatrikaDispatcher(janmaPatrikaFee(withPredictionFees));
			}
		}else if(previousTier === janmaPatrikaHandlers.patrikaTiers.intermediate){
			if(newTier === janmaPatrikaHandlers.patrikaTiers.basic && outsider){
				let intermediateFees = janmaPatrikaFeeSelector - 500;
				janmaPatrikaDispatcher(janmaPatrikaFee(intermediateFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.basic && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let intermediateFees = janmaPatrikaFeeSelector - 200;
				janmaPatrikaDispatcher(janmaPatrikaFee(intermediateFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.advance && outsider){
				let intermediateFees = Number(janmaPatrikaFeeSelector) + 500;
				janmaPatrikaDispatcher(janmaPatrikaFee(intermediateFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.advance && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let advanceFees = Number(janmaPatrikaFeeSelector) + 100;
				janmaPatrikaDispatcher(janmaPatrikaFee(advanceFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.withPrediction && outsider){
				let withPredictionFees = Number(janmaPatrikaFeeSelector) + 1000;
				janmaPatrikaDispatcher(janmaPatrikaFee(withPredictionFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.withPrediction && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let withPredictionFees = Number(janmaPatrikaFeeSelector) + 200;
				janmaPatrikaDispatcher(janmaPatrikaFee(withPredictionFees));
			}
		}else if(previousTier === janmaPatrikaHandlers.patrikaTiers.advance){
			if(newTier === janmaPatrikaHandlers.patrikaTiers.basic && outsider){
				let intermediateFees = janmaPatrikaFeeSelector - 1000;
				janmaPatrikaDispatcher(janmaPatrikaFee(intermediateFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.basic && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let intermediateFees = janmaPatrikaFeeSelector - 300;
				janmaPatrikaDispatcher(janmaPatrikaFee(intermediateFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.intermediate && outsider){
				let advanceFees = janmaPatrikaFeeSelector - 500;
				janmaPatrikaDispatcher(janmaPatrikaFee(advanceFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.intermediate && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let advanceFees = janmaPatrikaFeeSelector - 100;
				janmaPatrikaDispatcher(janmaPatrikaFee(advanceFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.withPrediction && outsider){
				let withPredictionFees = Number(janmaPatrikaFeeSelector) + 500;
				janmaPatrikaDispatcher(janmaPatrikaFee(withPredictionFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.withPrediction && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let withPredictionFees = Number(janmaPatrikaFeeSelector) + 100;
				janmaPatrikaDispatcher(janmaPatrikaFee(withPredictionFees));
			}
		}else if(previousTier === janmaPatrikaHandlers.patrikaTiers.withPrediction){
			if(newTier === janmaPatrikaHandlers.patrikaTiers.basic && outsider){
				let intermediateFees = janmaPatrikaFeeSelector - 1500;
				janmaPatrikaDispatcher(janmaPatrikaFee(intermediateFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.basic && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let intermediateFees = janmaPatrikaFeeSelector - 400;
				janmaPatrikaDispatcher(janmaPatrikaFee(intermediateFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.intermediate && outsider){
				let advanceFees = janmaPatrikaFeeSelector - 1000;
				janmaPatrikaDispatcher(janmaPatrikaFee(advanceFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.intermediate && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let advanceFees = janmaPatrikaFeeSelector - 200;
				janmaPatrikaDispatcher(janmaPatrikaFee(advanceFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.advance && outsider){
				let withPredictionFees = janmaPatrikaFeeSelector - 500;
				janmaPatrikaDispatcher(janmaPatrikaFee(withPredictionFees));
			}else if(newTier === janmaPatrikaHandlers.patrikaTiers.advance && defaultPatrikaCountry === janmaPatrikaHandlers.countrys.India){
				let withPredictionFees = janmaPatrikaFeeSelector - 100;
				janmaPatrikaDispatcher(janmaPatrikaFee(withPredictionFees));
			}
		}
	}

	let patrikaInsideIndia = useRef({
		indian:true,
	});
	let patrikaCountryCode = useRef({
		code:91,
	});
	let patrikaPhoneNumber = useRef({
		number:0,
	});

	useEffect(() => {
		axios({
			method: kapi.post,
			url: kapi.janmapatrikafeeapi,
			data:{
				servicename: kapi.janmaPatrikaServiceName,
			},
			headers: {
				"Content-Type": "application/json",
			},
			withCredentials: true,
		}).then(result => {
			if(result.status !== 200){
				throw new Error("error fetching data");
			}
			let {fees} = result.data;
			janmaPatrikaDispatcher(janmaPatrikaFee(fees));
		}).catch(error => {
			SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShowerSelected");
			janmaPatrikaDispatcher(setJanmaPatrikaPaymentError("failed to fetch service data"));
			setTimeout(() => {
				SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaPaymentError());
			}, 8000);
		})
	},[janmaPatrikaFeeSelector]);

	janmaPatrikaFeeSelector = useSelector(state => state.fees.janmaPatrika);

	let janmaPatrikaHandlers = {

		countrys: {
			Afghanistan: "Afghanistan",
			Albania: "Albania",
			Algeria: "Algeria",
			Andorra: "Andorra",
			Angola: "Angola",
			"Antigua and Barbuda":"Antigua and Barbuda",
			Argentina: "Argentina",
			Armenia: "Armenia",
			Australia:"Australia",
			Austria:"Austria",
			Azerbaijan:"Azerbaijan",
			Bahamas:"Bahamas",
			Bahrain:"Bahrain",
			Bangladesh:"Bangladesh",
			Barbados:"Barbados",
			Belarus:"Belarus",
			Belgium:"Belgium",
			Belize:"Belize",
			Benin:"Benin",
			Bhutan:"Bhutan",
			Bolivia:"Bolivia",
			"Bosnia and Herzegovina":"Bosnia and Herzegovina",
			Botswana:"Botswana",
			Brazil:"Brazil",
			Brunei:"Brunei",
			Bulgaria:"Bulgaria",
			"Burkina Faso":"Burkina Faso",
			Burundi:"Burundi",
			"Côte d'Ivoire":"Côte d'Ivoire",
			"Cabo Verde":"Cabo Verde",
			Cambodia:"Cambodia",
			Cameroon:"Cameroon",
			Canada:"Canada",
			"Central African Republic":"Central African Republic",
			Chad:"Chad",
			Chile:"Chile",
			China: "China",
			Colombia:"Colombia",
			Comoros:"Comoros",
			"Congo (Congo-Brazzaville)":"Congo (Congo-Brazzaville)",
			"Costa Rica":"Costa Rica",
			Croatia:"Croatia",
			Cuba:"Cuba",
			Cyprus:"Cyprus",
			"Czechia (Czech Republic)":"Czechia (Czech Republic)",
			"Democratic Republic of the Congo":"Democratic Republic of the Congo",
			Denmark:"Denmark",
			Djibouti:"Djibouti",
			Dominica:"Dominica",
			"Dominican Republic":"Dominican Republic",
			Ecuador:"Ecuador",
			Egypt:"Egypt",
			"El Salvador":"El Salvador",
			"Equatorial Guinea":"Equatorial Guinea",
			Eritrea:"Eritrea",
			Estonia:"Estonia",
			Swaziland:"Swaziland",
			Ethiopia:"Ethiopia",
			Fiji:"Fiji",
			Finland:"Finland",
			France:"France",
			Gabon:"Gabon",
			Gambia:"Gambia",
			Georgia:"Georgia",
			Germany:"Germany",
			Ghana:"Ghana",
			Greece:"Greece",
			Grenada:"Grenada",
			Guatemala:"Guatemala",
			Guinea:"Guinea",
			"Guinea-Bissau":"Guinea-Bissau",
			Guyana:"Guyana",
			Haiti:"Haiti",
			"Vatican City":"Vatican City",
			Honduras:"Honduras",
			Hungary:"Hungary",
			Iceland:"Iceland",
			India:"India",
			Indonesia:"Indonesia",
			Iran:"Iran",
			Iraq:"Iraq",
			Ireland:"Ireland",
			Israel:"Israel",
			Italy:"Italy",
			Jamaica:"Jamaica",
			Japan:"Japan",
			Jordan:"Jordan",
			Kazakhstan:"Kazakhstan",
			Kenya:"Kenya",
			Kiribati:"Kiribati",
			Kuwait:"Kuwait",
			Kyrgyzstan:"Kyrgyzstan",
			Laos:"Laos",
			Latvia:"Latvia",
			Lebanon:"Lebanon",
			Lesotho:"Lesotho",
			Liberia:"Liberia",
			Libya:"Libya",
			Liechtenstein:"Liechtenstein",
			Lithuania:"Lithuania",
			Luxembourg:"Luxembourg",
			Madagascar:"Madagascar",
			Malawi:"Malawi",
			Malaysia:"Malaysia",
			Maldives:"Maldives",
			Mali:"Mali",
			Malta:"Malta",
			"Marshall Islands":"Marshall Islands",
			Mauritania:"Mauritania",
			Mauritius:"Mauritius",
			Mexico:"Mexico",
			Micronesia:"Micronesia",
			Moldova:"Moldova",
			Monaco:"Monaco",
			Mongolia:"Mongolia",
			Montenegro:"Montenegro",
			Morocco:"Morocco",
			Mozambique:"Mozambique",
			Myanmar:"Myanmar",
			Namibia:"Namibia",
			Nauru:"Nauru",
			Nepal:"Nepal",
			Netherlands:"Netherlands",
			"New Zealand":"New Zealand",
			Nicaragua:"Nicaragua",
			Niger:"Niger",
			Nigeria:"Nigeria",
			"North Korea":"North Korea",
			"North Macedonia":"North Macedonia",
			Norway:"Norway",
			Oman:"Oman",
			Pakistan:"Pakistan",
			Palau:"Palau",
			Panama:"Panama",
			"Papua New Guinea":"Papua New Guinea",
			Paraguay:"Paraguay",
			Peru:"Peru",
			Philippines:"Philippines",
			Poland:"Poland",
			Portugal:"Portugal",
			Qatar:"Qatar",
			Romania:"Romania",
			Russia:"Russia",
			Rwanda:"Rwanda",
			"Saint Kitts and Nevis":"Saint Kitts and Nevis",
			"Saint Lucia":"Saint Lucia",
			"Saint Vincent and the Grenadines":"Saint Vincent and the Grenadines",
			Samoa:"Samoa",
			"San Marino":"San Marino",
			"Sao Tome and Principe":"Sao Tome and Principe",
			"Saudi Arabia":"Saudi Arabia",
			Senegal:"Senegal",
			Serbia:"Serbia",
			Seychelles:"Seychelles",
			"Sierra Leone":"Sierra Leone",
			Singapore:"Singapore",
			Slovakia:"Slovakia",
			Slovenia:"Slovenia",
			"Solomon Islands":"Solomon Islands",
			Somalia:"Somalia",
			"South Africa":"South Africa",
			"South Korea":"South Korea",
			"South Sudan":"South Sudan",
			Spain:"Spain",
			"Sri Lanka":"Sri Lanka",
			Sudan:"Sudan",
			Suriname:"Suriname",
			Sweden:"Sweden",
			Switzerland:"Switzerland",
			Syria:"Syria",
			Tajikistan:"Tajikistan",
			Tanzania:"Tanzania",
			Thailand:"Thailand",
			"Timor-Leste":"Timor-Leste",
			Togo:"Togo",
			Tonga:"Tonga",
			"Trinidad and Tobago":"Trinidad and Tobago",
			Tunisia:"Tunisia",
			Turkey:"Turkey",
			Turkmenistan:"Turkmenistan",
			Tuvalu:"Tuvalu",
			Uganda:"Uganda",
			Ukraine:"Ukraine",
			"United Arab Emirates":"United Arab Emirates",
			"United Kingdom":"United Kingdom",
			"United States of America":"United States of America",
			Uruguay:"Uruguay",
			Uzbekistan:"Uzbekistan",
			Vanuatu:"Vanuatu",
			Venezuela:"Venezuela",
			Vietnam:"Vietnam",
			Yemen:"Yemen",
			Zambia:"Zambia",
			Zimbabwe:"Zimbabwe",
		},

		patrikaLang: {
			marathi:"Marathi",
			kannada:"Kannada",
			tamil:"Tamil",
			bengali:"Bengali",
			telugu:"Telugu",
			gujarati:"Gujarati",
			malyalam:"Malyalam",
			hindi:"Hindi",
			english:"English",
		},

		janmaPatrikaValidateTypes:{
			name:"name",
			date:"date",
			time:"time",
			place:"place",
			phone:"phone",
			email:"email",
			gender:"gender",
			country:"country",
			tier:"tier",
			language:"language",
		},

		gender: {
			male: "male",
			female: "female",
		},

		patrikaTiers: {
			basic: "basic",
			intermediate: "intermediate",
			advance: "advance",
			withPrediction: "with prediction",
		},

		dateValidator: function(date){
			let dateValidateObj = {
				year: true,
				month: true,
				day: true,
			};

			if (date.getFullYear() === "" || date.getFullYear() <= 1900 || date.getFullYear().toString().length < 4) {
				dateValidateObj.year = false;
			}
			if(date.getFullYear() > new Date().getFullYear()){
				dateValidateObj.year = false;
			}

			if (date.getMonth() === "") {
				dateValidateObj.month = false;
			}

			if (date.getMonth() > 12 || (date.getFullYear() > new Date().getFullYear() && (date.getMonth() - 1) >= new Date().getMonth())) {
				dateValidateObj.month = false;
			}

			if (date.getFullYear() <= new Date().getFullYear() && (date.getMonth() - 1) <= new Date().getMonth()) {
				dateValidateObj.month = true;
			}

			if (date.getMonth() <= 12 && (date.getFullYear() < new Date().getFullYear() && ((date.getMonth() - 1) > new Date().getMonth() || (date.getMonth() - 1) < new Date().getMonth() || (date.getMonth() - 1) === new Date().getMonth()))) {
				dateValidateObj.month = true;
			}

			if (date.getFullYear() === new Date().getFullYear() && (date.getMonth() - 1) > new Date().getMonth()) {
				dateValidateObj.month = false;
			}

			if (date.getDate() === "") {
				dateValidateObj.day = false;
			}
			let patrikaDaysInMonth = janmaPatrikaHandlers.getDaysInMonth(date.getFullYear(), date.getMonth());
			if (isBoolean(patrikaDaysInMonth)) {
				dateValidateObj.day = false;
			}
			if(date.getDate() > patrikaDaysInMonth){
				dateValidateObj.day = false;
			}        
			return dateValidateObj;
		},

		timeValidator: function(time){

			let timeobj = {
				hour: true,
				minute: true,
			};

			if(time === null || time === " " || time === undefined || time === ""){
				return false;
			}

			let trimedtime = time.split(":");
			if(trimedtime.length < 2 || trimedtime.length > 2 || trimedtime.length !== 2){
				return {
					...timeobj,
					hour: false,
					minute: false,
				};
			}
			let minute = parseInt(trimedtime[1]);
			let hour = parseInt(trimedtime[0]);

			if(hour === "" || hour < 0 || hour > 24){
				timeobj.hour = false;
			}

			if(minute === "" || minute < 0 || minute > 60){
				timeobj.minute = false;
			}

			return timeobj;
		},

		placeValidator: function(place){

			if(place === ""){
				return false;
			}
			
			if (place.length < 4) {
				return false;
			}
			let placeRegex = /[a-zA-Z]{4,}/
			let isPlaceValid = placeRegex.test(place);
			if (!isPlaceValid) {
				return false;
			}
			let advancedPlaceRegex = /(?=.*[@!+._\-?{}[\]])[@!+._\-?{}[\]]/
			if (advancedPlaceRegex.test(place)) {
				return false;
			}
			return true;
		},

		nameValidator: function(name){
			let nameRegex = /[a-zA-Z]{5,}/i;
			let isNameValid = nameRegex.test(name);
			return isNameValid;
		},

		daysInMonths: {
			"Jan": 31,
			"Feb": 28,
			"Mar": 31,
			"Apr": 30,
			"May": 31,
			"Jun": 30,
			"Jul": 31,
			"Aug": 31,
			"Sep": 30,
			"Oct": 31,
			"Nov": 30,
			"Dec": 31,
		},
		 
		getNumber: number => {
			if (number === "") {
				return false;
			}
			let positionOfDash = number.indexOf("-");
			let lastPositionOfDash = number.lastIndexOf("-");
			if (positionOfDash === -1) {
				return false;
			}
			if (positionOfDash !== lastPositionOfDash) {
				return false;
			}
			let MM = number.substring(0, positionOfDash);
			let parsedMM = parseInt(MM);
			if (isNaN(parsedMM) || MM.length > 4 || MM.length < 1 || parsedMM === 0) {
				return false;
			}
			let actualNumberOfRequester = number.substring(positionOfDash + 1, number.length);
			let actualNumber = parseInt(actualNumberOfRequester);
			if (isNaN(actualNumber) || actualNumberOfRequester.length > 10) {
				return false;
			}
			patrikaInsideIndia.current.indian = parsedMM !== 91 ? false : true;
			patrikaCountryCode.current.code = parsedMM;
			patrikaPhoneNumber.current.number = actualNumber;
			return actualNumber;
		},

		getDaysInMonth: function(year, month){

			let is_leap_year = false;
			let months = this.daysInMonths;

			if (year % 4 === 0) {
				is_leap_year = true;
			}

			let months_map = new Map(Array.from(Object.entries(months)));

			if (is_leap_year) {
				let temp_months_keys_array = Array.from(months_map.keys());

				let alter_feb = temp_months_keys_array.find(item => item === "Feb");

				if (alter_feb !== null || alter_feb !== undefined) {
					months[alter_feb] = 29;
				}
			}

			let months_keys_array = Array.from(months_map.keys());

			let user_expected_month = months_keys_array[(month)];

			if (typeof user_expected_month === "string") {
				return months[user_expected_month];
			} else {
				return false;
			}
		},

		validatePatrika: function(value,type){

			switch(type){

				case this.janmaPatrikaValidateTypes.name:
					if(value === ""){
						return false;
					}
					return janmaPatrikaHandlers.nameValidator(value);

				case this.janmaPatrikaValidateTypes.date:
					if(new Date(value).valueOf() > new Date().valueOf()){
						return false;
					}
					let isDateValid = janmaPatrikaHandlers.dateValidator(new Date(value));
					if(Object.values(isDateValid).includes(false)){
						return false;
					}
					return true;
				
				case this.janmaPatrikaValidateTypes.time:
					let isTimeValid = janmaPatrikaHandlers.timeValidator(value);
					if(isBoolean(isTimeValid)){
						return false;
					}
					if(Object.values(isTimeValid).includes(false)){
						return false;
					}
					return true;
					
				case this.janmaPatrikaValidateTypes.place:
					return janmaPatrikaHandlers.placeValidator(value);
				
				case this.janmaPatrikaValidateTypes.country:
					let countryarr = Object.values(this.countrys);
					let countryExist = countryarr.find(item => item === value);
					if(countryExist){
						return true;
					}else{
						return false;
					}

				case this.janmaPatrikaValidateTypes.language:
					let languageArr = Object.values(this.patrikaLang);
					let languageExist = languageArr.find(item => item === value);
					if(languageExist){
						return true;
					}else {
						return false;
					}
				
				case this.janmaPatrikaValidateTypes.phone:
					let matchNumberWithOutCode = janmaPatrikaHandlers.getNumber(value);

					if (isBoolean(matchNumberWithOutCode)) {
						return false;
					}
					if (patrikaInsideIndia.current.indian && matchNumberWithOutCode.toString().length < 10) {
						return false;
					}
					if (!patrikaInsideIndia.current.value && matchNumberWithOutCode.toString().length < 10) {
						return false;
					}
					return true;
				
				case this.janmaPatrikaValidateTypes.email:
					if (value === "") {
						return false;
					}

					let emailRegex = /[a-zA-Z0-9\-_.]+@(gmail|hotmail|yahoo|rediffmail)?(?:\.(com))/
					let isEmailValid = emailRegex.test(value);
					return isEmailValid;

				case this.janmaPatrikaValidateTypes.gender:
					let genderArr = Object.values(this.gender);
					let genderExist = genderArr.find(item => item === value);
					if(genderExist){
						return true;
					}else{
						return false;
					}

				case this.janmaPatrikaValidateTypes.tier:
					let tierArr = Object.values(this.patrikaTiers);
					let tierExist = tierArr.find(item => item === value);
					if(tierExist){
						return true;
					}else{
						return false;
					}

				default:
					return false;
			}
		},
	
		handleJanmapatrika: function(e){
		e.preventDefault();

		if(patrikaNameRef.current.value === null || !janmaPatrikaHandlers.validatePatrika(patrikaNameRef.current.value,janmaPatrikaHandlers.janmaPatrikaValidateTypes.name)){
			window.scrollTo(0,0);
			SetJanmaPatrikaErrorShower("janmaPatrikaErrorShowerSelected");
			janmaPatrikaDispatcher(janmaPatrikaError("invalid name"));
			setTimeout(() => {
				SetJanmaPatrikaErrorShower("janmaPatrikaErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaError());
			}, 6000);
			jp_star++;
		}

		if(patrikaDateRef.current.value === null || patrikaDateRef.current.value === "" || !janmaPatrikaHandlers.validatePatrika(patrikaDateRef.current.value,janmaPatrikaHandlers.janmaPatrikaValidateTypes.date)){
			window.scrollTo(0,0);
			SetJanmaPatrikaErrorShower("janmaPatrikaErrorShowerSelected");
			janmaPatrikaDispatcher(janmaPatrikaError("invalid date"));
			setTimeout(() => {
				SetJanmaPatrikaErrorShower("janmaPatrikaErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaError());
			}, 6000);
			jp_star++;
		}

		if(patrikaTimeRef.current.value === null || patrikaTimeRef.current.value === "" || !janmaPatrikaHandlers.validatePatrika(patrikaTimeRef.current.value,janmaPatrikaHandlers.janmaPatrikaValidateTypes.time)){
			window.scrollTo(0,0);
			SetJanmaPatrikaErrorShower("janmaPatrikaErrorShowerSelected");
			janmaPatrikaDispatcher(janmaPatrikaError("invalid Time"));
			setTimeout(() => {
				SetJanmaPatrikaErrorShower("janmaPatrikaErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaError());
			}, 6000);
			jp_star++;
		}

		if(patrikaPlaceRef.current.value === null || patrikaPlaceRef.current.value === "" || !janmaPatrikaHandlers.validatePatrika(patrikaPlaceRef.current.value,janmaPatrikaHandlers.janmaPatrikaValidateTypes.place)){
			window.scrollTo(0,0);
			SetJanmaPatrikaErrorShower("janmaPatrikaErrorShowerSelected");
			janmaPatrikaDispatcher(janmaPatrikaError("invalid place"));
			setTimeout(() => {
				SetJanmaPatrikaErrorShower("janmaPatrikaErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaError());
			}, 6000);
			jp_star++;
		}

		if(patrikaPhoneRef.current.value === null || patrikaPhoneRef.current.value === "" || !janmaPatrikaHandlers.validatePatrika(patrikaPhoneRef.current.value,janmaPatrikaHandlers.janmaPatrikaValidateTypes.phone)){
			window.scrollTo(0,0);
			SetJanmaPatrikaErrorShower("janmaPatrikaErrorShowerSelected");
			janmaPatrikaDispatcher(janmaPatrikaError("invalid phone number, please check the format"));
			setTimeout(() => {
				SetJanmaPatrikaErrorShower("janmaPatrikaErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaError());
			}, 6000);
			jp_star++;
		}

		if(patrikaEmailRef.current.value === null || !janmaPatrikaHandlers.validatePatrika(patrikaEmailRef.current.value,janmaPatrikaHandlers.janmaPatrikaValidateTypes.email)){
			window.scrollTo(0,0);
			SetJanmaPatrikaErrorShower("janmaPatrikaErrorShowerSelected");
			janmaPatrikaDispatcher(janmaPatrikaError("email is not valid"));
			setTimeout(() => {
				SetJanmaPatrikaErrorShower("janmaPatrikaErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaError());
			}, 6000);
			jp_star++;
		}
		
		if(defaultPatrikaCountry === null || !janmaPatrikaHandlers.validatePatrika(defaultPatrikaCountry,janmaPatrikaHandlers.janmaPatrikaValidateTypes.country)){
			window.scrollTo(0,0);
			SetJanmaPatrikaErrorShower("janmaPatrikaErrorShowerSelected");
			janmaPatrikaDispatcher(janmaPatrikaError("country is not valid"));
			setTimeout(() => {
				SetJanmaPatrikaErrorShower("janmaPatrikaErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaError());
			}, 6000);
			jp_star++;
		}

		if(defaultGender === null || !janmaPatrikaHandlers.validatePatrika(defaultGender,janmaPatrikaHandlers.janmaPatrikaValidateTypes.gender)){
			window.scrollTo(0,0);
			SetJanmaPatrikaErrorShower("janmaPatrikaErrorShowerSelected");
			janmaPatrikaDispatcher(janmaPatrikaError("invalid gender"));
			setTimeout(() => {
				SetJanmaPatrikaErrorShower("janmaPatrikaErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaError());
			}, 6000);
			jp_star++;
		}

		if(defaultPatrikaTier === null || !janmaPatrikaHandlers.validatePatrika(defaultPatrikaTier,janmaPatrikaHandlers.janmaPatrikaValidateTypes.tier)){
			window.scrollTo(0,0);
			SetJanmaPatrikaErrorShower("janmaPatrikaErrorShowerSelected");
			janmaPatrikaDispatcher(janmaPatrikaError("invalid patrika type"));
			setTimeout(() => {
				SetJanmaPatrikaErrorShower("janmaPatrikaErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaError());
			}, 6000);
			jp_star++;
		}

		if(defaultPatrikaLanguage === null || !janmaPatrikaHandlers.validatePatrika(defaultPatrikaLanguage,janmaPatrikaHandlers.janmaPatrikaValidateTypes.language)){
			window.scrollTo(0,0);
			SetJanmaPatrikaErrorShower("janmaPatrikaErrorShowerSelected");
			janmaPatrikaDispatcher(janmaPatrikaError("invalid patrika language"));
			setTimeout(() => {
				SetJanmaPatrikaErrorShower("janmaPatrikaErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaError());
			}, 6000);
			jp_star++;
		}

		if(jp_star === 0){

			let janmaPatrikaFormData = new FormData();
			janmaPatrikaFormData.append("patrikaName",patrikaNameRef.current.value);
			janmaPatrikaFormData.append("patrikaDate",patrikaDateRef.current.value);
			janmaPatrikaFormData.append("patrikaTime",patrikaTimeRef.current.value);
			janmaPatrikaFormData.append("patrikaPlace",patrikaPlaceRef.current.value);
			janmaPatrikaFormData.append("patrikaPhone",patrikaPhoneNumber.current.number);
			janmaPatrikaFormData.append("patrikaPhoneCode",patrikaCountryCode.current.code);
			janmaPatrikaFormData.append("patrikaEmail",patrikaEmailRef.current.value);
			janmaPatrikaFormData.append("patrikaCountry",defaultPatrikaCountry);
			janmaPatrikaFormData.append("patrikaSex",defaultGender);
			janmaPatrikaFormData.append("patrikaTier",defaultPatrikaTier);
			janmaPatrikaFormData.append("patrikaLanguage",defaultPatrikaLanguage);

			resetPhoneCodeIndia();

			setTimeout(() => {
				submitPatrika(janmaPatrikaFormData);
			}, 1000);
		}
	}

	};


	async function handlePatrikaBillPaymentComplete(patrika_bill_response){

		let upload_patrika_res = null;

		if(patrika_bill_response.status === 200){
			upload_patrika_res = await uploadClientPatrika(janmaPatrikaFormData.current.map);
		}

		if(upload_patrika_res.success){
            setTimeout(() => {
                window.location.href = "/servicessuccess";
            }, 1000);
		}else{
			SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShowerSelected");
			janmaPatrikaDispatcher(setJanmaPatrikaPaymentError(upload_patrika_res.message));
			setTimeout(() => {
				SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShower");
				janmaPatrikaDispatcher(resetJanmaPatrikaPaymentError());
			}, 6000);
			window.location.reload();
		}
	}


	function preparePatrikaBillSdkObj(bill_order_obj){

        let {bdorderid,token,authorization} = bill_order_obj;

		let patrikaBillSdkConfig = getBillSdkConfig(bdorderid,token,authorization);

		patrikaBillSdkConfig.responseHandler = handlePatrikaBillPaymentComplete;

        window.loadBillDeskSdk(patrikaBillSdkConfig);
	}

	async function getPatrikaBOrderId(){

		let patrikaBorderFormData = new FormData();
		patrikaBorderFormData.append("amount",janmaPatrikaFeeSelector);
		patrikaBorderFormData.append("currency","inr");
		patrikaBorderFormData.append("clientip",await getClientIp());
		patrikaBorderFormData.append("useragent",window.navigator.userAgent);

		axios({
			url: kapi.borderidapi,
			method: kapi.post,
			data: patrikaBorderFormData,
			headers: {
				"Content-Type":"application/json",
			},
			withCredentials: true,
		}).then(result => {
			if(result.status !== 200){
				throw new Error({message: "order creation failed"});
			}else{
				SetJanmaPatrikaPaymentOptionsWindowCls("janmaPatrikaPaymentOptionsWindow");
				preparePatrikaBillSdkObj(result.data.data);
			}
		}).catch(error => {
			SetJanmaPatrikaPaymentOptionsWindowCls("janmaPatrikaPaymentOptionsWindow");
			if(error.response !== undefined){
				SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShowerSelected");
				janmaPatrikaDispatcher(setJanmaPatrikaPaymentError(error.response.data.message));
				setTimeout(() => {
					SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShower");
					janmaPatrikaDispatcher(resetJanmaPatrikaPaymentError());
				}, 6000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
			}else if(error instanceof Object){
				SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShowerSelected");
				janmaPatrikaDispatcher(setJanmaPatrikaPaymentError(error.message));
				setTimeout(() => {
					SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShower");
					janmaPatrikaDispatcher(resetJanmaPatrikaPaymentError());
				}, 6000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
			}else{
				SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShowerSelected");
				janmaPatrikaDispatcher(setJanmaPatrikaPaymentError("something is wrong may be server is down try again/later"));
				setTimeout(() => {
					SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShower");
					janmaPatrikaDispatcher(resetJanmaPatrikaPaymentError());
				}, 6000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
			}
		});

	}

	function handlePatrikaLanguage(e){
		SetDefaultPatrikaLanguage(e)
	}

	function resetPhoneCodeIndia(){
		patrikaPhoneNumber.current.number = 0;
		patrikaCountryCode.current.code = 91;
		patrikaInsideIndia.current.indian = true;
	}

	let patrikaPaymentOptionHandlerObj = {

		handlePaymentOptionOne: function(){
			setTimeout(() => {
				getPatrikaOrderId();
			}, 1000);
			SetJanmaPatrikaPaymentOptionsWindowCls("janmaPatrikaPaymentOptionsWindow");

			setTimeout(() => {
				SetPatrikaPaymentWindowCls("patrikaPaymentWindowSelected");
			}, 200);
		},

		handlePaymentOptionTwo: function(){
			getPatrikaBOrderId();
			SetJanmaPatrikaPaymentOptionsWindowCls("janmaPatrikaPaymentOptionsWindow");
		}
	}

	async function getPatrikaPayConfig(){

		let patrikaPayConfig = null;

		try{
			patrikaPayConfig = axios({
				method:kapi.get,
				url:kapi.orderconfig,
				withCredentials:true,
			})
		}catch(error){
			SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShowerSelected")
        	janmaPatrikaDispatcher(setJanmaPatrikaPaymentError("error creating order try later"));
        	setTimeout(() => {
				SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShower")
            	janmaPatrikaDispatcher(resetJanmaPatrikaPaymentError());
        	}, 10000);
		}
		
		return patrikaPayConfig;
	}

	function getPatrikaOrderId(){
		let patrikaOrder = new FormData();
		patrikaOrder.append("amount",janmaPatrikaFeeSelector);
		patrikaOrder.append("currency","inr");

		axios({
			method: kapi.post,
			url: kapi.orderidapi,
			data: patrikaOrder,
			headers:{
				"Content-Type":"application/json",
			},
			withCredentials: true,
		}).then(async result => {
			if(result.status !== 200){
				throw new Error();
			}
			janmaPatrikaOrderId.current.orderId = result.data.data;
			let patrikaConfig = null;
			patrikaConfig = await getPatrikaPayConfig()
							.then(result => {
								if(result.status === 200){
									patrikaStripePub.current.key = result.data.data;
									return result.data.data;
								}
								if(patrikaConfig === null){
									throw new Error({
										message:"failed to create order try later",
									});
								}
							}).catch(error => {
								SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShowerSelected");
								janmaPatrikaDispatcher(setJanmaPatrikaPaymentError(error.message));
            					setTimeout(() => {
									SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShower")
            						janmaPatrikaDispatcher(resetJanmaPatrikaPaymentError());
            					}, 10000);

							}).catch(error => {
								SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShowerSelected");
								janmaPatrikaDispatcher(setJanmaPatrikaPaymentError("failed to create order try later or again"));
            					setTimeout(() => {
									SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShower")
            						janmaPatrikaDispatcher(resetJanmaPatrikaPaymentError());
            					}, 10000);
							});
			SetPatrikaStripePromise(loadStripe(patrikaConfig));
		}).catch(error => {
			if(error.response !== undefined){
				let patrikaOrderError = error.response;
				SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShowerSelected");
				janmaPatrikaDispatcher(setJanmaPatrikaPaymentError(patrikaOrderError.data.message));
            	setTimeout(() => {
					SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShower")
            		janmaPatrikaDispatcher(resetJanmaPatrikaPaymentError());
            	}, 10000);
			}else{
				SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShowerSelected");
				janmaPatrikaDispatcher(setJanmaPatrikaPaymentError("failed to fetch order data try later"));
            	setTimeout(() => {
					SetJanmaPatrikaPaymentErrorShower("janmaPatrikaPaymentErrorShower")
            		janmaPatrikaDispatcher(resetJanmaPatrikaPaymentError());
            	}, 10000);
			}
		});
	}


	let [defaultGender, SetDefaultGender] = useState(janmaPatrikaHandlers.gender.male);
	let [defaultPatrikaCountry, SetDefaultPatrikaCountry] = useState(janmaPatrikaHandlers.countrys.India);
	let [defaultPatrikaTier, SetDefaultPatrikaTier] = useState(janmaPatrikaHandlers.patrikaTiers.basic);
	let [defaultPatrikaLanguage, SetDefaultPatrikaLanguage] = useState(janmaPatrikaHandlers.patrikaLang.marathi);

	function submitPatrika(janmaPatrikaData){
		janmaPatrikaFormData.current.map = janmaPatrikaData;
		SetJanmaPatrikaPaymentOptionsWindowCls("janmaPatrikaPaymentOptionsWindowSelected");

	}

	return(
		<div className="kalniyojanJanmapatrikaContainer">
			<div className="kalniyojanJanmapatrikaContainerContent">
				<h2 className="kalniyojanJanmapatrikaHeader">
					Janma Patrika
				</h2>

				<div className={janmaPatrikaErrorShower}>
					{Array.from(janmaPatrikaErrorSelector).map((item,index) => 
						<div key={index}>{item}</div>
					)}
				</div>

				<div id={janmaPatrikaPaymentErrorShower}>
					{janmaPatrikaPaymentErrorSelector.map((item,index) => 
						<div key={index}>{item}</div>
					)}
				</div>

				<div id="kalniyojanJanmaPatrikaFeeIndicator">
					<div>Fees</div>
					<div id="janmaPatrikaFee">{janmaPatrikaFeeSelector} INR</div>
				</div>

				<form onSubmit={event => janmaPatrikaHandlers.handleJanmapatrika(event)}>
					<div className="nameOnPatrikaGenderRow">
						<div className="nameOnPatrikaRow">
							<label htmlFor="nameOnPatrika"><span>*</span> Name On Patrika:</label>
							<input type='text' name="nameOnPatrika" className="nameOnPatrika" ref={patrikaNameRef} />
						</div>
						<div className="genderOnPatrikaRow">
							<label htmlFor="genderOnPatrika"><span>*</span> Sex:</label>
							<select value={defaultGender} onChange={e => handleSex(e.target.value)}>
								{Array.from(Object.values(janmaPatrikaHandlers.gender)).map((item,index) => 
									<option key={index}>{item}</option>
								)}
							</select>
						</div>
					</div>
					<div className="kalniyojanPatrikaDateTimeRow">
						<div className="kalniyojanPatrikaDateRow">
							<label htmlFor="patrikaDate"><span>*</span> Birth Date:</label>
							<input type='date' name="patrikaDate" className="patrikaDate" ref={patrikaDateRef} />
						</div>
						<div className="kalniyojanPatrikaTimeRow">
							<label htmlFor="patrikaTime"><span>*</span> Birth Time:</label>
							<input type='time' name="patrikaTime" className="patrikaTime" ref={patrikaTimeRef} />
						</div>
						<div className="kalniyojanPatrikaLanguageRow">
							<label htmlFor="patrikaLang"><span>*</span> Patrika Language:</label>
							<select value={defaultPatrikaLanguage} onChange={e => handlePatrikaLanguage(e.target.value)}>
								{Array.from(Object.values(janmaPatrikaHandlers.patrikaLang)).map((item,index) => 
									<option key={index}>{item}</option>
								)}
							</select>
						</div>
					</div>
					<div className="kalniyojanPatrikaPlaceCountryRow">
						<div className="kalniyojanPatrikaPlace">
							<label htmlFor="patrikaPlace"><span>*</span> Birth Place:</label>
							<input type='text' name="patrikaPlace" className="patrikaPlace" ref={patrikaPlaceRef} />
						</div>
						<div className="kalniyojanPatrikaCountryRow">
							<label htmlFor="patrikaCountry"><span>*</span> Birth Country:</label>
							<select value={defaultPatrikaCountry} onChange={e => handlePatrikaOutsideCountry(e.target.value)}>
								{Array.from(Object.values(janmaPatrikaHandlers.countrys)).map((item,index) => 
									<option key={index}>{item}</option>
								)}
							</select>
						</div>
						<div className="kalniyojanPatrikaTier">
							<label htmlFor="patrikaTier"><span>*</span> Type</label>
							<select value={defaultPatrikaTier} onChange={e => handlePatrikaTier(e.target.value)}>
								{Array.from(Object.values(janmaPatrikaHandlers.patrikaTiers)).map((item,index) => 
									<option key={index}>{item}</option>
								)}
							</select>
							<Link to={"/janmapatrika/types"} className="patrikaTierKnowMore">Know more about types</Link>
						</div>
					</div>
					<div className="kalniyojanPatrikaPhoneEmailRow">
						<div className="kalniyojanPatrikaPhoneRow">
							<label htmlFor="patrikaPhone"><span>*</span> Phone:</label>
							<input type='text' name="patrikaPhone" className="patrikaPhone" ref={patrikaPhoneRef} />
							<p className="janmaPatrikaPhoneFormatNote">
								NOTE: use this format (country code - phonenumber)(eg: 91-0123456789)
							</p>
						</div>
						<div className="kalniyojanPatrikaEmailRow">
							<label htmlFor="patrikaEmail"><span>*</span> Email:</label>
							<input type='text' name="patrikaEmail" className="patrikaEmail" ref={patrikaEmailRef} />
						</div>
					</div>
					<div className="kalniyojanJanmapatrikaSubmitBtn">
						<button type='submit' value="submit" title="submit">Submit</button>
					</div>
				</form>
			</div>
            <div className={janmaPatrikaPaymentOptionsWindowCls}>
                <div className="kalniyojanPatrikaPaymentOptionOne">
                    <p>select this payment option if you want to pay through (master,visa) credit/debit card</p>
                    <button onClick={patrikaPaymentOptionHandlerObj.handlePaymentOptionOne}>Select</button>
                </div>
                <div className="kalniyojanPatrikaPaymentOptionTwo">
                    <p>select this payment option if you want to pay through (netbanking,upi,QR) or (Rupay) credit/debit card</p>
                    <button onClick={patrikaPaymentOptionHandlerObj.handlePaymentOptionTwo}>Select</button>
                </div>
            </div>
			{patrikaStripePub.current.key && <div className={patrikaPaymentWindowCls}>
				<Elements stripe={patrikaStripePromise} options={getStripeAppearance(janmaPatrikaOrderId.current.orderId)}>
					<KalniyojanCheckout sendData={{serviceName:"patrika", formD:janmaPatrikaFormData.current.map}} payAmount={janmaPatrikaFeeSelector} paymentWindow={() => {SetPatrikaPaymentWindowCls("patrikaPaymentWindow"); window.location.reload();}} orderId={janmaPatrikaOrderId.current.orderId} stripePub={patrikaStripePub.current.key} />
				</Elements>
			</div>}
		</div>
	);

}

export default Janmapatrika;