import { useEffect, useRef, useState } from "react";
import "./Loginpage.css";
import { useDispatch, useSelector } from "react-redux";
import { invalidLogin, resetLogin } from "../Redux/Action/ErrorAction";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import kapi from "../Redux/kalniyojanapi";

function Loginpage(props){

    let l_page = 0; 

    let loginNavigatation = useNavigate();

    let emailref = useRef(null);
    let passwordref = useRef(null);

    let [errorShowerCls, setErrorShowerCls] = useState("loginPageErrorShower");

    let loginDispatcher = useDispatch();

    let loginSelector = useSelector(state => state.errors.loginError);

    useEffect(() => {
        
        let Auth_tok = localStorage.getItem("kapt");

        if(Auth_tok){
            loginNavigatation("/kal/admin/dashboard");
        }
    });

    function handleKalniyojanLogin(e){
        e.preventDefault();

        let email_value = emailref.current.value;
        let password_value = passwordref.current.value;

        loginDispatcher(resetLogin());
        setErrorShowerCls("loginPageErrorShower");

        if(email_value.length < 1 || password_value.length < 1){
            setErrorShowerCls("loginPageErrorShowerSelected");
            loginDispatcher(invalidLogin("login form is empty fill the form first"));
            setTimeout(() => {
                setErrorShowerCls("loginPageErrorShower");
                loginDispatcher(resetLogin());
            }, 5000);
            l_page++;
        }

        if(!validEmail(email_value)){
            setErrorShowerCls("loginPageErrorShowerSelected");
            loginDispatcher(invalidLogin("Invalid Email"));
            setTimeout(() => {
                setErrorShowerCls("loginPageErrorShower");
                loginDispatcher(resetLogin());
            }, 5000);
            l_page++;
        }

        if(!validPassword(password_value)){
            setErrorShowerCls("loginPageErrorShowerSelected");
            loginDispatcher(invalidLogin("password should contain all type of characters and it should be 8 character long"));
            setTimeout(() => {
                setErrorShowerCls("loginPageErrorShower");
                loginDispatcher(resetLogin());
            }, 5000);
            l_page++;
        }

        if(l_page === 0){

            let login_data = new FormData();
            login_data.append("username",email_value);
            login_data.append("password",password_value);
            
            sendLogin(login_data);
        
        }

    }

    function validEmail(email){

        let emailRegex = /[a-zA-Z0-9\-_.]+@(gmail|hotmail|yahoo|rediffmail)+(?:\.(com))/

        let valid = emailRegex.test(email);

        return valid;
    }

    function validPassword(password){
        
        let passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@_=*[\]{}.+-])[\w\d@_=*[\]{}.+-]{6,}/

        let valid = passwordRegex.test(password);

        return valid;
    }

    function sendLogin(loginData){

        axios({
            url: kapi.loginapi,
            method: kapi.post,
            data: loginData,
            headers:{
                "Content-Type":"multipart/form-data",
            },
            withCredentials: true,
        }).then(result => {
            if(result.status !== 200 || result.status === undefined || result.data.success === false){
                throw new Error(result);
            }
            handleSuccessfulLogin(result);
        }).catch(error => {
            if(error.response === undefined){
                setErrorShowerCls("loginPageErrorShowerSelected");
                loginDispatcher(invalidLogin("Something Is Wrong With Server or Please Check Your email/password and try again"));
                setTimeout(() => {
                    setErrorShowerCls("loginPageErrorShower");
                    loginDispatcher(resetLogin());
                }, 5000);
            }
            if(error.response !== null || error.response !== undefined){
                switch(error.response.status){
                    case 409:
                        setErrorShowerCls("loginPageErrorShowerSelected");
                        loginDispatcher(invalidLogin("User is already logged in"));
                        loginDispatcher(invalidLogin("login after one week from the time you last logged in"));
                        setTimeout(() => {
                            setErrorShowerCls("loginPageErrorShower");
                            loginDispatcher(resetLogin());
                        }, 5000);
                        break;
                    case 401:
                        if(error.response.data !== null){
                            setErrorShowerCls("loginPageErrorShowerSelected");
                            if(error.response.data.error !== undefined){
                                loginDispatcher(invalidLogin(error.response.data.error));
                            }else{
                                loginDispatcher(invalidLogin("invalid login credentials please contact the administrator"));
                            }
                            setTimeout(() => {
                                setErrorShowerCls("loginPageErrorShower");
                                loginDispatcher(resetLogin());
                            }, 10000);
                        }else{
                            setErrorShowerCls("loginPageErrorShowerSelected");
                            loginDispatcher(invalidLogin("Please Check Your email/password and try again"));
                            setTimeout(() => {
                                setErrorShowerCls("loginPageErrorShower");
                                loginDispatcher(resetLogin());
                            }, 5000);
                        }
                        break;
                    default:
                        setErrorShowerCls("loginPageErrorShowerSelected");
                        loginDispatcher(invalidLogin("Something Is Wrong With Server or Please Check Your email/password and try again"));
                        setTimeout(() => {
                            setErrorShowerCls("loginPageErrorShower");
                            loginDispatcher(resetLogin());
                        }, 5000);
                }
            }
        });
    }

    function handleSuccessfulLogin(result){

        let token = result.data.token;

        localStorage.setItem("kapt",token);
        
        loginNavigatation("/kal/admin/dashboard");
    }

    return(
        <div className="kalniyojanLoginPageContainer">
            <div className="kalniyojanLoginPageContainerContent">
                <h1 className="LoginPageHeader">
                    Loginpage
                </h1>

                <div className={errorShowerCls}>
                    {Array.from(loginSelector).map((error,index) => 
                        <div key={index}>{error}</div>
                    )}
                </div>
                    
                <form onSubmit={event => handleKalniyojanLogin(event)}>
                    <div className="loginEmailRow">
                        <label htmlFor="email">Email:</label>
                        <input type="text" name="username" ref={emailref}/>
                    </div>
                    <div className="loginPasswordRow">
                        <label htmlFor="password">Password:</label>
                        <input type="password" name="password" ref={passwordref}/>
                    </div>
                    <div className="loginBtn">
                        <button type="submit" value="submit">Login</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Loginpage;
