
import { useEffect, useState } from "react";
import "./AdminAdvice.css"
import solved from "../images/successtick.svg";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ServicesAction from "../Redux/Action/ServicesAction";
import { aAdviceError, resetAdminAdviceError } from "../Redux/Action/ErrorAction";
import kapi from "../Redux/kalniyojanapi";

function AdminAdvice(props){

    let adminAdviceTabClsName = "adminAdviceContainerSelected";

    if(!props.show){
        adminAdviceTabClsName = "adminAdviceContainer";
    }

    let adminAdviceDispatcher = useDispatch();
    let adminAdviceSelector;
    let [adminAdviceErrorCls,setAdminAdviceErrorCls] = useState("adminAdviceErrorShower");

    let adminAdviceHandler = {

        getAdviceClients: function(){
            
            let adminAdviceKapt = localStorage.getItem("kapt");

            if(adminAdviceKapt === null){
                window.location.href = "/login";
            }
            
            axios({
                method: kapi.get,
                url: kapi.adminadvicelistapi,
                headers: {
                    Authorization: `Bearer ${adminAdviceKapt}`,
                },
                withCredentials:true,
            }).then(result => {
                if(result.status !== 200){
                    throw new Error(result);
                }
                adminAdviceDispatcher(ServicesAction.setAdvice(result));
            }).catch(error => {
                setAdminAdviceErrorCls("adminAdviceErrorShowerSelected");
                if(error.response !== undefined){
                    adminAdviceDispatcher(aAdviceError(error.response.data.message));
                    setTimeout(() => {
                        setAdminAdviceErrorCls("adminAdviceErrorShower");
                        adminAdviceDispatcher(resetAdminAdviceError());
                    }, 8000);
                }else{
                    adminAdviceDispatcher(aAdviceError("something is wrong, failed to fetch advice clients data"));
                    setTimeout(() => {
                        setAdminAdviceErrorCls("adminAdviceErrorShower");
                        adminAdviceDispatcher(resetAdminAdviceError());
                    }, 8000);
                }
            });
        },

        handleSolvedAdvice: function(adviceUuid){
            let adviceCompleteKapt = localStorage.getItem("kapt");
            if(adviceCompleteKapt === null){
                window.location.href = "/login";
            }
            axios({
                method: kapi.post,
                url: kapi.adminadvicecompleteapi,
                data:{
                    "key": adviceUuid,
                },
                headers:{
                    "Authorization": `Bearer ${adviceCompleteKapt}`,
                    "Content-Type":"application/json",
                },
                withCredentials: true,
            }).then(result => {
                if(result.status !== 200){
                    throw new Error(result);
                }
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            }).catch(error => {
                setAdminAdviceErrorCls("adminAdviceErrorShowerSelected");
                if(error.response !== undefined){
                    if(error.response.data === ""){
                        localStorage.removeItem("kapt");
                        window.location.href = "/";
                    }
                    if(error.response.data === false && error.response.status === 406){
                        adminAdviceDispatcher(aAdviceError("can not do this action may be this client data not available"));
                        setTimeout(() => {
                            setAdminAdviceErrorCls("adminAdviceErrorShower");
                            adminAdviceDispatcher(resetAdminAdviceError());
                        }, 5000);
                    }

                }else{
                    adminAdviceDispatcher(aAdviceError("something is wrong may be server is down,try later"));
                    setTimeout(() => {
                        setAdminAdviceErrorCls("adminAdviceErrorShower");
                        adminAdviceDispatcher(resetAdminAdviceError());
                    }, 5000);
                }
            });
        },
    };

    useEffect(() => {
        let adviceIsAuth = localStorage.getItem("kapt");

        if(adviceIsAuth === null || adviceIsAuth === undefined){
            window.location.href = "/login";
        }

        adminAdviceHandler.getAdviceClients();
    },[adminAdviceSelector]);

    adminAdviceSelector = useSelector(state => state.services.advice);
    let adminAdviceErrorSelector = useSelector(state => state.errors.aAdvice);

    return(
        <div className={adminAdviceTabClsName}>
            <div className="adminAdviceContainerContent">
                <div className={adminAdviceErrorCls}>
                    {Array.from(adminAdviceErrorSelector).map((item,index) => 
                        <div key={index}>{item}</div>
                    )}
                </div>
                {Array.from(adminAdviceSelector).length > 0 ? 
                
                <table className="adminAdviceTable">
                    <thead>
                        <tr>
                            <td>Name</td>
                            <td>Date</td>
                            <td>Time</td>
                            <td>Place</td>
                            <td>Country</td>
                            <td className="tableHeadEmailTd">Email</td>
                            <td className="tableHeadPhoneTd">Phone</td>
                            <td className="tableHeadPhoneCcTd">Phone CC</td>
                            <td>Question</td>
                            <td className="tableHeadActionTd">Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(adminAdviceSelector).map(item => 
                            <tr key={item.clientKey}>
                                <td>{item.clientName}</td>
                                <td>{item.clientBirthDate}</td>
                                <td>{item.clientBirthTime}</td>
                                <td>{item.clientBirthPlace}</td>
                                <td>{item.clientBirthCountry}</td>
                                <td className="tableBodyEmailTd">{item.clientEmail}</td>
                                <td className="tableBodyPhoneTd">{item.clientPhone}</td>
                                <td className="tableBodyPhoneCcTd">{item.clientPhoneCountryCode}</td>
                                <td>{item.clientQuestion}</td>
                                <td className="adminAdviceAction">
                                    <button className="adviceSolved" name="adviceSolved" title="solved" onClick={() => adminAdviceHandler.handleSolvedAdvice(item.clientKey)}>
                                        <img src={solved} className="adminAdviceSolvedImg" alt="solvedAdvice" />
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table> : <div className="adminEmptyAdvice">No one asked for advice!!!</div>
            }
            </div>
        </div>
    );

}

export default AdminAdvice;