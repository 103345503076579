import React from 'react';
import './About.css';

class About extends React.Component{
    constructor(props){
        super(props);
        this.state = {containerName: "kalniyojanAboutContainer"}
    }

    render(){
        return(
            <div className={this.state.containerName}>
                <h1 className='kalniyojanAboutHeader'>
                    <strong>About KALNIYOJAN</strong>
                </h1>

                <div className='kalniyojanAboutBody'>
                    <p className='kalniyojanAbout'>
                        With the blessing of shree gurudev datta and shree ganesh as well as with the help of you people, the founder of KALNIYOJAN 
                        <strong> Laxmikant Motisa Kolhapure</strong> publish <span>Kalniyojan </span>
                        panchang with more information for everyone to understand and provide Other astrology services worldwide. it's been 9 years we publishing kalniyojan panchang, "2024" year will be the 10<sub>th</sub> year of <span>kalniyojan panchang</span><br/><br/>
                        The main aim of Kalniyojan panchang is with the help of panchang mathematics every person should understand his/her 
                        life value and the estimate/prediction of things/eventuality that will come to his/her path in the life and the future.
                        and solving those things with positivity every person should do the planning(niyojan) of karma and time(kal) so the planning
                        of future is KALNIYOJAN.<br/><br/>
                        The meaning of kal here is(The Continuously moving time) and niyojan here is(positivity).<br/><br/>
                        Instead of believing in ritual(karmakand) Every person should do the kalniyojan. we do not say you should not belive in 
                        your festival, traditions, customs we say be aware of superstitions.
                    </p>
                </div>
            </div>
        );
    }
}


export default About;