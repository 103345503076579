import { useNavigate } from "react-router-dom";

import "./Sidebar.css";
import closebtn from "../images/close-line.svg";


const Sidebar = (props) => {

    const visible = props.show;
    let cls = "novisible";

    if(visible){
       cls = "visible";
    }

    let navigation = useNavigate();

    return(
        <div className={cls}>
            <div className="closeBtn"><img src={closebtn} alt="sideBarCrossIcon" onClick={props.click}/></div>
            <div className="sidebarLinks" onClick={props.click}>
                <div className="sidebarContents" onClick={() => navigation("/article")}>
                    article
                </div>
                <div className="sidebarContents" onClick={() => navigation("/services")}>
                    services
                </div>
                <div className="sidebarContents" onClick={() => navigation("/about")}>
                    about
                </div>
                <div className="sidebarContents" onClick={() => navigation("/contact")}>
                    contact
                </div>
                <div className="sidebarContents" onClick={() => navigation("/donate")}>
                    Donate
                </div>
            </div>
        </div>
    );
}

export default Sidebar;