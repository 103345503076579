
import { useDispatch, useSelector } from "react-redux";
import "./AdminMatchMaking.css";
import { useEffect, useState } from "react";
import axios from "axios";
import kapi from "../Redux/kalniyojanapi";
import ServicesAction from "../Redux/Action/ServicesAction";
import { aMatchMakingError, resetAMatchMakingError } from "../Redux/Action/ErrorAction";
import matchMakingSolved from '../images/successtick.svg';

function AdminMatchMaking(props){

    let adminMatchmakingTabCls = "adminMatchmakingContainerSelected"

    if(!props.show){
        adminMatchmakingTabCls = "adminMatchmakingContainer";
    }

    let adminMatchMakingSelector;
    let adminMatchMakingDispatcher = useDispatch();
    let [adminMatchMakingErrorCls, SetAdminMatchMakingErrorCls] = useState("adminMatchmakingErrorShower");

    let adminMatchMakingHandler = {

        getMatchMakingClients: function(){

            let adminMatchMakingKapt = localStorage.getItem("kapt");

            if(adminMatchMakingKapt === null){
                window.location.href = "/login";
            }

            axios({
                method: kapi.get,
                url: kapi.adminmatchmakinglistapi,
                headers: {
                    Authorization: `Bearer ${adminMatchMakingKapt}`,
                },
                withCredentials: true,
            }).then(result => {
                if(result.status !== 200){
                    throw new Error(result);
                }
                adminMatchMakingDispatcher(ServicesAction.setMatchMaking(result));
            }).catch(error => {
                SetAdminMatchMakingErrorCls("adminMatchmakingErrorShowerSelected");
                if(error.response !== undefined){
                    adminMatchMakingDispatcher(aMatchMakingError(error.response.data.message));
                    setTimeout(() => {
                        SetAdminMatchMakingErrorCls("adminMatchmakingErrorShower");
                        adminMatchMakingDispatcher(resetAMatchMakingError());
                    }, 8000);
                }else{
                    adminMatchMakingDispatcher(aMatchMakingError("something is wrong failed to fetch data"));
                    setTimeout(() => {
                        SetAdminMatchMakingErrorCls("adminMatchmakingErrorShower");
                        adminMatchMakingDispatcher(resetAMatchMakingError());
                    }, 8000);
                }
            });
        },

        handleSolvedMatchMaking: function(key){
            let mmKapt = localStorage.getItem("kapt");
            if(mmKapt === null){
                window.location.href = "/login";
            }

            axios({
                method: kapi.post,
                url: kapi.adminmatchmakingcompleteapi,
                data:{
                    "key": key,
                },
                headers:{
                    "Content-Type":"application/json",
                    "Authorization": `Bearer ${mmKapt}`,
                },
                withCredentials: true,
            }).then(result => {
                if(result.status !== 200){
                    throw new Error(result);
                }
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            }).catch(error => {
                SetAdminMatchMakingErrorCls("adminMatchmakingErrorShowerSelected");
                if(error.response !== undefined){
                    if(error.response.data === ""){
                        localStorage.removeItem("kapt");
                        window.location.href = "/";
                    }
                    if(error.response.data === false && error.response.status === 406){
                        adminMatchMakingDispatcher(aMatchMakingError("can not perform this action may be this client data not available"))
                        setTimeout(() => {
                            SetAdminMatchMakingErrorCls("adminMatchmakingShower");
                            adminMatchMakingDispatcher(resetAMatchMakingError());
                        }, 8000);
                    }
                }else{
                    adminMatchMakingDispatcher(aMatchMakingError("something went wrong may be server is down, try later"));
                    setTimeout(() => {
                        SetAdminMatchMakingErrorCls("adminMatchmakingErrorShower");
                        adminMatchMakingDispatcher(resetAMatchMakingError());
                    }, 8000);
                }
            });

        }
    }

    useEffect(() => {

        let adviceIsAuth = localStorage.getItem("kapt");

        if(adviceIsAuth === null || adviceIsAuth === undefined){
            window.location.href = "/login";
        }

        adminMatchMakingHandler.getMatchMakingClients();
    },[adminMatchMakingSelector])

    adminMatchMakingSelector = useSelector(state => state.services.matchMaking);
    let adminMatchMakingErrorSelector = useSelector(state => state.errors.aMatchMaking);

    return(
        <div className={adminMatchmakingTabCls}>
            <div className="adminMatchMakingContainerContent">

                <div className={adminMatchMakingErrorCls}>
                    {Array.from(adminMatchMakingErrorSelector).map((item,index) => 
                        <div key={index}>{item}</div>
                    )}
                </div>

            {Array.from(adminMatchMakingSelector).length > 0 ? 
            
                <table id="adminMatchmakingTable">
                    <thead>
                        <tr id="matchMakingTableHeadHeader">
                            <td>Person</td>
                            <td>Name</td>
                            <td>Email/Date</td>
                            <td>Phone/Time</td>
                            <td>Birth City</td>
                            <td>Country</td>
                            <td id="adminMatchmakingActionBtnHeader">Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from(adminMatchMakingSelector).map(item => 
                            <tr key={item.key}>
                                <td id="matchMakingClientsRow">
                                    <div>Client</div>
                                    <div>Male</div>
                                    <div>Female</div>
                                </td>
                                <td id="matchMakingNameRow">
                                    <div id="matchMakingNameFirstChild">{item.clientName}</div>
                                    <div id="matchMakingNameSecChild">{item.maleName}</div>
                                    <div>{item.femaleName}</div>
                                </td>
                                <td id="matchMakingEmailDateRow">
                                    <div id="matchMakingEDFirstChild">{item.clientEmail}</div>
                                    <div id="matchMakingEDSecChild">{item.maleDate}</div>
                                    <div>{item.femaleDate}</div>
                                </td>
                                <td id="matchMakingPhoneTimeRow">
                                    <div id="matchMakingPTFirstChild">{item.clientPhone}</div>
                                    <div id="matchMakingPTSecChild">{item.maleTime}</div>
                                    <div>{item.femaleTime}</div>
                                </td>
                                <td id="matchmakingPlaceRow">
                                    <div>---</div>
                                    <div>{item.malePlace}</div>
                                    <div>{item.femalePlace}</div>
                                </td>
                                <td id="matchMakingCountryRow">
                                    <div>---</div>
                                    <div>{item.maleCountry}</div>
                                    <div>{item.femaleCountry}</div>
                                </td>
                                <td id="matchMakingAction">
                                    <button className="matchMakingSolved" name="matchMakingSolved" title="solved" onClick={() => adminMatchMakingHandler.handleSolvedMatchMaking(item.key)}>
                                        <img src={matchMakingSolved} className="matchMakingSolvedImg" alt="matchMakingSolvedImg"/>
                                    </button>
                                </td>
                            </tr>
                            
                        )}
                    </tbody>
                </table> : <div className="adminEmptyMatchmaking">No one send for matchMaking!!!</div>}
            </div>
        </div>
    );
}

export default AdminMatchMaking;