import { useRef, useState } from "react";
import "./AdminAddArticle.css"
import { useDispatch, useSelector } from "react-redux";
import { addNewArticleFormError, resetNewArticleError } from "../Redux/Action/ErrorAction";
import axios from "axios";
import { isBoolean } from "lodash";
import kapi from "../Redux/kalniyojanapi";


function AdminAddArticle(props){

    let d_star = 0;

    let adminAddArticleContainer = "kalniyojanAdminAddArticleContainerSelected";

    if(!props.show){
        adminAddArticleContainer = "kalniyojanAdminAddArticleContainer";
    }

    let [errorShower, setErrorShower] = useState("adminAddArticleErrorShower");
    let [addArticleFile, SetAddArticleFile] = useState(null);

    let articleNameRef = useRef(null);
    let articlePublishYearRef = useRef(null);
    let articlePublishMonthRef = useRef(null);
    let articlePublishDayRef = useRef(null);

    let errorDispatcher = useDispatch();
    
    let addArticleErrors = useSelector(state => state.errors.newArticleError);

    function handleAddNewArticleForm(event){

        event.preventDefault();

        errorDispatcher(resetNewArticleError());
        setErrorShower("adminAddArticleErrorShower");

        if(articleNameRef.current.value === null || !validateArticleName(articleNameRef.current.value)){
            setErrorShower("adminAddArticleErrorShowerSelected");
            errorDispatcher(addNewArticleFormError("name must be greater than 4 char!"));
            setTimeout(() => {
                setErrorShower("adminAddArticleErrorShower");
                errorDispatcher(resetNewArticleError());
            }, 5000);
            d_star++;
        }


        if(articlePublishYearRef.current.value === null || isNaN(articlePublishYearRef.current.value) || !validateYear(articlePublishYearRef.current.value)){
            setErrorShower("adminAddArticleErrorShowerSelected");
            errorDispatcher(addNewArticleFormError("year is not valid!!!"));
            setTimeout(() => {
                setErrorShower("adminAddArticleErrorShower");
                errorDispatcher(resetNewArticleError());
            }, 5000);
            d_star++;
        }

        if(articlePublishMonthRef.current.value === null || isNaN(articlePublishMonthRef.current.value) || !validateMonth(articlePublishMonthRef.current.value,articlePublishYearRef.current.value)){
            setErrorShower("adminAddArticleErrorShowerSelected");
            errorDispatcher(addNewArticleFormError("month is not valid!!!"));
            setTimeout(() => {
                setErrorShower("adminAddArticleErrorShower");
                errorDispatcher(resetNewArticleError());
            }, 5000);
            d_star++;
        }

        if(articlePublishDayRef.current.value === null || isNaN(articlePublishDayRef.current.value) || !validateDayOfMonth(articlePublishYearRef.current.value, articlePublishMonthRef.current.value, articlePublishDayRef.current.value)){
            setErrorShower("adminAddArticleErrorShowerSelected");
            errorDispatcher(addNewArticleFormError(`day is not valid, check filled date!!!`));
            setTimeout(() => {
                setErrorShower("adminAddArticleErrorShower");
                errorDispatcher(resetNewArticleError());
            }, 5000);
            d_star++;
        }

        if(!validateDate(articlePublishYearRef.current.value,articlePublishMonthRef.current.value,articlePublishDayRef.current.value)){
            setErrorShower("adminAddArticleErrorShowerSelected");
            errorDispatcher(addNewArticleFormError("Please provide valid date!!!"));
            setTimeout(() => {
                setErrorShower("adminAddArticleErrorShower");
                errorDispatcher(resetNewArticleError());
            }, 5000);
            d_star++;
        }

        if(addArticleFile === null || addArticleFile.type !== "application/pdf"){
            setErrorShower("adminAddArticleErrorShowerSelected");
            errorDispatcher(addNewArticleFormError(`article file should be of type PDF!!!`));
            setTimeout(() => {
                setErrorShower("adminAddArticleErrorShower");
                errorDispatcher(resetNewArticleError());
            }, 5000);
            d_star++;
        }

        if(newArticleFileSize(addArticleFile)){
            setErrorShower("adminAddArticleErrorShowerSelected");
            errorDispatcher(addNewArticleFormError("check file size is less than 20MB!!!"));
            setTimeout(() => {
                setErrorShower("adminAddArticleErrorShower");
                errorDispatcher(resetNewArticleError());
            }, 5000);
            d_star++;
        }

        if(d_star === 0){

            let new_article_formData = new FormData();
            new_article_formData.append("articleName",articleNameRef.current.value);
            new_article_formData.append("articlePublishYear",articlePublishYearRef.current.value);
            new_article_formData.append("articlePublishMonth",articlePublishMonthRef.current.value);
            new_article_formData.append("articlePublishDay",articlePublishDayRef.current.value);

            if(addArticleFile !== null){
                new_article_formData.append("articlefile",addArticleFile);
            }

            addNewArticle(new_article_formData);
        }

    }

    function newArticleFileSize(file){
        return file.size > 20971520 ? true : false;
    }

    function validateArticleName(article_name){

        let articleNameRegex = /[a-zA-Z0-9_\-.]{4,255}/i

        let isValidEmail = articleNameRegex.test(article_name);

        return isValidEmail;

    }

    function validateYear(year){
        if(year === ""){
            return false;
        }
        let date = new Date();
        return year > date.getFullYear() ? false : true;
    }

    function validateMonth(month,year){

        if(month === ""){
            return false;
        }

        let date = new Date();

        if(month > 12 || (year > date.getFullYear() && (month - 1) >= date.getMonth())){
            return false;
        }

        if(year <= date.getFullYear() && (month - 1) <= date.getMonth()){
            return true;
        }

        if(month <= 12 && (year < date.getFullYear() && ((month - 1) > date.getMonth() || (month - 1) < date.getMonth() || (month - 1) === date.getMonth()))){
            return true;
        }

        if(year === date.getFullYear() && (month - 1) > date.getMonth()){
            return false;
        }
    }

    function validateDayOfMonth(year,month,day){

        if(day === ""){
            return false;
        }

        if(month > 12){
            return false;
        }
        
        let days_in_provided_month = getDaysInMonth(year,month);

        if(isBoolean(days_in_provided_month)){
            return days_in_provided_month;
        }

        return day > days_in_provided_month ? false : true;
    }

    function validateDate(year,month,day){

        if(year > new Date().getFullYear() || month > 12){
            return false;
        }

        let days_in_provided_month = getDaysInMonth(year,month);


        if(isBoolean(days_in_provided_month) || day > days_in_provided_month){
            return false;
        }

        let usr_date = [year,month,day].join("-");

        let date_obj_of_usr_date = new Date(usr_date);

        return date_obj_of_usr_date.valueOf() > new Date().valueOf() ? false : true;
    }

    function getDaysInMonth(yer,mnth){

        let is_leap_year = false;
        let months = getMonths();

        if(yer % 4 === 0){
            is_leap_year = true;
        }

        let months_map = new Map(Array.from(Object.entries(months)));

        if(is_leap_year){
            let temp_months_keys_array =  Array.from(months_map.keys());

            let alter_feb = temp_months_keys_array.find(item => item === "Feb");

            if(alter_feb !== null || alter_feb !== undefined){
                months[alter_feb] = 29;
            }
        }

        let months_keys_array = Array.from(months_map.keys());

        let user_expected_month = months_keys_array[(mnth - 1)];

        if(typeof user_expected_month === "string"){
            return months[user_expected_month];
        }else{
            return false;
        }
    }

    function getMonths(){
        return {
        "Jan" : 31,
        "Feb" : 28,
        "Mar" : 31,
        "Apr" : 30,
        "May" : 31,
        "Jun" : 30,
        "Jul" : 31,
        "Aug" : 31,
        "Sep" : 30,
        "Oct" : 31,
        "Nov" : 30,
        "Dec" : 31,
        };
    }

    function handleAddArticleFile(event){
        SetAddArticleFile(event.target.files[0]);
    }

    function addNewArticle(new_article_formData){

        let kapt = localStorage.getItem("kapt");

        axios({
            method: kapi.post,
            url: kapi.adminaddarticleapi,
            data: new_article_formData,
            headers: {
                "Content-Type":"multipart/form-data",
                "Authorization" : `Bearer ${kapt}`,
            },
            withCredentials: true,
        }).then(result => {
            if(result.status !== 200){
                throw new Error(result);
            }else{
                window.location.reload();
            }
        }).catch(error => {
            setErrorShower("adminAddArticleErrorShowerSelected");
            if(error.response !== undefined){
                if(error.response.data === ""){
					localStorage.removeItem("kapt");
					window.location.href = "/";
                }
                if(error.response.data === false && error.response.status === 406){
                    errorDispatcher(addNewArticleFormError("article name already exist"));
			        setTimeout(() => {
				     setErrorShower("adminAddArticleErrorShower");
                     errorDispatcher(resetNewArticleError());
			        }, 5000);
                }
            }else{
                errorDispatcher(addNewArticleFormError("something is wrong may be server is down, please try later"));
			    setTimeout(() => {
				    setErrorShower("adminAddArticleErrorShower");
                    errorDispatcher(resetNewArticleError());
			    }, 5000);
            }
        });
    }


    return(
        <div className={adminAddArticleContainer}>
            <div className={errorShower}>
                {addArticleErrors.map((error,index) => 
                    <div key={index}>{error}</div>
                )}
            </div>
            <form onSubmit={event => handleAddNewArticleForm(event)} >
                <div className="adminAddArticleNameRow">
                    <label htmlFor="articlenamelabel"><span>*</span> Article Name:</label>
                    <input type="text" name="articleName" className="adminAddArticleName" ref={articleNameRef}/>
                </div>
                <div className="adminAddArticlePublishDateRow">
                    <div className="adminArticlePublishYear">
                        <label htmlFor="articleyearlabel"><span>*</span> Year:</label>
                        <input type="number" name="articlePublishYear" className="adminArticleYear" ref={articlePublishYearRef}/>
                    </div>
                    <div className="adminArticlePublishMonth">
                        <label htmlFor="articlemonthlabel"><span>*</span> Month:</label>
                        <input type="number" name="articlePublishMonth" className="adminArticleMonth" ref={articlePublishMonthRef}/>
                    </div>
                    <div className="adminArticlePublishDay">
                        <label htmlFor="articledaylabel"><span>*</span> Day:</label>
                        <input type="number" name="articlePublishDay" className="adminArticleDay" ref={articlePublishDayRef}/>
                    </div>
                </div>
                <div className="adminAddArticleImage">
                    <label htmlFor="articleimagelabel"><span>*</span> ArticleFile:</label>
                    <input type="file" name="articlefile" className="adminAddArticleImage" onChange={event => handleAddArticleFile(event)}/>
                </div>
                <div className="kalniyojanAdminAddArticleSubmitBtn">
                    <button value="submit" type="submit">Submit</button>
                </div>
            </form>
        </div>
    );
}

export default AdminAddArticle;