
import ErrorTypes from "../Type/ErrorTypes";


function addAdminArticleListError(error) {
    return {
        type: ErrorTypes.newArticleListError,
        payload: error,
    };
}

function addBaseArticleListError(error) {
    return {
        type: ErrorTypes.newArticleListError,
        payload: error,
    };
}

function resetArticleListError() {
    return {
        type: ErrorTypes.resetArticleList,
        payload: [],
    };
}

function adminDeleteArticleError(error) {
    return {
        type: ErrorTypes.deleteArticleError,
        payload: error,
    };
}

function resetAdminDeleteArticleError() {
    return {
        type: ErrorTypes.resetDeleteArticle,
        payload: [],
    };
}

function addNewArticleFormError(error) {

    return {
        type: ErrorTypes.newAritcleFormError,
        payload: error,
    };
}

function addMainPageImgFormError(error) {
    return {
        type: ErrorTypes.mainPageFormError,
        payload: error,
    };
}

function resetNewArticleError() {
    return {
        type: ErrorTypes.resetNewArticle,
        payload: [],
    };
}

function resetMainPageError() {
    return {
        type: ErrorTypes.resetMainPage,
        payload: [],
    };
}

function updateArticleError(error) {
    return {
        type: ErrorTypes.updateArticleError,
        payload: error,
    };
}

function resetUpdateArticleError() {
    return {
        type: ErrorTypes.resetUpdateArticleError,
        payload: [],
    };
}

function logoutError(error) {
    return {
        type: ErrorTypes.logoutError,
        payload: error,
    };
}

function resetLogout() {
    return {
        type: ErrorTypes.resetLogoutError,
        payload: [],
    };
}

function invalidLogin(error) {
    return {
        type: ErrorTypes.invalidLogin,
        payload: error,
    };
}

function resetLogin() {
    return {
        type: ErrorTypes.resetLogin,
        payload: [],
    };
}

function resetAllError() {
    return {
        type: ErrorTypes.resetAll,
        payload: [],
    };
}

let mainPageError = error => {
    return {
        type: ErrorTypes.mainPageError,
        payload: error,
    };
}

let adviceError = error => {
    return {
        type: ErrorTypes.adviceError,
        payload: error,
    };
}

function aAdviceError(error){
    return{
        type: ErrorTypes.aAdviceError,
        payload: error,
    };
}

function resetAdminAdviceError(){
    return{
        type:ErrorTypes.resetAAdviceError,
        payload:[],
    };
}

let resetAdviceError = () => {
    return {
        type: ErrorTypes.resetAdviceError,
        payload: [],
    };
}

function advicePaymentError(error) {
    return{
        type: ErrorTypes.advicePaymentError,
        payload: error,
    };
}

function resetAdvicePaymentError(){
    return{
        type: ErrorTypes.resetAdvicePaymentError,
        payload:[],
    };
}

function matchMakingError(error){
    return{
        type: ErrorTypes.matchMakingError,
        payload: error,
    };
}

function matchMakingPaymentError(error){
    return {
        type: ErrorTypes.matchMakingPaymentError,
        payload: error,
    };
}

function resetMatchMakingError(){
    return{
        type: ErrorTypes.resetMatchMakingError,
        payload: [],
    };
}

function resetMatchMakingPaymentError(){
    return{
        type: ErrorTypes.resetMatchMakingPaymentError,
        payload: [],
    };
}

function aMatchMakingError(error){
    return{
        type: ErrorTypes.aMatchMakingError,
        payload: error,
    };
}

function resetAMatchMakingError(){
    return{
        type: ErrorTypes.resetAMatchMakingError,
        payload:[],
    };
}

function janmaPatrikaError(error){
    return{
        type: ErrorTypes.janmaPatrikaError,
        payload: error,
    };
}

function resetJanmaPatrikaError(){
    return{
        type: ErrorTypes.resetJanmaPatrikaError,
        payload:[],
    };
}

function setJanmaPatrikaPaymentError(error){
    return{
        type: ErrorTypes.setJanmaPatrikaPaymentError,
        payload: error,
    };
}

function resetJanmaPatrikaPaymentError(){
    return{
        type: ErrorTypes.resetJanmaPatrikaPaymentError,
        payload: [],
    };
}

function setMuhurtaError(error){
    return{
        type: ErrorTypes.setMuhurtaError,
        payload: error,
    };
}

function resetMuhurtaError(){
    return{
        type:ErrorTypes.resetMuhurtaError,
        payload:[],
    };
}

function setMuhurtaPaymentError(error){
    return{
        type: ErrorTypes.setMuhurtaPaymentError,
        payload: error,
    };
}

function resetMuhurtaPaymentError(){
    return{
        type: ErrorTypes.resetMuhurtaPaymentError,
        payload: [],
    };
}

function setAdminPatrikaError(error){
    return{
        type:ErrorTypes.aPatrikaError,
        payload: error,
    };
}

function resetAPatrikaError(){
    return{
        type: ErrorTypes.resetAPatrikaError,
        payload: [],
    };
}

function setAmuhurtaError(data){
    return{
        type: ErrorTypes.setAmuhurtaError,
        payload: data,
    };
}

function resetAmuhurtaError(){
    return{
        type: ErrorTypes.resetAmuhurtaError,
        payload: [],
    };
}

function setFeedbackError(error){
    return{
        type: ErrorTypes.setFeedbackError,
        payload: error,
    };
}

function resetFeedbackError(){
    return{
        type: ErrorTypes.resetFeedbackError,
        payload: [],
    };
}

function setRefundCancellationError(error){
    return{
        type:ErrorTypes.setRefundCancellationError,
        payload: error,
    };
}

function resetRefundCancellationError(){
    return{
        type: ErrorTypes.resetRefundCancellationError,
        payload: [],
    };
}

function setAFeedbackError(error){
    return{
        type: ErrorTypes.setAFeedbackError,
        payload: error,
    };
}

function resetAFeedbackError(){
    return{
        type: ErrorTypes.resetAFeedbackError,
        payload: [],
    };
}

function setVastuPaymentError(error){
    return{
        type: ErrorTypes.setVastuPaymentError,
        payload: error,
    };
}

function resetVastuPaymentError(){
    return{
        type: ErrorTypes.setVastuPaymentError,
        payload: [],
    };
}

function setVastuError(error){
    return{
        type: ErrorTypes.setVastuError,
        payload: error,
    };
}

function resetVastuError(){
    return{
        type: ErrorTypes.resetVastuError,
        payload: [],
    };
}
export {
    addAdminArticleListError,
    resetUpdateArticleError,
    adminDeleteArticleError,
    addBaseArticleListError,
    resetArticleListError,
    addNewArticleFormError,
    addMainPageImgFormError,
    updateArticleError,
    mainPageError,
    adviceError,
    aAdviceError,
    resetAdminAdviceError,
    resetAdviceError,
    resetNewArticleError,
    resetAdminDeleteArticleError,
    resetMainPageError,
    invalidLogin,
    logoutError,
    resetLogout,
    resetLogin,
    resetAllError,
    advicePaymentError,
    resetAdvicePaymentError,
    matchMakingError,
    matchMakingPaymentError,
    resetMatchMakingError,
    resetMatchMakingPaymentError,
    aMatchMakingError,
    resetAMatchMakingError,
    janmaPatrikaError,
    resetJanmaPatrikaError,
    setJanmaPatrikaPaymentError,
    resetJanmaPatrikaPaymentError,
    setMuhurtaError,
    resetMuhurtaError,
    setMuhurtaPaymentError,
    resetMuhurtaPaymentError,
    setAdminPatrikaError,
    resetAPatrikaError,
    setAmuhurtaError,
    resetAmuhurtaError,
    setFeedbackError,
    resetFeedbackError,
    setRefundCancellationError,
    resetRefundCancellationError,
    setAFeedbackError,
    resetAFeedbackError,
    setVastuPaymentError,
    resetVastuPaymentError,
    setVastuError,
    resetVastuError,
};
