
import "./ArticleDownloadSupport.css";
import paymentQR from "../paymentQR.png";

const articleDownloadSuccessfulEventNavigation = props => {

    return(
        <div className="articleSuccessfulDownloadContainer">
            <div className="articleSuccessfulDownloadContainerContent">
                <div className="articleCommertialSupport">
                    If you like the contents of article and 
                    if you like our services support us (with Donation).
                </div>
                <img src={paymentQR} className="articleDownloadSupportQrImg" alt="articleSupportQr"/>
            </div>
        </div>
    );
}

export default articleDownloadSuccessfulEventNavigation;