import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllArticle, resetUpdateArticleData } from "../Redux/Action/ArticleAction";
import {addAdminArticleListError, adminDeleteArticleError, resetArticleListError, resetAdminDeleteArticleError, resetUpdateArticleError} from "../Redux/Action/ErrorAction";
import { useNavigate } from "react-router-dom";

import "./AdminArticleList.css";

import DeleteBtn from "../images/delete.svg";
import UpdateBtn from "../images/update.svg";
import kapi from "../Redux/kalniyojanapi";

function AdminArticleList(props){

    let adminArticleListContainerClassName = "kalniyojanAdminContainerArticleContentSelected";
    let adminArticleSelector = null;

    if(!props.show){
        adminArticleListContainerClassName = "kalniyojanAdminContainerArticleContent";
    }

    let [articleListError, SetArticleListError] = useState("articleListErrorShower");
    let [deleteArticleError, SetDeleteArticleError] = useState("articleDeleteErrorShower");

    let article_list_error_selector = useSelector(state => state.errors.articleListError)
    let article_delete_error_selector = useSelector(state => state.errors.deleteArticleError);

    let adminDispatch = useDispatch();
    let updateNavigate = useNavigate();

    useEffect(() => {

        let isAUth = localStorage.getItem("kapt");

        if(isAUth === null || isAUth === undefined){
            window.location.href = "/login";
        }

        SetArticleListError("articleListErrorShower");
        adminDispatch(resetArticleListError());
        adminDispatch(resetUpdateArticleError());
        adminDispatch(resetUpdateArticleData());

        axios({
            url: kapi.articleapi,
            method: kapi.get,
            withCredentials: true,
        }).then(result => {
            if(result.status !== 200){
                throw new Error(result);
            }else{
                adminDispatch(getAllArticle(result));
            }
        }).catch(error => {
            if(error.response === undefined){
                SetArticleListError("articleListErrorShowerSelected");
                adminDispatch(addAdminArticleListError("something is wrong try later"));
            }else{
                SetArticleListError("articleListErrorShowerSelected");
                adminDispatch(addAdminArticleListError(error.response.data));
            }
        });
    },[adminArticleSelector]);

    adminArticleSelector = useSelector(state => state.articles.article);

    function handleDeleteArticle(articlekey){

        SetDeleteArticleError("articleDeleteErrorShower");
        adminDispatch(resetAdminDeleteArticleError());

        let delete_kapt = localStorage.getItem("kapt");

        axios({
            url: kapi.admindeletearticleapi,
            method: kapi.delete,
            data:{
                key: articlekey,
            },
            headers:{
                "Authorization": `Bearer ${delete_kapt}`
            },
            withCredentials: true,
        }).then(result => {
            if(result.status !== 200){
                throw new Error(result);
            }
            setTimeout(() => {
                window.location.reload();
            }, 800);
            
        }).catch(error => {
            SetDeleteArticleError("articleDeleteErrorShowerSelected");
            if(error.response !== undefined){
                if(error.response.data === ""){
					localStorage.removeItem("kapt");
					window.location.href = "/";
                }
            }
            adminDispatch(adminDeleteArticleError("something is wrong may be server is down, please try later"));
            setTimeout(() => {
                SetDeleteArticleError("articleDeleteErrorShower");
                adminDispatch(resetArticleListError());
            }, 5000);
        });

    }

    function handleUpdateArticle(key){
        updateNavigate(`/kal/admin/article/update/${key}`);
    }

    function handleAdminArticleDownloadBtn(link,name){
        let adminDownloadBtn = document.createElement("a");
        adminDownloadBtn.href = link;
        adminDownloadBtn.setAttribute("download",name);
        document.body.appendChild(adminDownloadBtn);
        adminDownloadBtn.click();
        adminDownloadBtn.remove();
    }

    return(
        <div className={adminArticleListContainerClassName}>

            <div className={articleListError}>
                {article_list_error_selector.map((error,index) => 
                    <div key={index}>{error}</div>
                )}
            </div>

            <div className={deleteArticleError}>
                    {Array.from(article_delete_error_selector).map((item,index) => 
                        <div key={index}>{item}</div>
                    )}
            </div>

            {Array.from(adminArticleSelector).length > 0 ?
                <table className="kalniyojanAdminArticleTable">
                    <thead className="kalniyojanAdminTableHeader">
                        <tr>
                            <td className="articleAdminFileName">Filename</td>
                            <td className="articleAdminPublishedDate">Publish Date</td>
                            <td className="articleAdminDownload">Download Article</td>
                            <td className="articleAdminActions">Actions</td>
                        </tr>
                    </thead>
                    <tbody className="kalniyojanAdminTableBody">
                        {Array.from(adminArticleSelector).map(item => 
                            <tr key={item.key}>
                                <td>{item.filename}</td>
                                <td>{item.date}</td>
                                <td><button name="adminArticleDownloadBtn" title="Download Article" className="kalniyojanAdminDownloadArticleBtn" onClick={() => handleAdminArticleDownloadBtn(item.download,item.filename)}>Download</button></td>
                                <td className="adminArticleActionBtns">
                                    <button name="adminArticleDeleteBtn" title="Delete" className="kalniyojanAdminDeleteArticleBtn" onClick={() => {
                                        handleDeleteArticle(item.key);
                                    }}>
                                        <img src={DeleteBtn} alt="deleteBtn"></img>
                                    </button>
                                    <button name="adminArticleUpdateBtn" title="Update" className="KalniyojanAdminUpdateArticleBtn" onClick={() => {
                                        handleUpdateArticle(item.key);
                                    }}>
                                        <img src={UpdateBtn} alt="updateBtn"></img>
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table> : <div className="emptyArticleList">No Article Found!!!</div>
            }

        </div>
   );
}

export default AdminArticleList;