import MainPageType from "../Type/MainPageType";

let mainPageInitState = 0;

const mainPageReducer = (state = mainPageInitState, action) => {

    switch(action.type){
        case MainPageType.mp:
            return{
                ...action.payload,
            };
        default:
            return state;
    }
}

export default mainPageReducer;