import articleType from "../Type/ArticleTypes";

let articleInitialState = {
    article:[],
    updateArticle:[],
};


function articleReducer(state = articleInitialState, action){

    switch(action.type){

        case 'loadAllArticle':
            return {
                ...state,
                article: [
                    ...action.payload,
                ]
            };

        case articleType.resetAllArticle:
            return{
                ...state,
                article:[],
            }
        
        case articleType.updateArticle:
            return{
                ...state,
                updateArticle:{
                    ...action.payload,
                }
            };
        
        case articleType.resetUpdateArticle:
            return{
                ...state,
                updateArticle:action.payload,
            };
        
        default:
            return state;
    }
}

export default articleReducer;