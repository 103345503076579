import VisitorType from "../Type/VisitorsType";

let initialVisitor = {
    visitor: 0,
};

function visitorReducer(state = initialVisitor, action){

    switch(action.type){

        case VisitorType.visitors:
            return{
                ...state,
                visitor: action.payload,
            };
        
        default:
            return{
                ...state,
            };
    }
}

export default visitorReducer;