import { useNavigate } from "react-router-dom";

import "./PageNotFound.css";

function PageNotFound(){

    let pageNotFOundHomeBtn = useNavigate();

    function GoToHome(){
        pageNotFOundHomeBtn("/");
    }

    return(
        <div className="kalniyojanPageNotFound">
            <div className="kalniyojanPageNotFoundStatusCode">404</div>
            <div className="kalniyojanPageNotFoundMessage">
                The Page You Are Looking for is not found.
            </div>
            <div className="kalniyojanPageNotFoundGoToHomeBtn" onClick={GoToHome}>
                Go To Home
            </div>
        </div>
    );

}
export default PageNotFound;