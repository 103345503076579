
import "./Terms.css";

export default function Terms(){
    return(
        <div className="kalniyojanTermsAndConditionsContainer">
            <div className="kalniyojanTermsAndConditionsContainerContent">
            
            <h2>Welcome to kalniyojan Terms</h2>

            <p>
                This website is owned and operated by Laxmikant Motisa Kolhapure
                The terms and conditions below govern your use of this Website. Please read these carefully before using this Website.
            </p>

            <div className="kalniyojanTermsExplaination">
                <ol>
                    <li className="kalniyojanAcceptanceOfTermAndCondition">
                        <div>ACCEPTANCE OF TERMS & CONDITIONS:</div>
                        <div>
                            Your right to use our Services is personal to you. Each time you access and/or use, avail any of the services provided by kalniyojan.com, you agree to be bound by these Terms & Conditions.
                            Please note that these Terms & Conditions may be revised and reissued, prospectively by posting updated Terms & Conditions on kalniyojan.com at any time based on their discretion and/ or keeping in tune with the changing scenarios and technology. However, also always bear in mind that they shall never be intended to directly affect or inhibit our customers, service quality or customer satisfaction.
                        </div>
                    </li>
                    <li className="kalniyojanPermittedUseTerms">
                        <div>PERMITTED USE: </div>
                        <div className="kalniyojanPermittedUseTermsSub">
                            The online services contain material that is supplied and owned by kalniyojan.com
                            Your use of our site and services is subject to various restrictions designed to protect the online services and our users. You are responsible for any charges incurred in obtaining access and use of the Site and online services.
                            You acknowledge that the Content, including but not limited to text, software, photographs, graphics or commercially produced information presented to the User by kalniyojan.com
                            is protected by copyrights, trademarks, service marks, patents and/or other proprietary rights and laws.

                            <p>You are not permitted to remove, alter, bypass, avoid, interfere with, or circumvent</p>
                            <ol>
                                <li>any copyright, trademark or other proprietary notices marked on the online services or Content,</li>
                                <li>any digital rights, management mechanism, device, or other content protection or access control measure associated with the online services or Content,</li>
                                <li>any advertisement on the online services and/or within Content.</li>
                            </ol>
                            You may not directly or through the use of any device or other means remove, copy, alter, use, reproduce, duplicate, archive, upload, publish, modify, translate, broadcast, perform, display, sell, transmit, retransmit, distribute,or create derivative works from the Content unless expressly permitted by kalniyojan.com in writing.
                            You may also not use any software robots, spider, crawlers, or other data gathering or extraction tools, whether automated or manual, to access, acquire, copy, monitor, scrape or aggregate Content or any portion of the online services.
                            You may not knowingly or intentionally take any action that may impose an unreasonable burden or load on the online services or its servers and infrastructures.
                        </div>
                    </li>
                    <li className="kalniyojanPriceDisclaimer">
                        <div>PRICING DISCLAIMER:</div>
                        <div>
                            We do everything we can to ensure that the prices of our products on websites are correct, and we try to keep our prices constant. Sometimes we need to change the cost of a product, either up or down.
                            Prices may be changed at any time without further notice. We reserve the right to change our products prices at any time without further notice.
                            Prices listed in any other websites may not be accepted. We sell our products only under kalniyojan.com. We reserve the right not to accept a price listed on a site other than our own.
                        </div>
                    </li>
                </ol>
            </div>
            </div>
        </div>
    );
}