import FeesType from "../Type/FeesType"

function adviceFee(data){
    return{
        type: FeesType.adviceFee,
        payload: data,
    };
}

function matchMakingFee(data){
    return{
        type: FeesType.matchMakingFee,
        payload:data,
    };
}

function janmaPatrikaFee(data){
    return{
        type: FeesType.janmaPatrikaFee,
        payload:data,
    };
}

function muhurtaFee(fee){
    return{
        type:FeesType.muhurtafee,
        payload: fee,
    };
}

function vastuFee(fee){
    return{
        type:FeesType.vastuFee,
        payload: fee,
    };
}

export{
    adviceFee,
    matchMakingFee,
    janmaPatrikaFee,
    muhurtaFee,
    vastuFee,
};