import React from "react";
import "./Services.css";
import { Link, useNavigate } from "react-router-dom";

import patrikaImage from "../PatrikaGanpati.png";
import softwareImage from "../6.png";
import teachingImage from "../7.png";
import counsellingImage from "../8.png";

function Services(props){

  let servicesState = {
    panchang: "Panchang",
    advice: "Advice",
    books: "Books",
    patrika: "Janma Patrika",
    matchmaking: "Match Making",
    counselling: "Counselling",
    teaching: "Teaching",
    muhurta: "Muhurta",
    puja: "Puja",
    vastu: "Vastu",
    marathi: "Marathi",
    telugu: "Telugu",
    kannada: "Kannada",
    gujurati: "Gujarati",
    english: "English",
    tamil: "Tamil",
    bangali: "Bangali",
    malyalam: "Malyalam",
    hindi: "Hindi",
    serviceOneClassName: "kalniyojanServiceOne",
    serviceTwoClassName: "kalniyojanServiceTwo",
    serviceThreeClassName: "kalniyojanServiceThree",
    serviceFourClassName: "kalniyojanServiceFour",
    serviceFiveClassName: "kalniyojanServiceFive",
    serviceSixClassName: "kalniyojanServiceSix",
    serviceSevenClassName: "kalniyojanServiceSeven",
    serviceEightClassName: "kalniyojanServiceEight",
    serviceNineClassName: "kalniyojanServiceNine",
    serviceTenClassName: "kalniyojanServiceTen",
  };

  const servicesNavigate = useNavigate();


  return (
    <div className="kalniyojanServicesContainer">
      <div className="kalniyojanServicesContainerContent">
        <h1 className="kalniyojanServicesHeader">Our Services</h1>

        <div className="kalniyojanServices">
          <div className="kalniyojanServicesLeftSide">
            <div className={servicesState.serviceTwoClassName} onClick={() => {
              servicesNavigate("/services/advice");
            }}>{servicesState.advice}</div>

            <div className={servicesState.serviceThreeClassName} >{servicesState.books}</div>

            <div className={servicesState.serviceSixClassName} >{servicesState.counselling}</div>

            <div className={servicesState.serviceFourClassName} onClick={() => {
              servicesNavigate("/services/janmapatrika")
            }}>{servicesState.patrika}</div>

            <div className={servicesState.serviceFiveClassName} onClick={() => {
              servicesNavigate("/services/matchmaking");
            }}>{servicesState.matchmaking}</div>

          </div>

          <div className="kalniyojanServicesRightSide">

            <div className={servicesState.serviceEightClassName} onClick={() => {
                servicesNavigate("/services/muhurta")
            }}>{servicesState.muhurta}</div>

            <div className={servicesState.serviceOneClassName} >{servicesState.panchang}</div>

            <div className={servicesState.serviceNineClassName} >{servicesState.puja}</div>
            
            <div className={servicesState.serviceSevenClassName} >{servicesState.teaching}</div>

            <div className={servicesState.serviceTenClassName} onClick={() => {
              servicesNavigate("/services/vastu");
            }}>{servicesState.vastu}</div>

          </div>

        </div>
      </div>

      <div className="servicesDetailsHighlight">
        <hr/>
        <p>Services Highlights</p>
        <hr/>
      </div>

      <div className="servicesDetailsContainer">
        <div className="servicesDetailsContainerContent">
          <div className="patrikaDetail">

            <div className="kalniyojanPatrikaServiceImgHeader">
              <img src={patrikaImage} alt="kalniyojanPatrikaServiceImg" />

              <h2 className="patrikaDetailHeader">
                Computer Patrika
              </h2>
            </div>

            <div className="patrikaVariantLang">
              <div className="patrikaVariants">
                C.P (traditional), Sayan and <br />
                K.P (krishnamurti) method <br />
                (Available in 7 languages)<br />
              </div>
              <ul className="langList">
                <li key={servicesState.marathi} className="langone">Marathi</li>
                <li key={servicesState.telugu} className="langtwo">Telugu</li>
                <li key={servicesState.kannada} className="langthree">Kanaada</li>
                <li key={servicesState.english} className="langfour">English</li>
                <li key={servicesState.gujurati} className="langfive">Gujarati</li>
                <li key={servicesState.hindi} className="langsix">Hindi</li>
                <li key={servicesState.bangali} className="langseven">Bengali</li>
              </ul>
            </div>

          </div>

          <div className="softwareDetails">

            <div className="kalniyojanSoftwareDetailsImgHeader">
              <img src={softwareImage} alt="softwareImage" />

              <h2 className="softwareDetailHeader">
                Astrology Softwares
              </h2>
            </div>

            <div className="softwareVariantLang">
              <div className="softwareVariants">
                C.P (traditional), Sayan and <br />
                K.P (krishnamurti) method <br />
                (Available in 9 languages)<br />
              </div>
              <ul className="langList">
                <li key={servicesState.marathi} className="langone">Marathi</li>
                <li key={servicesState.telugu} className="langtwo">Telugu</li>
                <li key={servicesState.kannada} className="langthree">Kannada</li>
                <li key={servicesState.gujurati} className="langfour">Gujarati</li>
                <li key={servicesState.tamil} className="langfive">Tamil</li>
                <li key={servicesState.malyalam} className="langsix">Malyalam</li>
                <li key={servicesState.bengali} className="langseven">Bengali</li>
                <li key={servicesState.english} className="langeight">English</li>
                <li key={servicesState.hindi} className="langnine">Hindi</li>
              </ul>
            </div>

          </div>

          <div className="TeachingDetails">

            <div className="kalniyojanTeachingDetailsImgHeader">
              <img src={teachingImage} alt="teachingImage" />

              <h2 className="teachingDetailHeader">
                Teaching
              </h2>
            </div>

            <div className="teachingVariants">
              <div className="teachingVariant">
                Method: Krishnamurti,Niryan(traditional)<br />
                Regular Astrology classes, K.P-Basic,Advanced and<br />
                Expert Level Curriculum.<br />
                C.P(traditional)-Wizard,pandit,shastri level Curriculum.
              </div>
            </div>

          </div>

          <div className="counsellingDetails">

            <div className="kalniyojanCounsellingDetailsImgHeader">
              <img src={counsellingImage} alt="counsellingImage"/>

              <h2 className="counsellingDetailHeader">
                Counselling
              </h2>
            </div>

            <div className="counsellingVariants">
              Counselling in kalniyojan method. with the help of
              Astrology.
              (Prepaid online/offline Counselling Available)
            </div>

          </div>
        </div>
      </div>

      <div className="magzineContainer">
        <div className="magzine">

          <div className="kalniyojanMagzineHeaderBrandYearCreator">

            <h1 className="header">Yearly Magzine</h1>

            <div className="kalniyojanMagzinePublishDate">
                kalniyojan magzine publish every year on 23rd october or <br/> bhartiya sour mas kartik shake 1
            </div>

            <div className="magzineNameAndCreator">
              <div className="Creator">
                Sampadak: Laxmikant Motisa Kolhapure
              </div>
            </div>

          </div>

          <div className="magzineSubscriptionInfo">

            <div className="magzineContents">
              To get Kalniyojan Yearly Magzine By Post Become the Kalniyojan
              Member..!
              <div className="magzineContent">
                Yearly Rashi-Bhavishya, Dharma, Adhyatma
                and jyotish related content, vishesh sampadakiya
              </div>
            </div>

            <div className="magzineYearlyRate">
              Yearly Subscription Fees
              <div className="yearlyPrice">
                Rs.200/-
              </div>
              <div className="littleDescription">
                With special content
              </div>
            </div>

            <div className="bankInfo">
              State Bank Of India,solapur branch balives.
              <div className="bankAccNu">
                <span className="bankInfoTitle">Bank account number</span> : <span>11087797116</span>
                <div className="bankAccName">
                  <span className="bankInfoTitle">Name</span> : Laxmikant Motisa Kolhapure
                </div>
              </div>
              <div className="bankCodes">
              <span className="bankInfoTitle">IFSC Code</span>: <span>SBIN 0000483</span> (Only transfer money by cheque/bank transfer/NEFT)
              </div>
            </div>

            <div className="whereToFindQrCode">
              the QR code is Available in <Link to={"/contact"}>Contact us</Link>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default Services;
