
import { useEffect, useRef, useState } from "react";
import "./MatchMaking.css";
import { useDispatch, useSelector } from "react-redux";
import { isBoolean } from "lodash";
import { matchMakingError, matchMakingPaymentError, resetMatchMakingError, resetMatchMakingPaymentError } from "../Redux/Action/ErrorAction";
import axios from "axios";
import kapi from "../Redux/kalniyojanapi";
import { matchMakingFee } from "../Redux/Action/FeesAction";
import { loadStripe } from "@stripe/stripe-js";
import KalniyojanCheckout from "./Stripescreens/KalniyojanCheckout";
import { Elements } from "@stripe/react-stripe-js";
import { getBillSdkConfig, getClientIp, getStripeAppearance, uploadClientMatchMaking } from "../Helpers/Earth";

function MatchMaking(){


	let mm_star = 0;
	let matchMakingFeeSelector;

	let matchMakingErrorSelector = useSelector(state => state.errors.matchMaking);
	let matchMakingPaymentErrorSelector = useSelector(state => state.errors.payment);

	let [matchMakingStripePromise, SetMatchMakingStripePromise] = useState(null);

	let [matchMakingPaymentOptionsWindowCls,SetMatchMakingPaymentOptionsWindowCls] = useState("matchMakingPaymentOptionsWindow");

	let [matchMakingPaymentWindowCls, SetMatchMakingPaymentWindowCls] = useState("matchMakingPaymentWindow");

	let requesterNameRef = useRef(null);
	let requesterEmailRef = useRef(null);
	let requesterPhoneRef = useRef(null);

	let maleNameRef = useRef(null);
	let maleBirthDateRef = useRef(null);
	let maleBirthTimeRef = useRef(null);
	let maleBirthPlaceRef = useRef(null);

	let femaleNameRef = useRef(null);
	let femaleBirthDateRef = useRef(null);
	let femaleBirthTimeRef = useRef(null);
	let femaleBirthPlaceRef = useRef(null);


	let matchmakingOrderId = useRef({
		matchMakingOrderId:0,
	});

	let matchMakingStripePub = useRef({
		key:null,
	});

	let matchMakingFormData = useRef({
		formdata:0,
	});

	let matchMakingDispatcher = useDispatch();

	let [matchMakingErrorShower, SetMatchMakingErrorShower] = useState("matchMakingErrorShower");
	let [matchMakingPaymentErrorShower, SetMatchMakingPaymentErrorShower] = useState("matchMakingPaymentErrorShower");

	useEffect(() => {
		axios({
			url: kapi.matchmakingfeesapi,
			method: kapi.post,
			data:{
				servicename: kapi.matchMakingServiceName,
			},
			headers:{
				"Content-Type":"application/json",
			},
			withCredentials: true,
		}).then(result => {
			if(result.status !== 200){
				throw new Error();
			}
			let {fees} = result.data;
			matchMakingDispatcher(matchMakingFee(fees));
		}).catch(error => {
			SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShowerSelected");
			matchMakingDispatcher(matchMakingPaymentError("failed to fetch service data"))
			setTimeout(() => {
				SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShower");
				matchMakingDispatcher(resetMatchMakingPaymentError());
			}, 10000);
		});
	},[matchMakingFeeSelector]);

	matchMakingFeeSelector = useSelector(state => state.fees.matchMaking);

	let matchMakingHandlers = {

		requesterInsideIndia: true,
		requesterCountryCode: null,
		matchMakingCurrentDate: new Date(),
		requesterPhoneNumber: null,
		countrys: {
			Afghanistan: "Afghanistan",
			Albania: "Albania",
			Algeria: "Algeria",
			Andorra: "Andorra",
			Angola: "Angola",
			"Antigua and Barbuda":"Antigua and Barbuda",
			Argentina: "Argentina",
			Armenia: "Armenia",
			Australia:"Australia",
			Austria:"Austria",
			Azerbaijan:"Azerbaijan",
			Bahamas:"Bahamas",
			Bahrain:"Bahrain",
			Bangladesh:"Bangladesh",
			Barbados:"Barbados",
			Belarus:"Belarus",
			Belgium:"Belgium",
			Belize:"Belize",
			Benin:"Benin",
			Bhutan:"Bhutan",
			Bolivia:"Bolivia",
			"Bosnia and Herzegovina":"Bosnia and Herzegovina",
			Botswana:"Botswana",
			Brazil:"Brazil",
			Brunei:"Brunei",
			Bulgaria:"Bulgaria",
			"Burkina Faso":"Burkina Faso",
			Burundi:"Burundi",
			"Côte d'Ivoire":"Côte d'Ivoire",
			"Cabo Verde":"Cabo Verde",
			Cambodia:"Cambodia",
			Cameroon:"Cameroon",
			Canada:"Canada",
			"Central African Republic":"Central African Republic",
			Chad:"Chad",
			Chile:"Chile",
			China: "China",
			Colombia:"Colombia",
			Comoros:"Comoros",
			"Congo (Congo-Brazzaville)":"Congo (Congo-Brazzaville)",
			"Costa Rica":"Costa Rica",
			Croatia:"Croatia",
			Cuba:"Cuba",
			Cyprus:"Cyprus",
			"Czechia (Czech Republic)":"Czechia (Czech Republic)",
			"Democratic Republic of the Congo":"Democratic Republic of the Congo",
			Denmark:"Denmark",
			Djibouti:"Djibouti",
			Dominica:"Dominica",
			"Dominican Republic":"Dominican Republic",
			Ecuador:"Ecuador",
			Egypt:"Egypt",
			"El Salvador":"El Salvador",
			"Equatorial Guinea":"Equatorial Guinea",
			Eritrea:"Eritrea",
			Estonia:"Estonia",
			Swaziland:"Swaziland",
			Ethiopia:"Ethiopia",
			Fiji:"Fiji",
			Finland:"Finland",
			France:"France",
			Gabon:"Gabon",
			Gambia:"Gambia",
			Georgia:"Georgia",
			Germany:"Germany",
			Ghana:"Ghana",
			Greece:"Greece",
			Grenada:"Grenada",
			Guatemala:"Guatemala",
			Guinea:"Guinea",
			"Guinea-Bissau":"Guinea-Bissau",
			Guyana:"Guyana",
			Haiti:"Haiti",
			"Vatican City":"Vatican City",
			Honduras:"Honduras",
			Hungary:"Hungary",
			Iceland:"Iceland",
			India:"India",
			Indonesia:"Indonesia",
			Iran:"Iran",
			Iraq:"Iraq",
			Ireland:"Ireland",
			Israel:"Israel",
			Italy:"Italy",
			Jamaica:"Jamaica",
			Japan:"Japan",
			Jordan:"Jordan",
			Kazakhstan:"Kazakhstan",
			Kenya:"Kenya",
			Kiribati:"Kiribati",
			Kuwait:"Kuwait",
			Kyrgyzstan:"Kyrgyzstan",
			Laos:"Laos",
			Latvia:"Latvia",
			Lebanon:"Lebanon",
			Lesotho:"Lesotho",
			Liberia:"Liberia",
			Libya:"Libya",
			Liechtenstein:"Liechtenstein",
			Lithuania:"Lithuania",
			Luxembourg:"Luxembourg",
			Madagascar:"Madagascar",
			Malawi:"Malawi",
			Malaysia:"Malaysia",
			Maldives:"Maldives",
			Mali:"Mali",
			Malta:"Malta",
			"Marshall Islands":"Marshall Islands",
			Mauritania:"Mauritania",
			Mauritius:"Mauritius",
			Mexico:"Mexico",
			Micronesia:"Micronesia",
			Moldova:"Moldova",
			Monaco:"Monaco",
			Mongolia:"Mongolia",
			Montenegro:"Montenegro",
			Morocco:"Morocco",
			Mozambique:"Mozambique",
			Myanmar:"Myanmar",
			Namibia:"Namibia",
			Nauru:"Nauru",
			Nepal:"Nepal",
			Netherlands:"Netherlands",
			"New Zealand":"New Zealand",
			Nicaragua:"Nicaragua",
			Niger:"Niger",
			Nigeria:"Nigeria",
			"North Korea":"North Korea",
			"North Macedonia":"North Macedonia",
			Norway:"Norway",
			Oman:"Oman",
			Pakistan:"Pakistan",
			Palau:"Palau",
			Panama:"Panama",
			"Papua New Guinea":"Papua New Guinea",
			Paraguay:"Paraguay",
			Peru:"Peru",
			Philippines:"Philippines",
			Poland:"Poland",
			Portugal:"Portugal",
			Qatar:"Qatar",
			Romania:"Romania",
			Russia:"Russia",
			Rwanda:"Rwanda",
			"Saint Kitts and Nevis":"Saint Kitts and Nevis",
			"Saint Lucia":"Saint Lucia",
			"Saint Vincent and the Grenadines":"Saint Vincent and the Grenadines",
			Samoa:"Samoa",
			"San Marino":"San Marino",
			"Sao Tome and Principe":"Sao Tome and Principe",
			"Saudi Arabia":"Saudi Arabia",
			Senegal:"Senegal",
			Serbia:"Serbia",
			Seychelles:"Seychelles",
			"Sierra Leone":"Sierra Leone",
			Singapore:"Singapore",
			Slovakia:"Slovakia",
			Slovenia:"Slovenia",
			"Solomon Islands":"Solomon Islands",
			Somalia:"Somalia",
			"South Africa":"South Africa",
			"South Korea":"South Korea",
			"South Sudan":"South Sudan",
			Spain:"Spain",
			"Sri Lanka":"Sri Lanka",
			Sudan:"Sudan",
			Suriname:"Suriname",
			Sweden:"Sweden",
			Switzerland:"Switzerland",
			Syria:"Syria",
			Tajikistan:"Tajikistan",
			Tanzania:"Tanzania",
			Thailand:"Thailand",
			"Timor-Leste":"Timor-Leste",
			Togo:"Togo",
			Tonga:"Tonga",
			"Trinidad and Tobago":"Trinidad and Tobago",
			Tunisia:"Tunisia",
			Turkey:"Turkey",
			Turkmenistan:"Turkmenistan",
			Tuvalu:"Tuvalu",
			Uganda:"Uganda",
			Ukraine:"Ukraine",
			"United Arab Emirates":"United Arab Emirates",
			"United Kingdom":"United Kingdom",
			"United States of America":"United States of America",
			Uruguay:"Uruguay",
			Uzbekistan:"Uzbekistan",
			Vanuatu:"Vanuatu",
			Venezuela:"Venezuela",
			Vietnam:"Vietnam",
			Yemen:"Yemen",
			Zambia:"Zambia",
			Zimbabwe:"Zimbabwe",
		},

		countrysValues: function(){
			let countryMap = new Map(Object.entries(this.countrys))
			return countryMap.values();
		},

		daysInMonths: {
			"Jan": 31,
			"Feb": 28,
			"Mar": 31,
			"Apr": 30,
			"May": 31,
			"Jun": 30,
			"Jul": 31,
			"Aug": 31,
			"Sep": 30,
			"Oct": 31,
			"Nov": 30,
			"Dec": 31,
		},


		matchMakingValidatorType:{
			requesterName: "requesterName",
			requesterEmail: "requesterEmail",
			requesterPhone: "requesterPhone",
			maleName: "maleName",
			maleDate: "maleDate",
			maleTime: "maleTime",
			malePlace: "malePlace",
			maleCountry: "maleCountry",
			femaleName: "femaleName",
			femaleDate: "femaleDate",
			femaleTime: "femaleTime",
			femalePlace: "femalePlace",
			femaleCountry: "femaleCountry",
		},

		matchResetPhoneAndCc: function(){
			this.requesterCountryCode = null;
			this.requesterPhoneNumber = null;
		},



		getDaysInMatchMakingMonth: function(year, month){

			let is_leap_year = false;
			let adviceMonths = this.daysInMonths;

			if (year % 4 === 0) {
				is_leap_year = true;
			}

			let months_map = new Map(Array.from(Object.entries(adviceMonths)));

			if (is_leap_year) {
				let temp_months_keys_array = Array.from(months_map.keys());

				let alter_feb = temp_months_keys_array.find(item => item === "Feb");

				if (alter_feb !== null || alter_feb !== undefined) {
					adviceMonths[alter_feb] = 29;
				}
			}

			let months_keys_array = Array.from(months_map.keys());

			let user_expected_month = months_keys_array[(month)];

			if (typeof user_expected_month === "string") {
				return adviceMonths[user_expected_month];
			} else {
				return false;
			}
		},

		placeValidator: function(place){

			if(place === ""){
				return false;
			}
			
			if (place.length < 4) {
				return false;
			}
			let placeRegex = /[a-zA-Z]{4,}/
			let isPlaceValid = placeRegex.test(place);
			if (!isPlaceValid) {
				return false;
			}
			let advancedPlaceRegex = /(?=.*[@!+._\-?{}[\]])[@!+._\-?{}[\]]/
			if (advancedPlaceRegex.test(place)) {
				return false;
			}
			return true;
		},

		timeValidator: function(time){

			let timeobj = {
				hour: true,
				minute: true,
			};

			if(time === null || time === " " || time === undefined || time === ""){
				return false;
			}

			let trimedtime = time.split(":");
			if(trimedtime.length < 2 || trimedtime.length > 2 || trimedtime.length !== 2){
				return {
					...timeobj,
					hour: false,
					minute: false,
				};
			}
			let minute = parseInt(trimedtime[1]);
			let hour = parseInt(trimedtime[0]);

			if(hour === "" || hour < 0 || hour > 24){
				timeobj.hour = false;
			}

			if(minute === "" || minute < 0 || minute > 60){
				timeobj.minute = false;
			}

			return timeobj;
		},

		dateValidator: function(date){
			let matchMakingDateValidate = {
				year: true,
				month: true,
				day: true,
			};

			if (date.getFullYear() === "" || date.getFullYear() <= 1900 || date.getFullYear().toString().length < 4) {
				matchMakingDateValidate.year = false;
			}
			if(date.getFullYear() > new Date().getFullYear()){
				matchMakingDateValidate.year = false;
			}

			if (date.getMonth() === "") {
				matchMakingDateValidate.month = false;
			}

			if (date.getMonth() > 12 || (date.getFullYear() > this.matchMakingCurrentDate.getFullYear() && (date.getMonth() - 1) >= this.matchMakingCurrentDate.getMonth())) {
				matchMakingDateValidate.month = false;
			}

			if (date.getFullYear() <= this.matchMakingCurrentDate.getFullYear() && (date.getMonth() - 1) <= this.matchMakingCurrentDate.getMonth()) {
				matchMakingDateValidate.month = true;
			}

			if (date.getMonth() <= 12 && (date.getFullYear() < this.matchMakingCurrentDate.getFullYear() && ((date.getMonth() - 1) > this.matchMakingCurrentDate.getMonth() || (date.getMonth() - 1) < this.matchMakingCurrentDate.getMonth() || (date.getMonth() - 1) === this.matchMakingCurrentDate.getMonth()))) {
				matchMakingDateValidate.month = true;
			}

			if (date.getFullYear() === this.matchMakingCurrentDate.getFullYear() && (date.getMonth() - 1) > this.matchMakingCurrentDate.getMonth()) {
				matchMakingDateValidate.month = false;
			}

			if (date.getDate() === "") {
				matchMakingDateValidate.day = false;
			}
			let adviceDaysInMonth = matchMakingHandlers.getDaysInMatchMakingMonth(date.getFullYear(), date.getMonth());
			if (isBoolean(adviceDaysInMonth)) {
				matchMakingDateValidate.day = false;
			}
			if(date.getDate() > adviceDaysInMonth){
				matchMakingDateValidate.day = false;
			}        
			return matchMakingDateValidate;
		},

		handleMatchMaking: function(event){
			event.preventDefault();
		
			
			if(requesterNameRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				requesterNme: requesterNameRef.current.value,
			},this.matchMakingValidatorType.requesterName)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("client name must be grater than 5 char"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(requesterEmailRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				requesterEmail: requesterEmailRef.current.value,
			},this.matchMakingValidatorType.requesterEmail)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("email is not valid, only (gmail,hotmail,yahoo,rediffmail) supported"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(requesterPhoneRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				requesterPhone: requesterPhoneRef.current.value,
			},this.matchMakingValidatorType.requesterPhone)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("phoneNumber is not valid"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(maleNameRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				maleName: maleNameRef.current.value,
			},this.matchMakingValidatorType.maleName)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("male name must be grater than 5 char"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(maleBirthDateRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				maleDate: maleBirthDateRef.current.value,
			},this.matchMakingValidatorType.maleDate)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("male birth date is invalid"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(maleBirthTimeRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				maleTime: maleBirthTimeRef.current.value,
			},this.matchMakingValidatorType.maleTime)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("male birth time is invalid"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(maleBirthPlaceRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				malePlace: maleBirthPlaceRef.current.value,
			},this.matchMakingValidatorType.malePlace)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("male birth place is invalid"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(defaultMaleCountry === null || !matchMakingHandlers.matchMakingValidator({
				maleCountry: defaultMaleCountry,
			},this.matchMakingValidatorType.maleCountry)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("male birth country is invalid"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;

			}

			if(femaleNameRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				femaleName: femaleNameRef.current.value,
			},this.matchMakingValidatorType.femaleName)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("female name is not valid atleast 5 char required"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(femaleBirthDateRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				femaleDate: femaleBirthDateRef.current.value,
			},this.matchMakingValidatorType.femaleDate)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("female birth date is invalid"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(femaleBirthTimeRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				femaleTime: femaleBirthTimeRef.current.value,
			},this.matchMakingValidatorType.femaleTime)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("female birth time is invalid"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(femaleBirthPlaceRef.current.value === null || !matchMakingHandlers.matchMakingValidator({
				femalePlace: femaleBirthPlaceRef.current.value,
			},this.matchMakingValidatorType.femalePlace)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("female birth place is invalid"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(defaultFemaleCountry === null || !matchMakingHandlers.matchMakingValidator({
				femaleCountry: defaultFemaleCountry,
			},this.matchMakingValidatorType.femaleCountry)){
				window.scrollTo(0,0);
				SetMatchMakingErrorShower("matchMakingErrorShowerSelected");
				matchMakingDispatcher(matchMakingError("male birth country is invalid"));
				setTimeout(() => {
					SetMatchMakingErrorShower("matchMakingErrorShower");
					matchMakingDispatcher(resetMatchMakingError());
				}, 10000);
				mm_star++;
			}

			if(mm_star === 0){
				
				let matchMakingForm = new FormData();
				matchMakingForm.append("clientName",requesterNameRef.current.value);
				matchMakingForm.append("clientEmail",requesterEmailRef.current.value);
				matchMakingForm.append("clientPhone",this.requesterPhoneNumber);
				matchMakingForm.append("clientPhoneCountryCode",this.requesterCountryCode);
				matchMakingForm.append("maleName",maleNameRef.current.value);
				matchMakingForm.append("maleDate",maleBirthDateRef.current.value);
				matchMakingForm.append("maleTime",maleBirthTimeRef.current.value);
				matchMakingForm.append("malePlace",maleBirthPlaceRef.current.value);
				matchMakingForm.append("femaleName",femaleNameRef.current.value);
				matchMakingForm.append("femaleDate",femaleBirthDateRef.current.value);
				matchMakingForm.append("femaleTime",femaleBirthTimeRef.current.value);
				matchMakingForm.append("femalePlace",femaleBirthPlaceRef.current.value);

				setTimeout(() => {
					matchMakingHandlers.matchResetPhoneAndCc();
					submitMatchMaking(matchMakingForm);
				}, 1000);
			}

		},


		nameValidator: function(name){
			let nameRegex = /[a-zA-Z]{5,}/i;
			let isNameValid = nameRegex.test(name);
			return isNameValid;
		},

		matchMakingValidator: function(data, type) {

			let getNumber = number => {
				if (number === "") {
					return false;
				}
				let matchmakingPositionOfDash = number.indexOf("-");
				let matchmakingLastPositionOfDash = number.lastIndexOf("-");
				if (matchmakingPositionOfDash === -1) {
					return false;
				}
				if (matchmakingPositionOfDash !== matchmakingLastPositionOfDash) {
					return false;
				}
				let MM = number.substring(0, matchmakingPositionOfDash);
				let parsedMM = parseInt(MM);
				if (isNaN(parsedMM) || MM.length > 4 || MM.length < 1 || parsedMM === 0) {
					return false;
				}
				let actualNumberOfRequester = number.substring(matchmakingPositionOfDash + 1, number.length);
				let matchMakingParsedActualNumber = parseInt(actualNumberOfRequester);
				if (isNaN(matchMakingParsedActualNumber) || actualNumberOfRequester.length > 10) {
					return false;
				}
				this.requesterInsideIndia = parsedMM !== 91 ? false : true;
				this.requesterCountryCode = parsedMM;
				this.requesterPhoneNumber = matchMakingParsedActualNumber;
				return matchMakingParsedActualNumber;
			};


			switch (type) {

				case this.matchMakingValidatorType.requesterName:
				case this.matchMakingValidatorType.maleName:
				case this.matchMakingValidatorType.femaleName:
					if(type === this.matchMakingValidatorType.requesterName){
						if(data.requesterNme === ""){
							return false;
						}
						return matchMakingHandlers.nameValidator(data.requesterNme);
					}else if (type === this.matchMakingValidatorType.maleName) {
						if(data.maleName === ""){
							return false;
						}
						return matchMakingHandlers.nameValidator(data.maleName);
					}else{
						if(data.femaleName === ""){
							return false;
						}
						return matchMakingHandlers.nameValidator(data.femaleName);
					}

				case this.matchMakingValidatorType.requesterEmail:
					if (data.requesterEmail === "") {
						return false;
					}

					let emailRegex = /[a-zA-Z0-9\-_.]+@(gmail|hotmail|yahoo|rediffmail)+(?:\.(com))/
					let isEmailValid = emailRegex.test(data.requesterEmail);
					return isEmailValid;

				case this.matchMakingValidatorType.requesterPhone:
					let matchNumberWithOutCode = getNumber(data.requesterPhone);

					if (typeof matchNumberWithOutCode === Boolean) {
						return false;
					}
					if (this.requesterInsideIndia && matchNumberWithOutCode.toString().length < 10) {
						return false;
					}
					if (!this.requesterInsideIndia && matchNumberWithOutCode.toString().length < 10) {
						return false;
					}
					return true;

				case this.matchMakingValidatorType.maleDate:
				case this.matchMakingValidatorType.femaleDate:
					if(type === this.matchMakingValidatorType.maleDate){
						let maleDateValid = matchMakingHandlers.dateValidator(new Date(data.maleDate));
						if(new Date(data.maleDate).valueOf() > new Date().valueOf()){
							return false;
						}
						if(Object.values(maleDateValid).includes(false) || maleDateValid.length){
							return false;
						}

						return true;

					}else if (type === this.matchMakingValidatorType.femaleDate){
						let femaleDateValid = matchMakingHandlers.dateValidator(new Date(data.femaleDate));

						if(new Date(data.femaleDate).valueOf() > new Date().valueOf()){
							return false;
						}
						if(Object.values(femaleDateValid).includes(false)){
							return false;
						}

						return true;
					}
					break;

				case this.matchMakingValidatorType.maleTime:
				case this.matchMakingValidatorType.femaleTime:

					if(type === this.matchMakingValidatorType.maleTime){
						let maleTimeValid = matchMakingHandlers.timeValidator(data.maleTime);
						if(isBoolean(maleTimeValid)){
							return false;
						}
						if(Object.values(maleTimeValid).includes(false)){
							return false;
						}

						return true;
					}else if(type === this.matchMakingValidatorType.femaleTime){
						let femaleTimeValid = matchMakingHandlers.timeValidator(data.femaleTime);
						if(isBoolean(femaleTimeValid)){
							return false;
						}
						if(Object.values(femaleTimeValid).includes(false)){
							return false;
						}

						return true;

					}
					break;

				case this.matchMakingValidatorType.malePlace:
				case this.matchMakingValidatorType.femalePlace:
					if(type === this.matchMakingValidatorType.malePlace){
						return matchMakingHandlers.placeValidator(data.malePlace);
					}else if(type === this.matchMakingValidatorType.femalePlace){
						return matchMakingHandlers.placeValidator(data.femalePlace);
					}
					break;

				case this.matchMakingValidatorType.maleCountry:
				case this.matchMakingValidatorType.femaleCountry:
					if(type === this.matchMakingValidatorType.maleCountry){
					 	let countryarr = Array.from(Object.entries(this.countrys));
						let countrymap = new Map(countryarr);
						let foundCountry = Array.from(countrymap.values()).find(cou => cou === data.maleCountry);
						if(foundCountry){
							return true;
						}else{
							return false;
						}
					}else if(type === this.matchMakingValidatorType.femaleCountry){
					 	let countryarr = Array.from(Object.entries(this.countrys));
						let countrymap = new Map(countryarr);
						let foundCountry = Array.from(countrymap.values()).find(cou => cou === data.femaleCountry);
						if(foundCountry){
							return true;
						}else{
							return false;
						}
					}
					break;

				default:
					return false;
			}
		},
	};

	let [defaultMaleCountry, SetDefaultMaleCountry] = useState(matchMakingHandlers.countrys.India);
	let [defaultFemaleCountry, SetDefaultFemaleCountry] = useState(matchMakingHandlers.countrys.India);

	function handleMaleOutsideIndia(value){
		SetDefaultMaleCountry(value);
		let maleOutsideIndiaFees = matchMakingFeeSelector * 5
		if(value !== matchMakingHandlers.countrys.India && defaultFemaleCountry === matchMakingHandlers.countrys.India && matchMakingFeeSelector === 100){
			matchMakingDispatcher(matchMakingFee(maleOutsideIndiaFees));
		}else if(value === matchMakingHandlers.countrys.India && defaultFemaleCountry === matchMakingHandlers.countrys.India && matchMakingFeeSelector === 500){
			matchMakingDispatcher(matchMakingFee(matchMakingFeeSelector/5));
		}
	}

	async function handleMatchMakingBillPaymentComplete(matchMaking_bill_payment_response){

		let upload_matchmaking = null;
		
		if(matchMaking_bill_payment_response.status === 200){
			upload_matchmaking = await uploadClientMatchMaking(matchMakingFormData.current.formdata);
		}

		if(upload_matchmaking.success){
            setTimeout(() => {
                window.location.href = "/servicessuccess";
            }, 1000);
		}else{
            SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShowerSelected");
            matchMakingDispatcher(matchMakingPaymentError(upload_matchmaking.message));
            setTimeout(() => {
            	SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShower");
            	matchMakingDispatcher(resetMatchMakingPaymentError());
            }, 6000);
			window.location.reload();
		}
	}

	function prepareMatchMakingBillSdk(order_obj){
		
        let {bdorderid,token,authorization} = order_obj;
	
		let matchMakingBillSdkConfig = getBillSdkConfig(bdorderid,token,authorization);

		matchMakingBillSdkConfig.responseHandler = handleMatchMakingBillPaymentComplete;

        window.loadBillDeskSdk(matchMakingBillSdkConfig);
	}
	
	function handleFemaleOutsideIndia(value){
		SetDefaultFemaleCountry(value);
		let femaleOutsideIndiaFees = matchMakingFeeSelector * 5;
		if(value !== matchMakingHandlers.countrys.India && defaultMaleCountry === matchMakingHandlers.countrys.India && matchMakingFeeSelector === 100){
			matchMakingDispatcher(matchMakingFee(femaleOutsideIndiaFees));
		}else if(value === matchMakingHandlers.countrys.India && defaultMaleCountry === matchMakingHandlers.countrys.India && matchMakingFeeSelector === 500){
			matchMakingDispatcher(matchMakingFee(matchMakingFeeSelector/5));
		}
	}

	async function getMatchMakingBOrderId(){
		let matchMakingBorderFormData = new FormData();
		matchMakingBorderFormData.append("amount",matchMakingFeeSelector);
		matchMakingBorderFormData.append("currency","inr");
		matchMakingBorderFormData.append("clientip",await getClientIp());
		matchMakingBorderFormData.append("useragent",window.navigator.userAgent);

		axios({
			url: kapi.borderidapi,
			method: kapi.post,
			data: matchMakingBorderFormData,
			headers:{
				"Content-Type": "application/json",
			},
			withCredentials: true,
		}).then(result => {
			if(result.status !== 200){
				throw new Error({message: "order creation failed"});
			}else{
				SetMatchMakingPaymentOptionsWindowCls("matchMakingPaymentOptionsWindow");
				prepareMatchMakingBillSdk(result.data.data);
			}
		}).catch(error => {
			if(error.response !== undefined){
            	SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShowerSelected");
            	matchMakingDispatcher(matchMakingPaymentError(error.response.data.message));
            	setTimeout(() => {
            		SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShower");
            		matchMakingDispatcher(resetMatchMakingPaymentError());
            	}, 6000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
			}else if(error instanceof Object){
            	SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShowerSelected");
            	matchMakingDispatcher(matchMakingPaymentError(error.message));
            	setTimeout(() => {
            		SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShower");
            		matchMakingDispatcher(resetMatchMakingPaymentError());
            	}, 6000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
			}else{
            	SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShowerSelected");
            	matchMakingDispatcher(matchMakingPaymentError("something is wrong may be server is down try again/later"));
            	setTimeout(() => {
            		SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShower");
            		matchMakingDispatcher(resetMatchMakingPaymentError());
            	}, 6000);
                setTimeout(() => {
                    window.location.reload();
                }, 4000);
			}
		});
	}

	async function getMatchMakingPayConfig(){

		let matchMakingPayConfig = null;

		try{
			matchMakingPayConfig = await axios({
            	url: kapi.orderconfig,
            	method: kapi.get,
            	withCredentials: true,
        	});
		}catch(error){
            SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShowerSelected");
            matchMakingDispatcher(matchMakingPaymentError("failed to fetch data from server"));
            setTimeout(() => {
            	SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShower");
            	matchMakingDispatcher(resetMatchMakingPaymentError());
            }, 10000);
		}

		return matchMakingPayConfig;
	}

	let matchMakingPaymentOptionHandlerObj = {
		handlePaymentOptionOne: function(){

			setTimeout(() => {
				getMatchMakingOrderId();
			}, 1000);
			SetMatchMakingPaymentOptionsWindowCls("matchMakingPaymentOptionsWindow");

			setTimeout(() => {
		 		SetMatchMakingPaymentWindowCls("matchMakingPaymentWindowSelected");
			}, 200);
		},

		handlePaymentOptionTwo: function(){
			getMatchMakingBOrderId();
			SetMatchMakingPaymentOptionsWindowCls("matchMakingPaymentOptionsWindow");
		}
	}

	function getMatchMakingOrderId(){

        let orderidForm = new FormData();
        orderidForm.append("amount",matchMakingFeeSelector);
        orderidForm.append("currency","inr");

        axios({
            method: kapi.post,
            url: kapi.orderidapi,
            data: orderidForm,
            headers:{
                "Content-Type": "application/json",
            },
            withCredentials:true,
        }).then(async result => {
            if(result.status !== 200){
                throw new Error();
            }
			matchmakingOrderId.current.matchMakingOrderId = result.data.data;
			let matchMakingConfig = null;
			matchMakingConfig = await getMatchMakingPayConfig().then(result => {
				if(result.status === 200){
					matchMakingStripePub.current.key = result.data.data;
					return result.data.data;
				}
				if(matchMakingConfig === null){
					throw new Error({
						message:"failed to create order try again or later",
					});
				}
			}).catch(error => {
            	SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShowerSelected");
            	matchMakingDispatcher(matchMakingPaymentError(error.message));
            	setTimeout(() => {
            		SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShower");
            		matchMakingDispatcher(resetMatchMakingPaymentError());
            	}, 10000);
			}).catch(error => {
            	SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShowerSelected");
            	matchMakingDispatcher(matchMakingPaymentError("failed to create order try later"));
            	setTimeout(() => {
            		SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShower");
            		matchMakingDispatcher(resetMatchMakingPaymentError());
            	}, 10000);
			});
			SetMatchMakingStripePromise(loadStripe(matchMakingConfig));
        }).catch(error => {
			if(error.response !== undefined){
				let matchMakingOrderResponse = error.response;
            	SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShowerSelected");
            	matchMakingDispatcher(matchMakingPaymentError(matchMakingOrderResponse.data.message));
            	setTimeout(() => {
            		SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShower");
            		matchMakingDispatcher(resetMatchMakingPaymentError());
            	}, 10000);
			}else{
            	SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShowerSelected");
            	matchMakingDispatcher(matchMakingPaymentError("failed to fetch order data try later"));
            	setTimeout(() => {
            		SetMatchMakingPaymentErrorShower("matchMakingPaymentErrorShower");
            		matchMakingDispatcher(resetMatchMakingPaymentError());
            	}, 10000);
			}
        })
	}


	function submitMatchMaking(matchMakingData){
		matchMakingData.append("maleCountry",defaultMaleCountry);
		matchMakingData.append("femaleCountry",defaultFemaleCountry);
		matchMakingFormData.current.formdata = matchMakingData;
		SetMatchMakingPaymentOptionsWindowCls("matchMakingPaymentOptionsWindowSelected");
	}

	return (
		<div className="matchMakingContainer">
			<div className="matchMakingContainerContent">

				<h2 className="matchMakingPageHeader">MatchMaking Form</h2>

				<div className={matchMakingErrorShower}>
					{matchMakingErrorSelector.map((error, index) =>
						<div key={index}>{error}</div>
					)}
				</div>
				

				<div className={matchMakingPaymentErrorShower}>
					{matchMakingPaymentErrorSelector.map((error, index) =>
						<div key={index}>{error}</div>
					)}
				</div>


				<div className="kalniyojanMatchMakingFeesIndicator">
					<div>Fees</div>
					<div id="matchMakingFees">{matchMakingFeeSelector} INR</div>
				</div>


				<form onSubmit={event => matchMakingHandlers.handleMatchMaking(event)} >
					<div id="requesterDetails">Client Details</div>
					<div id="matchMakingRequesterDetails">
						<div id="requesterInputs">
							<div className="requesterNameRow">
								<label htmlFor="requesternamelabel"><span>*</span> Client Name:</label>
								<input type="text" name="requesterName" className="requesterName" ref={requesterNameRef} />
							</div>
							<div className="requesterEmailRow">
								<label htmlFor="requesteremaillabel"><span>*</span> Client Email:</label>
								<input type="text" name="requesterEmail" className="requesterEmail" ref={requesterEmailRef} />
							</div>
							<div className="requesterPhoneRow">
								<label htmlFor="requesterphonelabel"><span>*</span> Client PhoneNumber:</label>
								<input type='text' name="requesterPhone" className="requesterPhone" ref={requesterPhoneRef} />
							</div>
						</div>
						<div className="matchMakingPhoneFormatNote">
							NOTE: use this format (country code - phonenumber)(eg: 91-0123456789)
						</div>
					</div>

					<div id="maleFemaleDetails">

						<div id="maleDetails">
							<div id="maleNameHeader">Male Name</div>
							<div id="maleNameRow">
								<label htmlFor="malename"><span>*</span> Full Name:</label>
								<input type='text' name="maleName" id="maleName" ref={maleNameRef} />
							</div>
							<div className="maleBirthDateTimeHeader">Male Birth Date/Time</div>
							<div className="maleBirthDateTimeRow">
								<div className="maleBirthDate">
									<label htmlFor="malebirthdatelabel"><span>*</span> BirthDate:</label>
									<input type='date' name="maleBirthDate" className="maleBirthDate" ref={maleBirthDateRef} />
								</div>
								<div className="maleBirthTime">
									<label htmlFor="malebirthtimelabel"><span>*</span> BirthTime;</label>
									<input type='time' name="maleBirthTime" className="maleBirthTime" ref={maleBirthTimeRef} />
								</div>
							</div>
							<div className="maleBirthPlaceHeader">
								Male Birth city/country
							</div>
							<div className="maleBirthPlaceAndCountry">
								<div className="maleBirthPlaceRow">
									<label htmlFor="malebirthplacelabel"><span>*</span> BirthCityName:</label>
									<input type="text" name="maleBirthPlace" className="maleBirthPlace" ref={maleBirthPlaceRef} />
								</div>
								<div className="maleBirthCountryRow">
									<label htmlFor="malebirthcountrylabel"><span>*</span> BirthCountryName:</label>
									<select value={defaultMaleCountry} onChange={e => handleMaleOutsideIndia(e.target.value)}>
										{Array.from(matchMakingHandlers.countrysValues()).map((item,index) => 
											<option key={index}>{item}</option>
										)}
									</select>
								</div>
							</div>
						</div>

						<div id="femaleDetails">
							<div id="femaleNameHeader">Female Name</div>
							<div id="femaleNameRow">
								<label htmlFor="femalename"><span>*</span> Full Name:</label>
								<input type='text' name="femaleName" id="femaleName" ref={femaleNameRef} />
							</div>
							<div className="femaleBirthDateTimeHeader">Female Birth Date/Time</div>
							<div className="femaleBirthDateTimeRow">
								<div className="femaleBirthDate">
									<label htmlFor="femalebirthdatelabel"><span>*</span> BirthDate:</label>
									<input type="date" name="femaleBirthDate" className="femaleBirthDate" ref={femaleBirthDateRef} />
								</div>
								<div className="femaleBirthTime">
									<label htmlFor="femalebirthtimelabel"><span>*</span> BirthTime:</label>
									<input type='time' name="femaleBirthTime" className="femaleBirthTime" ref={femaleBirthTimeRef} />
								</div>
							</div>
							<div className="femaleBirthPlaceHeader">
								Female Birth city/country
							</div>
							<div className="femaleBirthPlaceAndCountry">
								<div className="femaleBirthPlaceRow">
									<label htmlFor="femalebirthplacelabel"><span>*</span> BirthCityName:</label>
									<input type="text" name="femaleBirthPlace" className="femaleBirthPlace" ref={femaleBirthPlaceRef} />
								</div>
								<div className="femaleBirthCountryRow">
									<label htmlFor="femalebirthcountrylabel"><span>*</span> BirthCountryName:</label>
									<select value={defaultFemaleCountry} onChange={e => handleFemaleOutsideIndia(e.target.value)}>
										{Array.from(matchMakingHandlers.countrysValues()).map((item,index) => 
											<option key={index}>{item}</option>
										)}
									</select>
								</div>
							</div>
						</div>
					</div>

					<div className="matchMakingSubmitBtn">
						<button value="submit" type="submit">Submit</button>
					</div>
				</form>
			</div>
            <div className={matchMakingPaymentOptionsWindowCls}>
                <div className="kalniyojanMatchMakingPaymentOptionOne">
                    <p>select this payment option if you want to pay through (master,visa) credit/debit card</p>
                    <button onClick={matchMakingPaymentOptionHandlerObj.handlePaymentOptionOne}>Select</button>
                </div>
                <div className="kalniyojanMatchMakingPaymentOptionTwo">
                    <p>select this payment option if you want to pay through (netbanking,upi,QR) or (Rupay) credit/debit card</p>
                    <button onClick={matchMakingPaymentOptionHandlerObj.handlePaymentOptionTwo}>Select</button>
                </div>
            </div>
			{matchMakingStripePub.current.key && <div className={matchMakingPaymentWindowCls}>
				<Elements stripe={matchMakingStripePromise} options={getStripeAppearance(matchmakingOrderId.current.matchMakingOrderId)}>
					<KalniyojanCheckout sendData={{serviceName:"matchMaking", formD: matchMakingFormData.current.formdata}} payAmount={matchMakingFeeSelector} paymentWindow={() => {SetMatchMakingPaymentWindowCls("matchMakingPaymentWindow"); window.location.reload(); }} orderId={matchmakingOrderId.current.matchMakingOrderId} stripePub={matchMakingStripePub.current.key} />
				</Elements>
			</div>}
		</div>
	);

}

export default MatchMaking;