
import { useEffect, useRef, useState } from "react";
import "./Vastu.css";
import axios from "axios";
import kapi from "../Redux/kalniyojanapi";
import { useDispatch, useSelector } from "react-redux";
import { vastuFee } from "../Redux/Action/FeesAction";
import { resetVastuError, resetVastuPaymentError, setVastuError, setVastuPaymentError } from "../Redux/Action/ErrorAction";
import { emailValidator, getBillSdkConfig, getClientIp, getVastuOrder, nameValidator, uploadVastu } from "../Helpers/Earth";
import { isBoolean } from "lodash";


function Vastu(){

  let kv_star = 0;

  let vastuFeeSelector;

  let vastuClientEmailRef = useRef(null);
  let vastuClientNameRef = useRef(null);
  let vastuClientPhoneRef = useRef(null);
  let vastuClientAddressRef = useRef(null);
  let vastuVisitNeededRef = useRef({
    value: false,
  });

  let vastuClientFormData = useRef({
    formData: null,
  });

  let vastuDispatcher = useDispatch();

  let [vastuErrorShower,SetVastuErrorShower] = useState("vastuErrorShower");
  let [vastuPaymentErrorShower,SetVastuPaymentErrorShower] = useState("vastuPaymentErrorShower");
  let [vastuFile,SetVastuFile] = useState(null);

  let vastuPaymentErrorSelector = useSelector(state => state.errors.payment);
  let vastuErrorSelector = useSelector(state => state.errors.vastu);
  
  let vastuHandler = {

    handleVastuVisit: function(e){
      vastuVisitNeededRef.current.value = e.target.checked;
    },

    validVastuFileSize: function(file){
      return file.size > 10485760 ? true : false;
    },

    handleVastuSubmit: function(e){
      e.preventDefault();

      if(vastuClientNameRef.current.value === "" || !nameValidator(vastuClientNameRef.current.value) || vastuClientNameRef.current.value === null){
        window.scrollTo(0,0);
        SetVastuErrorShower("vastuErrorShowerSelected");
        vastuDispatcher(setVastuError("name is invalid"));
        setTimeout(() => {
          SetVastuErrorShower("vastuErrorShower");
          vastuDispatcher(resetVastuError());
        }, 12000);
        kv_star++;
      }

      if(vastuClientEmailRef.current.value === "" || !emailValidator(vastuClientEmailRef.current.value) || vastuClientEmailRef.current.value === null){
        window.scrollTo(0,0);
        SetVastuErrorShower("vastuErrorShowerSelected");
        vastuDispatcher(setVastuError("email is invalid"));
        setTimeout(() => {
          SetVastuErrorShower("vastuErrorShower");
          vastuDispatcher(resetVastuError());
        }, 12000);
        kv_star++;
      }

      if(vastuClientPhoneRef.current.value === "" || vastuClientPhoneRef.current.value === null || !this.phonenumberValidator(vastuClientPhoneRef.current.value).status){
        window.scrollTo(0,0);
        SetVastuErrorShower("vastuErrorShowerSelected");
        vastuDispatcher(setVastuError("phone number is invalid"));
        setTimeout(() => {
          SetVastuErrorShower("vastuErrorShower");
          vastuDispatcher(resetVastuError());
        }, 12000);
        kv_star++;
      }

      if(vastuClientAddressRef.current.value === "" || vastuClientAddressRef.current.value === null || vastuClientAddressRef.current.value.length > 200){
        window.scrollTo(0,0);
        SetVastuErrorShower("vastuErrorShowerSelected");
        vastuDispatcher(setVastuError("address is too long or empty keep it less than 200 characters"));
        setTimeout(() => {
          SetVastuErrorShower("vastuErrorShower");
          vastuDispatcher(resetVastuError());
        }, 12000);
        kv_star++;
      }

      if(vastuFile === null || (vastuFile.type !== "image/png" && vastuFile.type !== "image/jpeg" && vastuFile.type !== "application/pdf")){
        
        window.scrollTo(0,0);
        SetVastuErrorShower("vastuErrorShowerSelected");
        vastuDispatcher(setVastuError("file can not be empty "));
        setTimeout(() => {
          SetVastuErrorShower("vastuErrorShower");
          vastuDispatcher(resetVastuError());
        }, 12000);
        kv_star++;
      }

      if(vastuHandler.validVastuFileSize(vastuFile)){
        window.scrollTo(0,0);
        SetVastuErrorShower("vastuErrorShowerSelected");
        vastuDispatcher(setVastuError("file size can not be grater than 10MB"));
        setTimeout(() => {
          SetVastuErrorShower("vastuErrorShower");
          vastuDispatcher(resetVastuError());
        }, 12000);
        kv_star++;
      }

      if(kv_star === 0){

        let vastuFormData = new FormData();
        vastuFormData.append("name",vastuClientNameRef.current.value);
        vastuFormData.append("email",vastuClientEmailRef.current.value);
        vastuFormData.append("phone",this.phonenumberValidator(vastuClientPhoneRef.current.value).pnumber);
        vastuFormData.append("phoneCode",this.phonenumberValidator(vastuClientPhoneRef.current.value).phoneCode);
        vastuFormData.append("address",vastuClientAddressRef.current.value);
        vastuFormData.append("vastuFile",vastuFile);
        vastuFormData.append("visit",vastuVisitNeededRef.current.value);

        setTimeout(() => {
          vastuHandler.submitVastuForm(vastuFormData);
        }, 1000);
      }

    },

    handleVastuFile: function(e){
      SetVastuFile(e.target.files[0]);
    },


	  getNumber: function(number){

		  let phonenumber, code;
		  if (number === "") {
			  return false;
		  }
		  let positionOfDash = number.indexOf("-");
		  let lastPositionOfDash = number.lastIndexOf("-");
		  if (positionOfDash === -1) {
			  return false;
		  }
		  if (positionOfDash !== lastPositionOfDash) {
			  return false;
		  }
		  let MM = number.substring(0, positionOfDash);
		  let parsedMM = parseInt(MM);
		  if (isNaN(parsedMM) || MM.length > 4 || MM.length < 1 || parsedMM === 0) {
			  return false;
		  }
		  let actualNumberOfRequester = number.substring(positionOfDash + 1, number.length);
		  let actualNumber = parseInt(actualNumberOfRequester);
		  if (isNaN(actualNumber) || actualNumberOfRequester.length > 10) {
			  return false;
		  }
		  let indian = true;
		  indian = parsedMM !== 91 ? false : true;
		  code = parsedMM;
		  phonenumber = actualNumber;
		  let numberObj = {
			  phoneCode: code,
			  pnumber: phonenumber,
			  phoneCountry: indian,
        indian,
		  };
		  return numberObj;
	  },

	  phonenumberValidator: function(phone){

		  let matchNumberWithOutCode = this.getNumber(phone);
		  let {pnumber} = matchNumberWithOutCode;

		  if (isBoolean(matchNumberWithOutCode)) {
			  return {
				  ...matchNumberWithOutCode,
				  status: false,
			  };
		  }
		  if (matchNumberWithOutCode.indian && pnumber.toString().length < 10) {
			  return {
				  ...matchNumberWithOutCode,
				  status: false,
			  };
		  }
		  if (!matchNumberWithOutCode.indian && pnumber.toString().length < 10) {
			  return {
				  ...matchNumberWithOutCode,
				  status: false,
			  };
		  }
		  return {
			  ...matchNumberWithOutCode,
			  status: true,
		  };
	  },

    submitVastuForm: async function(vastuData){

      vastuClientFormData.current.formData = vastuData;

      let vastuOrderData = new FormData();
      vastuOrderData.append("amount",vastuFeeSelector);
      vastuOrderData.append("currency","inr");
      vastuOrderData.append("clientip",await getClientIp());
      vastuOrderData.append("useragent",window.navigator.userAgent);

      getVastuOrder({data:vastuOrderData}).then(result => {
        let {data} = result.response.data;

        vastuHandler.prepareVastuBillSdkObj(data);
      }).catch(error => {
          window.scrollTo(0,0);
          SetVastuErrorShower("vastuErrorShowerSelected");
          vastuDispatcher(setVastuError(error.message));
          setTimeout(() => {
            SetVastuErrorShower("vastuErrorShower");
            vastuDispatcher(resetVastuError());
          }, 8000);
      });
    },

    prepareVastuBillSdkObj: function(data){

      let {bdorderid,token,authorization} = data;

      let vastuBillSdkConfig = getBillSdkConfig(bdorderid,token,authorization);
      vastuBillSdkConfig.responseHandler = vastuHandler.handleVastuBillPaymentComplete;
      window.loadBillDeskSdk(vastuBillSdkConfig);
    },

    handleVastuBillPaymentComplete: async function(bill_payment_complete_response){

      if(bill_payment_complete_response.status === 200){
        await uploadVastu(vastuClientFormData.current.formData).then(result => {
          if(result.success){
            setTimeout(() => {
                window.location.href = "/servicessuccess";
            }, 1000);
          }
        }).catch(error => {
          window.scrollTo(0,0);
          SetVastuErrorShower("vastuErrorShowerSelected");
          vastuDispatcher(setVastuError(error.message));
          setTimeout(() => {
            SetVastuErrorShower("vastuErrorShower");
            vastuDispatcher(resetVastuError());
          }, 8000);
          window.location.reload();
        })
      }

    }
  };
  
  async function getVastuFees(){
    let vastuFeeResponse = null;
    try{
      vastuFeeResponse = await axios({
        method: kapi.post,
        url: kapi.vastufeesapi,
        data: {
          servicename: kapi.vastuServiceName,
        },
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if(vastuFeeResponse.status !== 200){
        throw new Error({message: "failed to fetch service data"});
      }else{
        let {fees} = vastuFeeResponse.data;
        vastuDispatcher(vastuFee(fees));
      }
    }catch(error){
      SetVastuPaymentErrorShower("vastuPaymentErrorShowerSelected");
      vastuDispatcher(setVastuPaymentError("failed to fetch service data"));
      setTimeout(() => {
        SetVastuPaymentErrorShower("vastuPaymentErrorShower");
        vastuDispatcher(resetVastuPaymentError());
      }, 8000);
    }
  }

  useEffect(() => {
    getVastuFees();
  },[vastuFeeSelector]);

  vastuFeeSelector = useSelector(state => state.fees.vastu);

  return(
    <div className="kalniyojanVastuContainer">
      <div className="kalniyojanVastuContainerContent">
        <h2>Vastu Form</h2>

        <div className={vastuPaymentErrorShower}>
          {Array.from(vastuPaymentErrorSelector).map((item,index) => 
            <div key={index}>{item}</div>
          )}
        </div>

        <div className={vastuErrorShower}>
          {Array.from(vastuErrorSelector).map((item,index) => 
            <div key={index}>{item}</div>
          )}
        </div>

        <div className="kalniyojanVastuFeesIndicator">
            <div>Fees</div>
            <div id="adviceFees">{vastuFeeSelector} INR</div>
        </div>
      
        <form onSubmit={(event) => vastuHandler.handleVastuSubmit(event)}>
          <div className="kalniyojanVastuNameRow">
            <label>
              <span>
                *
              </span>
              Name
            </label>
            <input type="text" name="vastuClientName" ref={vastuClientNameRef} />
          </div>
          <div className="kalniyojanVastuEmailRow">
            <label>
              <span>
                *
              </span>
              Email
            </label>
            <input type='email' name="vastuClientEmail" ref={vastuClientEmailRef}/>
          </div>
          <div className="kalniyojanVastuPhoneRow">
            <label>
              <span>
                *
              </span>
              Phone
            </label>
            <input type='text' name="vastuClientPhone" ref={vastuClientPhoneRef} title="NOTE: use this format (country code - phonenumber)(eg: 91-0123456789)"/>
            <div className="kalniyojanVastuPhoneFormatNote">
              NOTE: use this format (country code - phonenumber)(eg: 91-0123456789)
            </div>
          </div>
          <div className="kalniyojanVastuAddressRow">
            <label>
              <span>
                *
              </span>
              Address
            </label>
            <input type='text' name="vastuClientAddress" ref={vastuClientAddressRef}/>
          </div>
          <div className="kalniyojanVastuVisitRow">
            <label>Check the box if visit to vastu is needed</label>
            <input type='checkbox' name="vastuVisit" onChange={(event) => vastuHandler.handleVastuVisit(event) } />
          </div>
          <div className="kalniyojanClientVastuFile">
            <label><span>*</span>Select Vastu Image/blue-print</label>
            <input type='file' name="vastuFile" onChange={event => vastuHandler.handleVastuFile(event)} />
          </div>
          <div className="kalniyojanVastuSubmitBtn">
            <button value="submit" type='submit'>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );

}

export default Vastu;