

let ServicesType = {
    getAdvice: "getAdvice",
    setMatchMaking: "setMatchMaking",
    setPatrika: "setPatrika",
    setMuhurta: "setMuhurta",
    setFeedback: "setFeedback",
};


export default ServicesType;