
import "./Help.css";
import Feedbackimg from "../images/feedback.svg";
import Refund from "../images/refund.svg";


export function Help(){

    return(
        <div className="kalniyojanHelpContainer">
            <div className="kalniyojanHelpContainerContent">
                <h1 className="kalniyojanHelpHeader">
                    Help
                </h1>
                <div className="kalniyojanHelpTypes">
                    <div className="kalniyojanFeedback" onClick={() => window.location.href = "/help/feedback"}>
                        <img src={Feedbackimg} alt="kalniyojanFeedBackImg"></img>
                        <h3>FeedBack</h3>
                    </div>
                    <div className="kalniyojanRefundCancellation" onClick={() => window.location.href = "/help/refundcancellation"}>
                        <img src={Refund} alt="kalniyojanRefundCancellationImg"></img>
                        <h3>Refund/Cancellation</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}